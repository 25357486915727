import { GET_COINLIST } from '../action/coinslistAction';

const coinslistReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_COINLIST:
      return payload;
    default:
      return state;
  }
};
export default coinslistReducer;
