import http from '../commonServices/httpService';
// import { apiUrl } from "../../config.json";
import helpers from '../commonServices/cryptos';

const apiEndpoint = process.env.REACT_APP_API_URL + '/nimecom';

export async function getsellerslist() {
  const { data } = await http.post(apiEndpoint + '/getseller');
  return data;
}
export async function UpdateSeller(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/aprvsel', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function getsellerImages(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/getreqsel', {
    enc: drreqpob,
  });
  return helpers.decrypt(data);
}
export async function getstoreDetails(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/viwstore', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function UpdateSellerFeature(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/featsel', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export default {
  getsellerslist,
  UpdateSeller,
  getsellerImages,
  getstoreDetails,
  UpdateSellerFeature,
};
