import React, { Component } from 'react';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import { CSVLink } from 'react-csv';
import Footer from '../../commons/Footer';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';

import { toast } from 'react-toastify';
import get_Reportslists from '../../reduxStore/action/reportsAction';
import { toastfun } from '../../commons/toast';
import { Table } from 'tabler-react';

import { Bars } from 'react-loader-spinner';
import Moment from 'react-moment';

class Reports extends Component {
  state = {
    repo: [],
    bknLoader: true,
    skip: 0,
    loadMoreButton: false,
    backEndBtn: false,
    startDate: '',
    EndDate: '',
    earnings: [],
    dbtn: false,
    loader: true,
  };
  componentDidMount = async () => {
    if (!this.props.reportslist) {
      await get_Reportslists();
    }
    await get_Reportslists();
    const reports = await this.props.reportslist;
    if (reports) {
      this.setState({
        repo: reports,
        earnings: reports.obj,

        loader: false,
      });
    }
    if (this.state.repo && !this.state.repo.length) {
      this.setState({ bknLoader: false });
    }
  };
  handleChangeDate = async (fdate) => {
    var d = fdate;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();
    const dd = year + '-' + month + '-' + day;
    await this.setState({
      startDate: dd,
    });
  };
  handleChangeToDate = async (tdate) => {
    var d = tdate;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();
    const dd = year + '-' + month + '-' + day;
    await this.setState({
      EndDate: dd,
    });
  };

  handleSubmit = async () => {
    this.setState({ dbtn: true });
    try {
      const dd = new Date();
      var month = dd.getMonth() + 1;
      var day = dd.getDate();
      var year = dd.getFullYear();
      const ddd = year + '-' + month + '-' + day;

      if (this.state.startDate === '' || this.state.EndDate === '') {
        toastfun('Please Enter Valid Dates', 'error');

        this.setState({ dbtn: false });
      } else {
        const obj = {
          sdate: this.state.startDate,
          edate: this.state.EndDate,
        };
        toastfun('Working On It', 'error');
        this.setState({ dbtn: false });

        // const data = await btwdatesreports(obj);

        // if (data) {
        //   if (data && data.result.length > 0) {
        //     await this.setState({
        //       repo: data.result,
        //       dbtn: false,
        //       loader: false,
        //     });
        //     this.dateReports();
        //   } else {
        //     this.setState({
        //       loader: false,

        //       dbtn: false,
        //     });
        //   }
        // }
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status === 400
      ) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ dbtn: false });
      }
    }
  };
  handleSearch = async (evt) => {
    if (!evt.target.value) {
      const reports = await this.props.fullstats;
      this.setState({ repo: reports.sta });
    } else {
      if (this.state.repo && this.state.repo.length > 0) {
        const fd = Object.values(this.state.repo).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          } else {
            let mdate = moment(val.date).format('D MMM YYYY hh:mm A');
            if (
              mdate
                .toLowerCase()
                .includes(evt.target.value.trim().toString().toLowerCase())
            ) {
              return val;
            }
          }
        });
        await this.setState({ repo: fd, currentPage: 1 });
      }
    }
  };

  colums = [
    {
      label: 'DATE',
      content: (item) => (
        <>
          {/* <Moment format='DD-MM-YYYY' fromNow>
            {item?.date}
          </Moment> */}
        </>
      ),
    },
    { path: 'total_peso_deposits', label: 'PESO DEPOSITS' },
    { path: 'total_peso_withdraw', label: 'PESO WITHDRAWALS' },

    { path: 'total_btc_deposits', label: 'BTC DEPOSITS' },
    { path: 'total_btc_withdraw', label: 'BTC WITHDRAWALS' },

    { path: 'total_usdt_deposits', label: 'USDT DEPOSITS' },
    { path: 'total_usdt_withdraw', label: 'USDT WITHDRAWALS' },

    { path: 'total_p2p_transfer', label: 'P2P TRANSFER' },

    { path: 'bills', label: 'BILLS' },
  ];

  totalValues = (objName) => {
    var tpd = this.state.repo
      .map((row) => row[objName])
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    if (tpd) return tpd;
    else return 0;
  };

  render() {
    const { repo } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> MIS Reports </b>
                      </h3>
                      <div className='pull-right no-gutters'>
                        <CSVLink
                          data={this.state.repo}
                          filename={'reports.csv'}
                        >
                          <button
                            type='button'
                            class='btn btn-sm theme-btn  btn-success text-center'
                            style={{
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          >
                            <i
                              class='fa fa-download mr-1'
                              aria-hidden='true'
                            ></i>
                            Download as Excel
                          </button>
                        </CSVLink>
                      </div>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          class='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            class='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              {/* <div className='d-flex no-gutters'>
                                <div
                                  className='col d-flex'
                                  style={{ height: 'fit-content' }}
                                >
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-40'
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                                <div>
                                  <DatePicker
                                    className='mt-1 mx-1 border py-1 text-center'
                                    value={this.state.startDate}
                                    onChange={this.handleChangeDate}
                                    placeholderText='From Date'
                                  />
                                </div>
                                <div className='mx-1 '>
                                  <DatePicker
                                    className='mt-1 mx-1 border py-1 text-center'
                                    value={this.state.EndDate}
                                    // selected={this.state.EndDate}
                                    onChange={this.handleChangeToDate}
                                    placeholderText='To Date'
                                  />
                                </div>
                                <div className='mx-1'>
                                  <button
                                    disabled={this.state.dbtn}
                                    onClick={this.handleSubmit}
                                    type='button'
                                    className='btn bg-warning mt-1 py-1 border'
                                  >
                                    Get Reports by Date
                                  </button>
                                </div>
                              </div> */}

                              {!this.state.rdata ? (
                                <div className='mt-2 mb-2 table-responsive dt-table'>
                                  <Table
                                    responsive
                                    className='card-table table-vcenter text-nowrap'
                                  >
                                    <thead>
                                      <th>DATE</th>
                                      <th> PESO DEPOSITS</th>
                                      <th> PESO WITHDRAWALS</th>

                                      <th> BTC DEPOSITS</th>
                                      <th> BTC WITHDRAWALS</th>

                                      <th> USDT DEPOSITS</th>
                                      <th> USDT WITHDRAWALS</th>

                                      <th> P2P TRANSFER</th>
                                      <th> BILLS</th>
                                    </thead>
                                    {this.state.repo &&
                                    this.state.repo.length ? (
                                      <tbody>
                                        {this.state.repo.map((k, i) => (
                                          <tr key={i}>
                                            <td>
                                              <Moment
                                                format='DD-MM-YYYY'
                                                fromNow
                                              >
                                                {k.date}
                                              </Moment>
                                            </td>
                                            <td>{k?.total_peso_deposits}</td>
                                            <td>{k?.total_peso_withdraw}</td>

                                            <td>{k?.total_btc_deposits}</td>
                                            <td>{k?.total_btc_withdraw}</td>

                                            <td>{k?.total_usdt_deposits}</td>
                                            <td>{k?.total_usdt_withdraw}</td>

                                            <td>{k?.total_p2p_transfer}</td>
                                            <td>{k?.bills}</td>
                                          </tr>
                                        ))}

                                        <tr className='bg-sums f-22'>
                                          <td>
                                            <span className=''> TOTAL</span>
                                          </td>
                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_peso_deposits'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_peso_withdraw'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_btc_deposits'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_btc_withdraw'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_usdt_deposits'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_usdt_withdraw'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues(
                                                'total_p2p_transfer'
                                              )}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues('bills')}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ) : this.state.loader ? (
                                      <tr className=' m-auto py-5'>
                                        <th colSpan='8'>
                                          {' '}
                                          <div
                                            className='bars'
                                            style={{
                                              marginLeft: '35rem',
                                            }}
                                          >
                                            <Bars
                                              className='text-center  offset-md-5 py-5'
                                              color='grey'
                                              height={80}
                                              width={80}
                                            />
                                          </div>{' '}
                                        </th>
                                      </tr>
                                    ) : (
                                      <tr className='col-md-12 m-auto py-5'>
                                        <td colSpan='8'>
                                          <h2>No Reports Found</h2>
                                        </td>
                                      </tr>
                                    )}
                                  </Table>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reportslist: state.reportslist,
  };
};

export default connect(mapStateToProps)(pushRoute(Reports));
