import React, { Component } from "react";
import HomeHeader from "../../commons/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { connect } from "react-redux";
import { pushRoute } from "../../commons/pushRoute";
import { toastfun } from "../../commons/toast";
import { backEndCallObj } from "../../services/MainService";
import _ from "lodash";
import get_grplist from "../../reduxStore/action/groupListAction";
import { Modal } from "react-responsive-modal";
import Form from "../../commons/form";
import Joi from "joi-browser";
import get_userslist from "../../reduxStore/action/userlistAction";
import OtpInput from "react-otp-input";

class UserTabs extends Form {
  state = {
    limitEditButton: true,
    pinvalue: "",
    userdetailsEditButton: true,
    edit_icons: {
      intro_id_1: true,
      intro_id_2: true,
    },
    edit_inputs: {},
    lbtn: false,
  };

  async componentDidMount() {
    await this.intialload({ intial: false });
  }

  handleEditInput = async (e, key) => {
    const edit_inputs = {};

    edit_inputs[key] = true;

    await this.setState({ edit_inputs });
  };

  handleNewChange = async (input) => {
    input = input.target;

    const detailsCard = { ...this.state.detailsCard };

    if (input.value === " ") {
      input.value = "";
      return;
    }
    detailsCard[input.name] = input.value;

    this.setState({ detailsCard });
  };

  editDetails = async () => {
    try {
      await this.setState({ btndis: true });
      const pkey = Object.keys(this.state.edit_inputs)[0];
      const userid = this.state?.user?.userid;
      const value = this.state.detailsCard[pkey];
      const compare = pkey === "intro_id_2" ? "intro_id_1" : "intro_id_2";
      if (value === userid || value === this.state.detailsCard[compare]) {
        toastfun("Not Valid", "error");
        await this.setState({ btndis: false });
        return;
      }
      const obj = {
        userid: userid,
        key: pkey,
        value: this.state.detailsCard[pkey],
      };
      const res = await backEndCallObj("/admin/update_introid", obj);
      if (res) {
        toastfun(res.success, "success");
        await get_userslist(null);
        await this.setState({ edit_inputs: {}, btndis: false });
      }
    } catch (error) {
      await this.setState({ btndis: false });
      toastfun(error.response.data, "error");
    }
  };

  schema = {
    daily_limit: Joi.number().required(),
    monthly_limit: Joi.number().required(),
    yearly_limit: Joi.number().required(),

    cashindaily_limit: Joi.number().required(),
    cashinmonthly_limit: Joi.number().required(),
    cashinyearly_limit: Joi.number().required(),
  };

  onOpenModal = async (item) => {
    await this.setState({
      popupOpen: true,
    });
  };
  redirected = () => {
    const { navigate } = this.props;
    setTimeout(() => {
      navigate("/userslist");
    }, 1000);
  };

  intialload = async ({ intial, userBkn }) => {
    try {
      var user = {};

      if (intial) {
        if (!this.state?.user?.userid) {
          await this.redirected();
          return;
        }
        const obj = {
          userid: this.state?.user.userid.replaceAll(" ", ""),
        };
        const res = await backEndCallObj("/adminget/getuser", obj);
        user = res.user;
      } else {
        user = { ...this.props.location.state?.user };

        if (!user) {
          await this.redirected();
          return;
        }
      }

      const detailsCard = _.omit(user, [
        "balances",
        "banking",
        "cryptoaddress",
        "user_limits",
        "images",
      ]);

      const limitsUsed = _.pick(user?.user_limits, [
        "cashindaily_used",
        "cashinmonthly_used",
        "cashinyearly_used",
        "daily_used",
        "monthly_used",
        "yearly_used",
      ]);

      const limitsValues = _.pick(user?.user_limits, [
        "cashindaily_limit",
        "cashinmonthly_limit",
        "cashinyearly_limit",
        "daily_limit",
        "monthly_limit",
        "yearly_limit",
      ]);

      if (!this.props.grouplist) {
        await get_grplist();
      }
      const gg = this.props.grouplist;

      const groups = gg.map((item) => {
        var data = { user_group: item.user_group, group_id: item.group_id };
        return data;
      });

      if (user?.images) {
        const userImages = [...Object.values(user?.images)];
        await this.setState({ userImages });
      }

      await this.setState({
        groups,
        user,
        limitsUsed,
        data: limitsValues,
        limitsValues,
        detailsCard,
      });
    } catch (error) {
      toastfun(error.response.data, "error");
      await this.setState({ loader: false });
    }
  };

  onCloseModal = async () => {
    await this.setState({
      popupOpen: false,
      groupChangePopupOpen: false,
      delpop: false,
    });
  };

  goToHistoryPage = () => {
    const { navigate } = this.props;
    navigate("/txnhistory", { state: this.state.user });
  };

  userGroupChange = async () => {
    await this.setState({ backEndBtn: true });
    try {
      const { changegroupvalue, user } = this.state;
      if (!changegroupvalue) {
        toastfun("Select User Group", "error");
        await this.setState({ backEndBtn: false });

        return;
      }
      const obj = {
        phone: user.phone,
        group_id: changegroupvalue,
      };

      const { success } = await backEndCallObj("/admin/change_user_group", obj);
      if (success) {
        toastfun(success, "success");
        await this.intialload({ intial: true });
        await this.close();
        await this.setState({ backEndBtn: false });

        await get_userslist(null);
      }
    } catch (error) {
      await this.setState({ backEndBtn: false });
    }
  };

  refresh = async () => {
    await this.setState({ refreshButton: true });
    await this.intialload({ intial: true });
    await this.setState({ refreshButton: false });
  };

  doSubmit = async () => {
    await this.setState({ btndis: true });
    const data = { ...this.state.data };
    if (this.state.limitsValues === data) {
      toastfun("No Changes", "warning");
      await this.setState({ btndis: false });
      return;
    }
    data.phone = this.state.user.phone;

    try {
      const { success } = await backEndCallObj(
        "/admin/update_user_limits",
        data
      );
      if (success) {
        toastfun(success, "success");
        await this.setState({ limitEditButton: true, btndis: false });
        await get_userslist(null);
      }
    } catch (error) {
      toastfun(error.response.data, "error");
      await this.setState({ btndis: false });
    }
  };

  close = async () => {
    await this.setState({
      popupOpen: false,
      userinfo: false,
      btndis: false,
      btndis1: false,
      groupChangePopupOpen: false,
    });
  };

  handleChangeGroupInput = async (e) => {
    await this.setState({ changegroupvalue: e.target.value });
  };

  UpdateStatus = async () => {
    await this.setState({ backEndBtn: true });

    try {
      const user = { ...this.state.user };
      var obj = {
        userid: user.userid,
        status: user.status === "1" ? "0" : "1",
      };

      const data = await backEndCallObj("/admin/suspenduser", obj);
      if (data) {
        toastfun("Status Updated", "success");
        await this.intialload({ intial: true });

        await this.close();
        await this.setState({ backEndBtn: false });
        await get_userslist(null);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }

      this.setState({ popupOpen: false, backEndBtn: false });
    }
  };

  onOpenGroupModal = async (item) => {
    await this.setState({ groupChangePopupOpen: true, userDetails: item });
  };

  renderObjValue = (title, obj) => {
    return (
      <>
        {obj &&
          Object.keys(obj).map((key) => {
            return (
              <>
                {obj[key] &&
                toString.call(obj[key]).slice(8, -1) === "Object" ? (
                  this.renderObjValue(title, obj[key])
                ) : (
                  <>
                    <h5 className="col-md-6 cap">
                      {key}
                      {/* {title === "Addresses" ? `${key}Address` : { key }}{" "} */}
                    </h5>
                    <div className="col-md-6">
                      <input disabled value={obj[key]} className="col-md-12" />
                    </div>
                  </>
                )}
              </>
            );
          })}
      </>
    );
  };

  handleotpChange = async (input) => {
    var pinvalue = input;
    await this.setState({ pinvalue, butdisabled: false });
  };
  submitPin = async () => {
    const { pinvalue, user } = this.state;

    await this.setState({ refreshButton: true });
    try {
      if (pinvalue.length < 4) {
        toastfun("Pin Length Must Be 4", "error");
        await this.setState({ refreshButton: false });
        return;
      }
      const obj = {
        pin: pinvalue,
      };

      const res = await backEndCallObj(
        "/admin/reset4DigitPin/" + user.userid,
        obj
      );

      toastfun(res?.success, "success");
      await this.setState({ refreshButton: false, pinvalue: "" });
    } catch (error) {
      await this.setState({ refreshButton: false });
      toastfun(error.response.data, "error");
    }
  };
  LogoutUser = async () => {
    this.setState({ lbtn: true });
    try {
      var obj = { userid: this.state.user?.userid };
      var response = await backEndCallObj("/admin/logout_user", obj);
      if (response) {
        toastfun(response.success, "success");
        setTimeout(() => {
          this.setState({ lbtn: false });
        }, 4000);
      }
    } catch (error) {
      toastfun(error.response.data, "error");
      this.setState({ lbtn: false });
    }
  };
  renderPinResetForm = (title, obj) => {
    return (
      <>
        <div className="text-center mb-3 col-md-8">
          <h3 className="mb-0 mt-2 title"> Reset User Pin </h3>
        </div>

        <div className="col-md-6">
          <OtpInput
            containerStyle="opt_input"
            inputStyle="opt_input_box"
            value={this.state.pinvalue}
            onChange={this.handleotpChange}
            numInputs={4}
            isInputNum
            separator={<span> &nbsp; &nbsp; </span>}
          />
        </div>
        <div className="form-group buttons text-center p-4 col-md-8">
          <button
            style={{ background: "#201865", color: "white" }}
            type="submit"
            disabled={this.state.refreshButton}
            onClick={this.submitPin}
            className="btn btn-primary cap teal-bg mr-4"
            // className={this.state.btnClas}
          >
            SUBMIT
          </button>
        </div>
      </>
    );
  };

  renderUserDetailsValue = (title, obj) => {
    return (
      <>
        {obj &&
          Object.keys(obj).map((key) => {
            return (
              <>
                {obj[key] &&
                toString.call(obj[key]).slice(8, -1) === "Object" ? (
                  this.renderUserDetailsValue(title, obj[key])
                ) : (
                  <>
                    <h5 className="col-md-3 cap">
                      {key} &nbsp; &nbsp;
                      {this.state.edit_icons[key] && (
                        <>
                          {" "}
                          {!this.state.edit_inputs[key] ? (
                            <i
                              className="fa fa-edit text-danger"
                              onClick={(e) => this.handleEditInput(e, key)}
                            />
                          ) : (
                            <>
                              {this.state.btndis ? (
                                <>
                                  {" "}
                                  <i
                                    role="button"
                                    class="fa fa-upload"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <i
                                    role="button"
                                    onClick={this.editDetails}
                                    class="fa fa-upload"
                                    aria-hidden="true"
                                  ></i>
                                </>
                              )}
                            </>
                          )}{" "}
                        </>
                      )}
                    </h5>
                    <div className="col-md-3">
                      <input
                        disabled={!this.state.edit_inputs[key]}
                        name={key}
                        onChange={this.handleNewChange}
                        value={obj[key]}
                        className="col-md-12"
                      />
                    </div>
                  </>
                )}
              </>
            );
          })}
      </>
    );
  };

  renderObjEditValue = (title, obj) => {
    return (
      <>
        {obj &&
          Object.keys(obj).map((key) => {
            return (
              <>
                {obj[key] &&
                toString.call(obj[key]).slice(8, -1) === "Object" ? (
                  this.renderObjEditValue(title, obj[key])
                ) : (
                  <>
                    <h5 className="col-md-6 cap">{key} </h5>
                    <div className="col-md-6">
                      <input
                        name={key}
                        onChange={this.handleChange}
                        disabled={this.state.limitEditButton}
                        value={this.state.data?.[key]}
                        className="col-md-12"
                      />
                      <small>{this.state.errors?.[key]}</small>
                    </div>
                  </>
                )}
              </>
            );
          })}
      </>
    );
  };

  render() {
    const {
      user,
      userImages,
      popupOpen,
      backEndBtn,
      groupChangePopupOpen,
      groups,
      limitsUsed,
      limitsValues,
      detailsCard,
      limitEditButton,
      userdetailsEditButton,
    } = this.state;

    const { navigate } = this.props;

    return (
      <React.Fragment>
        <HomeHeader />

        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline mb-5">
                      <h3 className="card-title">
                        <b>
                          {" "}
                          User Information - &nbsp;
                          <span className="text-danger cap">
                            {user?.userid} &nbsp;/&nbsp;{user?.member_name}
                            &nbsp;/&nbsp;{user?.phone}
                          </span>
                        </b>
                      </h3>
                      <button
                        value={user?.status === "1" ? "Suspend" : "Active"}
                        type="button"
                        onClick={(val) => this.onOpenModal()}
                        className={
                          user?.status === "1"
                            ? "btn btn-sm  bg-secondary bb  text-center"
                            : "btn btn-sm  btn-success bb text-center"
                        }
                      >
                        {user?.status === "1" ? "Suspend" : "Active"}
                      </button>

                      <button
                        type="button"
                        onClick={() => this.onOpenGroupModal()}
                        className={"btn  bb  bg-primary fw-bolder  text-center"}
                      >
                        {user?.user_group}
                      </button>

                      <button
                        type="button"
                        onClick={() => this.goToHistoryPage()}
                        className=" btn  rounded-pill history_button "
                      >
                        History
                      </button>
                      <button
                        type="button"
                        onClick={() => this.LogoutUser()}
                        className=" btn bg-warning fw-bolder "
                        disabled={this.state.lbtn}
                      >
                        Logout
                      </button>
                      {this.state.refreshButton ? null : (
                        <i
                          className="fa fa-refresh ml-2 text-danger"
                          style={{ fontSize: "2rem" }}
                          aria-hidden="true"
                          onClick={this.refresh}
                        ></i>
                      )}

                      <button
                        onClick={() => navigate(-1)}
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>

                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <Tabs>
                            <TabList>
                              <Tab>Personal Details</Tab>
                              <Tab>Balances</Tab>

                              <Tab>Limits</Tab>
                              <Tab>Documents</Tab>
                              <Tab>Reset Pin</Tab>
                            </TabList>

                            <TabPanel>
                              <div className="card card--secondary">
                                <header className="card__header">
                                  <div className=" dflex_center mt-1">
                                    {" "}
                                    User Details
                                  </div>
                                </header>
                                <div className="card__body">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "1.25em",
                                      padding: "0.8rem",
                                    }}
                                  >
                                    <div className="col-md-12 row">
                                      {this.renderUserDetailsValue(
                                        " User Details",
                                        detailsCard
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel>
                              <div className="card card--secondary">
                                <header className="card__header">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "1.25em",
                                    }}
                                  >
                                    <div className="dflex_center mt-1">
                                      Balances
                                    </div>
                                    <div className="dflex_center mt-1 offset-md-5">
                                      Crypto Addresses
                                    </div>
                                  </div>
                                </header>
                                <div className="card__body">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "1.25em",
                                      padding: "0.8rem",
                                    }}
                                  >
                                    <div className="col-md-6 row">
                                      {this.renderObjValue(
                                        " Balances",
                                        user?.balances
                                      )}
                                    </div>
                                    <div className="col-md-6 row">
                                      {this.renderObjValue(
                                        "Addresses",
                                        user?.cryptoaddress
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel>
                              <div className="card card--secondary">
                                <header className="card__header">
                                  <div className="row">
                                    <div className="dflex_center mt-1">
                                      Limits Used
                                    </div>
                                    <div className="dflex_center mt-1 offset-md-5">
                                      Limits
                                    </div>
                                  </div>
                                  {limitEditButton ? (
                                    <>
                                      {" "}
                                      <button
                                        onClick={() => {
                                          this.setState({
                                            limitEditButton:
                                              !this.state.limitEditButton,
                                          });
                                        }}
                                        className="btn  rounded-pill"
                                      >
                                        Edit
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <button
                                        disabled={this.state.btndis}
                                        onClick={this.handleSubmit}
                                        className="btn  rounded-pill"
                                      >
                                        Update
                                      </button>
                                    </>
                                  )}
                                </header>
                                <div className="card__body">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "1.25em",
                                      padding: "0.8rem",
                                    }}
                                  >
                                    <div className="col-md-6 row">
                                      {this.renderObjValue(
                                        " Limits Used",
                                        limitsUsed
                                      )}
                                    </div>
                                    <div className="col-md-6 row">
                                      {this.renderObjEditValue(
                                        " Limit Values",
                                        limitsValues
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>

                            <TabPanel>
                              {user?.kyc_status === "pending" ? (
                                <>
                                  <h2> KYC Status Pending</h2>
                                </>
                              ) : (
                                <>
                                  <>
                                    <div className="card card--secondary">
                                      <header className="card__header">
                                        <div className="dflex_center mt-1">
                                          {" "}
                                          Documents
                                        </div>
                                      </header>
                                    </div>
                                    <div className=" row col-12 dflex_space_arounf">
                                      {userImages ? (
                                        Object.values(userImages).map(
                                          (item) => {
                                            return (
                                              <>
                                                {item.substring(
                                                  item.length - 3
                                                ) === "pdf" ? (
                                                  <div className="col-2 p-3 req_height  ">
                                                    {" "}
                                                    <a
                                                      className="btn btn-primary p-1 "
                                                      href={item}
                                                    >
                                                      Click Here to view PDF
                                                      File
                                                    </a>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <img
                                                      className="col-4 p-3 req_height"
                                                      src={item}
                                                      alt="No "
                                                    />

                                                    {/* <a className="btn btn-primary p-1" href={this.state.image1} target="_blank"> Click Here to view PDF File</a> */}
                                                  </>
                                                )}
                                              </>
                                            );
                                          }
                                        )
                                      ) : (
                                        <>
                                          <h2>Documents Not Found</h2>
                                        </>
                                      )}
                                    </div>
                                  </>
                                </>
                              )}
                            </TabPanel>

                            <TabPanel>
                              <div className="card card--secondary">
                                <header className="card__header">
                                  <div className="dflex_center mt-1">
                                    {" "}
                                    Pin Change
                                  </div>
                                </header>
                                <div className="card__body">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "1.25em",
                                      padding: "0.8rem",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {this.renderPinResetForm(
                                      " Balances",
                                      user?.balances
                                    )}
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {popupOpen && (
          <Modal open={popupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Update {this.state.uid}{" "}
                      User Status
                    </h5>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={() => this.UpdateStatus()}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Yes
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {groupChangePopupOpen && (
          <Modal open={groupChangePopupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Change Group{" "}
                      {this.state.uid}{" "}
                    </h5>
                  </div>

                  <div className=" grid_center">
                    <div className="col-4">
                      <select
                        style={{
                          width: "10rem",
                          height: "80%",
                        }}
                        placeholder="min"
                        className="text-center py-1 m-1 "
                        onChange={(e) => this.handleChangeGroupInput(e)}
                      >
                        <option value="" required>
                          Select
                        </option>
                        {groups.map((r) => (
                          <option value={r.group_id} required>
                            {r.user_group}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={(val) => this.userGroupChange(val)}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Change
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grouplist: state.grouplist,
    user: state.getcurrentuser,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserTabs));
