import { GET_CRYPTOADRS } from "../action/cryptoadrsAction";

const cryptoadrsReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_CRYPTOADRS:
      return payload;
    default:
      return state;
  }
};
export default cryptoadrsReducer;
