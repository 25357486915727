import React, { Component } from "react";
import { Joi } from "joi-browser";
import { backEndCallObj } from "../services/MainService";
import { toastfun } from "../commons/toast";

class Dataform extends Component {
  state = {
    data: {
      member_name: "",
      member_email: "",
      phone: "",
      password: "",
      account_type: "",
      user_type: "",
      current_access_ip: "",
      fcmtoken: "",
      intro_id_1: "",
      intro_id_2: "",
      date_of_join: "",
      status: "",
      kyc_status: "",
      bitcoin: "",
      usdt: "",
      peso: "",
      region: "",
      province: "",
      city: "",
      barangay: "",
      houseno: "",
      dob: "",
      placeofbirth: "",
      trader: "",
      selfie: "",
      idcard: "",
    },
    errors: null,
  };

  doSubmit = async () => {
    try {
      console.log(this.state.data);
      const res = await backEndCallObj("/admin/add_old_users", this.state.data);
      console.log(res);
      if (res) {
        toastfun(res.success, "success");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }
    }
  };

  handleChange = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    const data = { ...this.state.data };
    if (input.name === "phone") {
      data[input.name] = input.value.replace(/[^0-9]/g, "");

      this.setState({ data, errors });
    } else {
      if (input.value === " ") {
        input.value = "";
        return;
      }
      data[input.name] = input.value;
      if (input.value === "") delete data[input.name];
      this.setState({ data, errors });
    }
  };
  handleChangefile = (e) => {
    console.log(e.target.value);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row offset-md-1">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    User Type
                  </label>
                  <input
                    type="text"
                    name="user_type"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Member Email
                  </label>
                  <input
                    type="text"
                    name="member_email"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Date Of join
                  </label>
                  <input
                    type="text"
                    name="date_of_join"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Account type
                  </label>
                  <input
                    type="text"
                    name="account_type"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Date Of Birth
                  </label>
                  <input
                    type="text"
                    name="dob"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row offset-md-1 mt-3">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Member Name
                  </label>
                  <input
                    type="text"
                    name="member_name"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    KYC status
                  </label>
                  <input
                    type="text"
                    name="kyc_status"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Intro_id_1
                  </label>
                  <input
                    type="text"
                    name="intro_id_1"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Current Access Ip
                  </label>
                  <input
                    type="text"
                    name="current_access_ip"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Intro_id_2
                  </label>
                  <input
                    type="text"
                    name="intro_id_2"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row offset-md-1 mt-3">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Status
                  </label>
                  <input
                    type="text"
                    name="status"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Bitcoin
                  </label>
                  <input
                    type="text"
                    name="bitcoin"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Usdt
                  </label>
                  <input
                    type="text"
                    name="usdt"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Peso
                  </label>
                  <input
                    type="text"
                    name="peso"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row offset-md-1 mt-3">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    fcm_Token
                  </label>
                  <input
                    type="text"
                    name="fcmtoken"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Region
                  </label>
                  <input
                    type="text"
                    name="region"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Province
                  </label>
                  <input
                    type="text"
                    name="province"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Barangay
                  </label>
                  <input
                    type="text"
                    name="barangay"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row offset-md-1 mt-3">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    House Number
                  </label>
                  <input
                    type="text"
                    name="houseno"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Password
                  </label>
                  <input
                    type="text"
                    name="password"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Place of Birth
                  </label>
                  <input
                    type="text"
                    name="placeofbirth"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Selfie
                  </label>
                  <input
                    type="text"
                    name="selfie"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Trader
                  </label>
                  <input
                    type="text"
                    name="trader"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row offset-md-1 mt-3">
                <div className="col-md-2">
                  <label for="exampleInputEmail1" className="form-label">
                    Id Card
                  </label>
                  <input
                    type="text"
                    name="idcard"
                    className="py-1  border  w-100"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    onClick={this.doSubmit}
                    className="btn btn-primary ml-4 mt-2"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dataform;
