import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_COINLIST = 'GET_COINLIST';

async function get_coinlist(data) {
  try {
    store.dispatch({ type: GET_COINLIST, payload: data });
  } catch (ex) {}
}

export default get_coinlist;
