import React from "react";
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import "react-responsive-modal/styles.css";
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Dashboard from "./components/dashboard/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Four04 from "./components/login/404";
import Login from "./components/login/login";
import ProtectedRoute from "./commons/protectedRoute";

import UserLimits from "./components/Users/userlimits";
import UserBalance from "./components/Users/userbalance";
import Bankslist from "./components/Users/bankslist";
import Cryptoadresses from "./components/Users/cryptoadresses";
import UserListTable from "./components/Users/userListTable";
import TxnHistory from "./components/Users/txnhistory";
import Gettxns from "./components/Users/gettxns";
import UserProfile from "./components/Users/userProfile";
import LimitEdit from "./components/Users/limitedit";

import SiteControls from "./components/admincontrols/sitecontrols";
import TxControls from "./components/admincontrols/txcontrols";
import Adminlist from "./components/admincontrols/adminlist";
import Addadmin from "./components/admincontrols/addAdmin";
import SiteControlsAdvan from "./components/admincontrols/siteControlsAdvan";
import CoinForm from "./components/admincontrols/coinForm";
import AddNewGroup from "./components/admincontrols/addGroup";
import CoinsList from "./components/admincontrols/coinsList";
import Reports from "./components/admincontrols/reports";
import Userlogdate from "./components/Users/userlogdate";
import KYCReq from "./components/kyc/kycreq";
import TraderKYCReq from "./components/kyc/TraderkycReq";
import CropKycValidate from "./components/kyc/cropKycValidate";
import CROPValidate from "./components/kyc/cropvalidate";
import KYCValidate from "./components/kyc/kycvalidate";
import Opentickets from "./components/tickets/opentickets";
import Closedtickets from "./components/tickets/closedtickets";
import Resolve from "./components/tickets/resolve";
import Viewtickets from "./components/tickets/viewtickets";

import Logout from "./commons/logout";

import Message from "./components/Message/message";

import CryptoWithdraw from "./components/pendingWithdraw/cryptoWithdraw";
import PesoWithdraw from "./components/pendingWithdraw/pesoWithdraw";
import UserListByGroup from "./components/Users/userListByGroup";
import UserTabs from "./components/Users/tabs";
import ProfitReports from "./components/admincontrols/profitReports";
import Dataform from "./components/userform";
import UserListByName from "./components/Users/userListByName";
import ApikeysUpdate from "./components/admincontrols/apikeysUpdate";

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        // theme='#63798b'
        position="top-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path="/404" exact element={<Four04 />} />
          <Route path="/" exact element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/" exact element={<ProtectedRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            {/* <Route exact path="/usrform" element={<Dataform />} /> */}
            <Route exact path="/kycreq" element={<KYCReq />} />
            <Route exact path="/traderkycreq" element={<TraderKYCReq />} />
            <Route exact path="/cropkycreq" element={<CropKycValidate />} />
            <Route exact path="/cropvalidate" element={<CROPValidate />} />
            <Route exact path="/kycvalidate" element={<KYCValidate />} />
            {/* <Route exact path='/corpvalidate' element={<CorpValidate />} /> */}

            <Route exact path="/viewtickets" element={<Viewtickets />} />
            <Route exact path="/resolve" element={<Resolve />} />
            <Route exact path="/closedticks" element={<Closedtickets />} />
            <Route exact path="/openticks" element={<Opentickets />} />

            <Route exact path="/admlist" element={<Adminlist />} />
            <Route exact path="/sitecontrols" element={<SiteControls />} />
            <Route
              exact
              path="/sitecontrolsadvan"
              element={<SiteControlsAdvan />}
            />
            <Route exact path="/addAdmin" element={<Addadmin />} />
            <Route exact path="/txcontrols" element={<TxControls />} />
            <Route exact path="/addnewgroup" element={<AddNewGroup />} />
            <Route exact path="/coinslist" element={<CoinsList />} />
            <Route exact path="/reports" element={<Reports />} />
            <Route exact path="/usertabs" element={<UserTabs />} />
            <Route exact path="/profitreports" element={<ProfitReports />} />
            <Route exact path="/userlogdate" element={<Userlogdate />} />
            <Route exact path="/binupdate" element={<ApikeysUpdate />} />
            <Route exact path="/userslist" element={<UserListTable />} />
            <Route exact path="/userlimits" element={<UserLimits />} />
            <Route exact path="/balances" element={<UserBalance />} />
            <Route exact path="/bankslist" element={<Bankslist />} />
            <Route exact path="/addresses" element={<Cryptoadresses />} />
            <Route exact path="/limitedit" element={<LimitEdit />} />
            <Route exact path="/userprofile" element={<UserProfile />} />
            <Route exact path="/usersgroups" element={<UserListByGroup />} />
            <Route exact path="/usersname" element={<UserListByName />} />
            <Route exact path="/cryptowithdraw" element={<CryptoWithdraw />} />
            <Route exact path="/pesowithdraw" element={<PesoWithdraw />} />
            <Route exact path="/msg" element={<Message />} />
            <Route exact path="/coinform" element={<CoinForm />} />

            <Route exact path="/txnhistory" element={<TxnHistory />} />
            <Route exact path="/txns" element={<Gettxns />} />
          </Route>
          <Route exact path="*" element={<Four04 />} />
          <Route exact path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
