import store from '../store/index';

import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_REPORTS = 'GET_REPORTS';

async function get_Reportslists() {
  try {
    const { success } = await backEndCall('/adminget/get_MIS_stats');
    store.dispatch({ type: GET_REPORTS, payload: success });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_Reportslists;
