import store from "../store/index";

import mp from "../../services/marketplace/orderService";
import { toast } from "react-toastify";

export var GET_ONLINEORDERS = "GET_ONLINEORDERS";

async function get_onlineorders() {
  try {
    const data = await mp.getuserorders();
    store.dispatch({ type: GET_ONLINEORDERS, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_onlineorders;
