import http from './commonServices/httpService';
// import apiUrl from "../config.json";
import helpers from './commonServices/cryptos';

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + '/nimdfs';

export async function getadminslist() {
  const { data } = await http.post(apiUrl + '/nimv/adminlist');
  return helpers.decryptobj(data);
}
export async function adminAdd(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/createadmin', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function changeadminType(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/changeadmtype', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function resetadmpass(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/changeadmpass', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function delAdmin(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/removadmin', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function admincontrols() {
  const { data } = await http.post(apiUrl + '/nimv/admincontrols');
  return helpers.decryptobj(data);
}
export async function updateadmcontrols(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/updateadminctrls', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function updatetxncontrols(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/updatetxctrls', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function txcontrols() {
  const { data } = await http.post(apiUrl + '/nimv/admintxcontrols');
  return helpers.decryptobj(data);
}
export async function logsbydate(obj) {
  const drreqpob = await helpers.encryptobj(obj);
  const data = await http.post(apiUrl + '/nimv/logsbydate', {
    enc: drreqpob,
  });
  return data;
  // return helpers.decrypt(data);
}
export async function senddata(obj) {
  var drreqpob = await helpers.encryptobj(obj);
  var data = await http.post(apiUrl + '/nimv/presentlog', {
    enc: drreqpob,
  });
  return data;
}
export async function logsearch(obj) {
  var drreqpob = await helpers.encryptobj(obj);
  var data = await http.post(apiUrl + '/nimv/userdate', {
    enc: drreqpob,
  });
  return data;
}

export default {
  logsearch,
  senddata,
  logsbydate,
  getadminslist,
  adminAdd,
  changeadminType,
  resetadmpass,
  delAdmin,
  admincontrols,
  updateadmcontrols,
  txcontrols,
  updatetxncontrols,
};
