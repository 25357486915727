import jwtDecode from "jwt-decode";
import http from "./commonServices/httpService";
import helpers from "./commonServices/cryptos";
import { publicIp, publicIpv4, publicIpv6 } from "public-ip";
// import apiUrl from "../config.json";

const { detect } = require("detect-browser");

const browser = detect();
const apiEndpoint = process.env.REACT_APP_API_URL;

const tokenKey = "admpr-token";

http.setJwt(getJwt());

export async function login(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/admin/loginotp", {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function resendotp(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + "/admin/resend", {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function validateOTP(obj) {
  const IP = await publicIpv4();
  obj.browserid = browser.version;
  obj.ip = IP;
  const drreqpob = helpers.encryptobj(obj);
  var { data: jwt } = await http.post(apiEndpoint + "/admin/valotp", {
    enc: drreqpob,
  });
  jwt = helpers.decryptobj(jwt);
  localStorage.setItem(tokenKey, helpers.encryptobj(jwt));
  return jwt;
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("IP");
}
(async () => {
  const IP = await publicIpv4();
  localStorage.setItem("IP", helpers.encrypt(IP));
})();

export function getCurrentUser() {
  try {
    const clientip = helpers.decrypt(localStorage.getItem("IP"));
    const jwt = helpers.decryptobj(localStorage.getItem(tokenKey));
    const some = jwtDecode(jwt);

    if (
      some.exp >= Date.now() / 1000 &&
      some.ip === clientip &&
      some.browserid === browser.version
    ) {
      return some;
    } else {
      logout();
    }
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return helpers.decrypt(localStorage.getItem(tokenKey));
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
  validateOTP,
};
