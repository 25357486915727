import http from '../commonServices/httpService';
// import { apiUrl } from "../../config.json";
import helpers from '../commonServices/cryptos';

const apiEndpoint = process.env.REACT_APP_API_URL + '/nimecom';

export async function getriders() {
  const { data } = await http.post(apiEndpoint + '/getrider');
  return helpers.decryptobj(data);
}
export async function RiderUpdate(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/riderapv', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
// export async function deletesubcat(obj) {
//   const drreqpob = helpers.encryptobj(obj);
//   const { data } = await http.post(apiEndpoint + "/delsub", {
//     enc: drreqpob,
//   });
//   return helpers.decryptobj(data);
// }

export default {
  getriders,
  RiderUpdate,
};
