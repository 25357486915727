/* eslint-disable */

import React, { Component } from "react";
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="navbar navbar-expand-lg navbar-light">
          <div className="justify-content-center margin-auto">
            <span className="navbar-text">
              Copyright &copy; 2022 TOPWALLET. All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
