import React, { Component } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { NavLink, useHref } from 'react-router-dom';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import { kycreq } from '../../services/kycService';
import { connect } from 'react-redux';
import get_kycreq from '../../reduxStore/action/kycreqAction';
import { backEndCall } from '../../services/MainService';

class KYCReq extends Component {
  state = {
    kdata: [],
    data: [],
    refresh: true,
    stoploader: true,
  };
  componentDidMount = async () => {
    if (!this.props.kycreqlist) {
      this.intialload({ intial: false });
    } else {
      this.intialload({ intial: true });
    }
  };

  intialload = async ({ intial }) => {
    await this.setState({ refresh: false });
    if (!intial) {
      await this.setState({ data: [], kdata: [] });

      const kdata = await backEndCall('/adminget/basicsilverkycList');
      await get_kycreq(kdata);
    }
    const kdata = this.props?.kycreqlist;

    setTimeout(() => {
      if (!kdata.length) {
        this.setState({ stoploader: false });
      }
    }, 2000);
    await this.setState({ refresh: true });
    await this.setState({ kdata, data: kdata });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ kdata: this.state.data });
    } else {
      if (this.state.kdata && this.state.kdata.length > 0) {
        const fd = Object.values(this.state.kdata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ kdata: fd, currentPage: 1 });
      }
    }
  };

  kycValidatePage = (arg) => {
    const { navigate } = this.props;
    navigate('/kycvalidate', { state: arg });
  };
  refresh = () => {
    this.intialload({ intial: false });
  };

  render() {
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Pending All KYC Requests </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                                {this.state.refresh ? (
                                  <i
                                    className='fa fa-refresh'
                                    style={{ fontSize: 'xx-large' }}
                                    aria-hidden='true'
                                    onClick={this.refresh}
                                  ></i>
                                ) : null}
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <th>SNO</th>
                                    <th>User Type</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    {this.props.location?.state?.admin_type ===
                                      '3' ||
                                    this.props.location?.state?.admin_type ===
                                      '5' ? null : (
                                      <th>Action</th>
                                    )}
                                  </thead>
                                  <tbody>
                                    {this.state.kdata &&
                                    this.state.kdata.length > 0 ? (
                                      this.state.kdata.map((k, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{k.user_type}</td>
                                            <td>{k.member_name}</td>
                                            <td>{k.phone}</td>
                                            <td>{k.kyc_status}</td>

                                            <td>
                                              <div
                                                className='btn btn-primary'
                                                onClick={(e) =>
                                                  this.kycValidatePage(k)
                                                }
                                              >
                                                Validate
                                              </div>
                                              {/* {k.UserType === "Corporate" ? <button to={'/corpvalidate'} state={k} className="btn btn-primary" > Validate</button> : <button to={'/kycvalidate'} state={k} className="btn btn-primary">Validate</button>} */}
                                              {/* <NavLink
                                                  to={{
                                                    pathname:
                                                      k.UserType === "Corporate"
                                                        ? "/corpvalidate"
                                                        : "/kycvalidate",
                                                    state: k,
                                                  }}
                                                  className="btn btn-primary"
                                                >
                                                  {" "}
                                                  Validate
                                                </NavLink> */}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : this.state.stoploader ? (
                                      <tr className=' m-auto py-5'>
                                        <th colSpan='8'>
                                          {' '}
                                          <div className=''>
                                            <BallTriangle
                                              className='text-center  offset-md-5 py-5'
                                              // type="Bars"
                                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                              color='grey'
                                              height={80}
                                              width={80}
                                            />
                                          </div>{' '}
                                        </th>
                                      </tr>
                                    ) : (
                                      <th colSpan='8'>
                                        {' '}
                                        <div className='text-center  offset-md-1 py-5'>
                                          No Data Found
                                        </div>
                                      </th>
                                    )}
                                  </tbody>
                                </Table>

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    kycreqlist: state.kycreqlist,
  };
};

export default connect(mapStateToProps)(pushRoute(KYCReq));
