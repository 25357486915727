import store from '../store/index';

import mp from '../../services/marketplace/orderService';
import { toast } from 'react-toastify';

export var GET_INSTOREORDERS = 'GET_INSTOREORDERS';

async function get_instoreorders() {
  try {
    const data = await mp.getinstoreorders();
    store.dispatch({ type: GET_INSTOREORDERS, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_instoreorders;
