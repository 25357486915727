import store from '../store/index';

import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_CLOSETICKETS = 'GET_CLOSETICKETS';

async function get_closetickets() {
  try {
    const { success } = await backEndCall('/adminget/get_closed_tickets');

    store.dispatch({ type: GET_CLOSETICKETS, payload: success });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_closetickets;
