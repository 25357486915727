import React from 'react';
import HomeHeader from '../../commons/Header';
import { pushRoute } from '../../commons/pushRoute';
import Joi, { errors } from 'joi-browser';
import Footer from '../../commons/Footer';

import Form from '../../commons/form';

import { regions, provinces, cities } from 'select-philippines-address';
import { toastfun } from '../../commons/toast';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { backEndCallObj } from '../../services/MainService';

class Message extends Form {
  state = {
    data: {},
    errors: {},
    aluser: '',
    alu: false,
    btndisabled: false,
    option: '',
  };

  async componentDidMount() {
    try {
      var reg = await regions();

      const type = toString.call(reg).slice(8, -1);

      if (type !== 'Array') {
        toastfun('Regions Not Found Try Again Later', 'error');
        return;
      }

      await this.setState({ reg });
    } catch (error) {
      toastfun('Regions Not Found Try Again Later', 'error');
    }
  }

  schema = {
    title: Joi.string().min(6).max(50).trim().required(),
    message: Joi.string().min(10).max(1000).trim().required(),

    type: Joi.string().valid('city', 'phone').required(),

    phone: Joi.when('type', {
      is: 'phone',
      then: Joi.string().min(12).max(12).required().label('Phone'),
      otherwise: Joi.optional().label('Phone'),
    }),

    region: Joi.when('type', {
      is: 'city',
      then: Joi.required().label('Region'),
      otherwise: Joi.optional().label('Region'),
    }),

    province: Joi.when('type', {
      is: 'city',
      then: Joi.required().label('province'),
      otherwise: Joi.optional().label('province'),
    }),

    city: Joi.when('type', {
      is: 'city',
      then: Joi.required().label('city'),
      otherwise: Joi.optional().label('city'),
    }),
  };

  handleChangeRegion = async (res) => {
    const input = { name: res.target.name, value: res.target.value };
    const errors = { ...this.state.errors };

    const data = { ...this.state.data };
    data[input.name] = input.value;
    delete errors[input.name];
    delete data.province;
    var prov = await provinces(input.value);

    await this.setState({ data, errors });
    const type = toString.call(prov).slice(8, -1);

    if (type !== 'Array') {
      toastfun(' Not Found Try Again Later', 'error');
      return;
    }
    await this.setState({ prov });
  };

  doSubmit = async () => {
    try {
      await this.setState({ btndisabled: true });
      const { data } = this.state;

      const obj = {
        type: data.type,
        key: data.type === 'phone' ? data.phone : data.city,

        message: data.message,

        title: data.title,
      };

      const res = await backEndCallObj('/admin/send_notification', obj);
      if (res) {
        Array.from(document.querySelectorAll('input')).forEach(
          (input) => (input.value = '')
        );

        Array.from(document.querySelectorAll('select')).forEach(
          (input) => (input.value = '')
        );

        Array.from(document.querySelectorAll('textarea')).forEach(
          (input) => (input.value = '')
        );

        // toastfun(res.success, "success");

        await this.setState({ btndisabled: false, data: {} });
      }
    } catch (error) {
      await this.setState({ btndisabled: false });
      toastfun(error.response.data, 'error');
    }
  };

  handleChangeProvince = async (res) => {
    const input = { name: res.target.name, value: res.target.value };
    const errors = { ...this.state.errors };

    delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    delete data.city;
    var cit = await cities(input.value);

    await this.setState({ data, errors });
    const type = toString.call(cit).slice(8, -1);

    if (type !== 'Array') {
      toastfun(' Not Found Try Again Later', 'error');
      return;
    }
    await this.setState({ cit });
  };

  handleChangeCity = async (res) => {
    const input = { name: res.target.name, value: res.target.value };

    const data = { ...this.state.data };
    data[input.name] = input.value;
    errors[input.name] = '';
    await this.setState({ data, errors });

    var prov = await provinces(input.value);
    this.setState({ prov });
  };

  render() {
    const { data, errors, option } = this.state;
    return (
      <React.Fragment>
        <HomeHeader />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-10 offset-md-1'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title '>
                        <b> Send Message</b>
                      </h3>
                    </div>
                    <hr className='mt-0'></hr>
                    <div className='row' style={{ padding: '2rem' }}>
                      <div className='col-md-5 '>
                        <div className='mb-3'>
                          <label className='form-label'>Title</label>
                          <input
                            type='text'
                            style={{ padding: '2.5rem' }}
                            name='title'
                            value={data?.title}
                            className='form-control'
                            placeholder='Enter Message Title'
                            onChange={this.handleChange}
                          />

                          <div className='row'>
                            {' '}
                            {data?.title?.length <= 50 ? (
                              <span className='offset-md-10 text-success'>
                                {data?.title.length || 0}/ 50
                              </span>
                            ) : (
                              <span className='offset-md-10 text-danger'>
                                {data?.title?.length || 0}/ 50
                              </span>
                            )}
                          </div>
                          <small>{errors?.title}</small>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='mb-3'>
                          <label className='form-label'>Message</label>
                          <textarea
                            type='text'
                            rows='6'
                            name='message'
                            value={data?.message}
                            className='form-control textaera_notification'
                            placeholder='Enter Message... '
                            onChange={this.handleChange}
                          />

                          <div className='row'>
                            {' '}
                            {data?.message?.length <= 1000 ? (
                              <span className='offset-md-10 text-success'>
                                {data?.message?.length || 0}/ 1000
                              </span>
                            ) : (
                              <span className='offset-md-10 text-danger'>
                                {data?.message?.length || 0}/ 1000
                              </span>
                            )}
                          </div>

                          <small>{errors?.message}</small>
                        </div>
                      </div>

                      <div className='col-md-5 mb-2'>
                        <label className='form-label'>Type</label>
                        <select
                          onChange={this.handleSelect}
                          name='type'
                          value={data?.type}
                          className='form-control'
                        >
                          <option value=''>Select Message Sent Option</option>

                          <option value='phone'>Phone</option>
                          <option value='city'>City</option>
                        </select>
                        <small>{errors?.type}</small>
                      </div>

                      {data?.type === 'phone' ? (
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label>Phone</label>
                            <PhoneInput
                              className=''
                              country={'ph'}
                              value={data?.phone}
                              countryCodeEditable={false}
                              disableDropdown={true}
                              onChange={(phone) =>
                                this.handleDate(phone, 'phone')
                              }
                            />

                            <small>{errors?.phone}</small>
                          </div>
                        </div>
                      ) : null}

                      {data?.type === 'city' ? (
                        <>
                          {' '}
                          <div className='col-md-6  '>
                            <label className='form-label'>Region</label>
                            <select
                              onChange={(e) => this.handleChangeRegion(e)}
                              name='region'
                              value={data?.region}
                              className='form-control'
                            >
                              <option value=''>-- Select Regions -- </option>
                              {this.state.reg &&
                                this.state.reg.map((region) => {
                                  return (
                                    <option value={region.region_code}>
                                      {region.region_name}
                                    </option>
                                  );
                                })}
                            </select>
                            <small>{errors.region}</small>
                          </div>
                          <div className='col-md-5  mb-3 '>
                            <label className='form-label'>Province</label>
                            <select
                              onChange={(e) => this.handleChangeProvince(e)}
                              className='form-control'
                              name='province'
                              value={data?.province}
                              aria-label='.form-select-lg example'
                            >
                              <option value=''>-- Select Province --</option>
                              {this.state.prov &&
                                this.state.prov.map((province) => {
                                  return (
                                    <option value={province.province_code}>
                                      {province.province_name}
                                    </option>
                                  );
                                })}
                            </select>
                            <small>{errors.province}</small>
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label'>Cities</label>
                            <select
                              className='form-control'
                              name='city'
                              value={data?.city}
                              onChange={this.handleSelect}
                              aria-label='.form-select-lg example'
                            >
                              <option value=''>-- Select Cities --</option>
                              {this.state.cit &&
                                this.state.cit.map((citi) => {
                                  return (
                                    <option value={citi.city_name}>
                                      {citi.city_name}
                                    </option>
                                  );
                                })}
                            </select>
                            <small>{errors?.city}</small>
                          </div>
                        </>
                      ) : null}

                      <div
                        className={
                          data.type === 'phone' || data.type === 'city'
                            ? 'col-md-5 offset-md-3 mt-3 dflex_center'
                            : 'col-md-5 dflex_center'
                        }
                      >
                        <button
                          onClick={this.handleSubmit}
                          className='btn text-white col-md-6'
                          style={{
                            background: '#12c5dc',
                            fontSize: '18px',
                          }}
                          disabled={this.state.btndisabled}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default pushRoute(Message);
