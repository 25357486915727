import store from "../store/index";

import catgory from "../../services/marketplace/categoryService";
import { toast } from "react-toastify";

export var GET_CATCONTROLS = "GET_CATCONTROLS";

async function get_catcontrols() {
  try {
    const data = await catgory.getcategories();
    store.dispatch({ type: GET_CATCONTROLS, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_catcontrols;
