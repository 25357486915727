import { GET_DASHBOARD_VALUES } from "../action/dashboardValuesaAction";

const dashboardValuesReducer = (state = null, { type, payload }) => {
    switch (type) {
        case GET_DASHBOARD_VALUES:
            return payload;
        default:
            return state;
    }
};
export default dashboardValuesReducer;
