import store from '../store/index';

export var GET_CROPKYCREQLIST = 'GET_CROPKYCREQLIST';

async function get_cropkycreq(data) {
  try {
    store.dispatch({ type: GET_CROPKYCREQLIST, payload: data });
  } catch (ex) {
    console.warn(ex);
  }
}

export default get_cropkycreq;
