import React, { Component } from 'react';
import { pushRoute } from '../../commons/pushRoute';
import { connect } from 'react-redux';
import HomeHeader from '../../commons/Header';
import Footer from '../../commons/Footer';
import { toast } from 'react-toastify';
import { toastfun } from '../../commons/toast';
import get_pendingcrypwtdr from '../../reduxStore/action/pendingCryWith';
import CommonTable from '../../commons/commonTable';
import Moment from 'react-moment';

class CryptoWithdraw extends Component {
  state = {
    loadMoreButton: false,
  };

  componentDidMount = async () => {
    if (!this.props.pndcrywtrlist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  intialload = async ({ intial }) => {
    try {
      this.setState({ bknLoader: true });

      if (intial) {
        await get_pendingcrypwtdr();
      }

      const data = this.props.pndcrywtrlist;

      await this.setState({ bknLoader: false });
      await this.setState({
        kdata: data,
        alldata: data,
        loadmore: false,
      });
    } catch (error) {
      console.warn(error);
      await this.setState({ bknLoader: false });
      toast.error('error');
    }
  };

  colums = [
    {
      label: 'DATE    ',
      content: (date) => (
        <Moment unix format='DD-MM-YYYY hh:mm A' fromNow>
          {date.time / 1000}
        </Moment>
      ),
    },

    { path: 'userid', label: 'USER ID' },
    { path: 'phone', label: 'PHONE' },
    { path: 'coin', label: 'COIN NAME' },
    { path: 'amount', label: 'AMOUNT' },

    { path: 'fee', label: 'FEE' },
    { path: 'tid', label: 'TRANSACTION ID' },
    { path: 'address', label: 'ADDRESS' },
    { path: 'status', label: 'STATUS' },
  ];

  refresh = () => {
    this.intialload({ intial: true });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ kdata: this.state.ldata });
    } else {
      if (this.state.kdata && this.state.kdata.length > 0) {
        const fd = Object.values(this.state.kdata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ kdata: fd });
      }
    }
  };

  render() {
    const { kdata } = this.state;
    return (
      <>
        <React.Fragment>
          <HomeHeader
            myprops={this.props}
            // user={this.props.location.state.user}
          />
          <div className='page-content'>
            <div className='content-wrapper'>
              <div className='content'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card'>
                      <div className='card-header header-elements-inline'>
                        <h3 className='card-title'>
                          <b> Pending Crypto Withdraw </b>
                        </h3>
                      </div>

                      <div className='card-body'>
                        <div className='tab-content'>
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab1'
                          >
                            <div
                              className='tab-pane fade show active'
                              id='solid-justified-tab11'
                            >
                              <div className='card-body datatable-scroll'>
                                {/* <div className='d-flex no-gutters'>
                                  <div className='col d-flex'>
                                    <input
                                      type='text'
                                      placeholder='Search..'
                                      pattern='[0-9]*'
                                      className='py-1 pl-2 mb-2 border w-25 '
                                      onChange={this.handleSearch}
                                    />
                                    <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                  </div>
                                </div> */}

                                {this.state.loadmore ? null : (
                                  <i
                                    className='fa fa-refresh ml-2 dflex_end'
                                    style={{ fontSize: 'xx-large' }}
                                    aria-hidden='true'
                                    onClick={this.refresh}
                                  ></i>
                                )}

                                <div className='mb-2 table-responsive dt-table'>
                                  <CommonTable
                                    colums={this.colums}
                                    data={kdata}
                                    bknLoader={this.state.bknLoader}
                                    loadMoreButton={this.state.loadMoreButton}
                                    loadmore={this.state.loadmore}
                                    fetchData={this.fetchData}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pndcrywtrlist: state.pndcrywtrlist,
  };
};

export default connect(mapStateToProps)(pushRoute(CryptoWithdraw));
