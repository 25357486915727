import React from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";
import Footer from "../../commons/Footer";
import { BallTriangle } from "react-loader-spinner";
import Joi from "joi-browser";
import { fields } from "../../data";
import Form from "../../commons/form";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import get_adminslist from "../../reduxStore/action/adminslistAction";
import { connect } from "react-redux";

class CoinForm extends Form {
  state = {
    data: {},
    disabledObj: {},
    btndisabled: false,
  };

  schema = {
    coin_name: Joi.string().min(4).trim(true).required(),
    ticker: Joi.string().min(3).trim(true).required(),
    text: Joi.string().min(5).trim(true).required(),
    otc_min: Joi.number().required(),
    otc_max: Joi.number().greater(
      Joi.ref("otc_min", {
        adjust: (value) => ~~value,
      })
    ),
    withdraw_min: Joi.number().required(),
    withdraw_max: Joi.number().greater(
      Joi.ref("withdraw_min", {
        adjust: (value) => ~~value,
      })
    ),
    address_generation: Joi.string().required(),
    withdraw: Joi.string().required(),
  };

  async componentDidMount() {
    const coin = this.props.location.state;

    if (coin) {
      var data = { ...this.state.data };
      const disabledObj = { ...this.state.disabledObj };
      data = coin;
      disabledObj.coin_name = true;
      disabledObj.ticker = true;
      await this.setState({ data, disabledObj });
    }
  }

  doSubmit = async () => {
    try {
      const { navigate } = this.props;
      const { data } = this.state;
      await this.setState({ btndis: true });
      const res = await backEndCallObj("/admin/add_coins", data);

      if (res) {
        toastfun(res.success, "success");
        setTimeout(() => {
          navigate("/coinslist", { state: { intialload: false } });
        }, 2000);
      }
    } catch (error) {
      toastfun(error.response.data, "error");
      await this.setState({ btndis: false });
    }
  };

  render() {
    const { btndis, data, errors, disabledObj } = this.state;

    const { navigate } = this.props;
    return (
      <React.Fragment>
        <HomeHeader />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title">
                        <b> Add Coin </b>
                      </h3>

                      <button
                        onClick={() => {
                          navigate(-1);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>
                    <hr className="mt-0"></hr>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Coin Name</label>
                            <input
                              disabled={disabledObj?.coin_name}
                              type="text"
                              name="coin_name"
                              className="form-control"
                              value={data?.coin_name}
                              placeholder="Enter Bitcoin Name ex: bitcoin"
                              onChange={this.handleChangeNoValidate}
                            />
                            <small>{errors?.coin_name}</small>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Ticker</label>
                            <input
                              disabled={disabledObj?.ticker}
                              type="text"
                              name="ticker"
                              className="form-control"
                              value={data?.ticker}
                              placeholder="Enter Ticker Name ex: TICKER"
                              onChange={this.handleChangeNoValidate}
                            />
                            <small>{errors?.ticker}</small>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">OTC Minimum</label>
                            <input
                              name="otc_min"
                              type="text"
                              className="form-control"
                              placeholder="Enter Minimum OTC Amount"
                              value={data?.otc_min}
                              onChange={this.handleNumberNoValidate}
                            />
                            <small>{errors?.otc_min}</small>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">OTC Maximum</label>
                            <input
                              type="text"
                              name="otc_max"
                              className="form-control c_b"
                              placeholder="Enter Maximum OTC Amount"
                              value={data?.otc_max}
                              onChange={this.handleNumberNoValidate}
                            />
                            <small>{errors?.otc_max}</small>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Withdraw Minimum
                            </label>
                            <input
                              type="text"
                              name="withdraw_min"
                              className="form-control"
                              placeholder="Enter Minimum Withdraw Amount"
                              value={data?.withdraw_min}
                              onChange={this.handleNumberNoValidate}
                            />
                          </div>
                          <small>{errors?.withdraw_min}</small>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Withdraw Maximum
                            </label>
                            <input
                              type="text"
                              name="withdraw_max"
                              className="form-control"
                              placeholder="Enter Maximum Withdraw Amount"
                              value={data?.withdraw_max}
                              onChange={this.handleNumberNoValidate}
                            />
                            <small>{errors?.withdraw_max}</small>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Note</label>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              placeholder="Enter Note"
                              value={data?.text}
                              onChange={this.handleChangeNoValidate}
                            />
                          </div>
                          <small>{errors?.text}</small>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Coin Withdraw </label>
                            <select
                              name="withdraw"
                              value={data?.withdraw}
                              className="form-control"
                              onChange={this.handleChangeNoValidate}
                            >
                              {fields?.coin_type.map((r) => (
                                <option value={r.value} required>
                                  {r.label}
                                </option>
                              ))}
                            </select>
                            {/* <input
                              type="text"
                              name="withdraw"
                              className="form-control"
                              placeholder="Enter withdraw"
                              value={data?.withdraw}
                              onChange={this.handleChangeNoValidate}
                            /> */}
                          </div>
                          <small>{errors?.withdraw}</small>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">
                              Address Generation
                            </label>
                            <select
                              name="address_generation"
                              value={data?.address_generation}
                              className="form-control"
                              onChange={this.handleChangeNoValidate}
                            >
                              {fields?.coin_type.map((r) => (
                                <option value={r.value} required>
                                  {r.label}
                                </option>
                              ))}
                            </select>
                            {/* <input
                              type="text"
                              name="address_generation"
                              className="form-control"
                              placeholder="Enter Note"
                              value={data?.address_generation}
                              onChange={this.handleChangeNoValidate}
                            /> */}
                          </div>
                          <small>{errors?.address_generation}</small>
                        </div>
                        <div className="col-md-2">
                          {!this.state.loader ? (
                            <button
                              type="button"
                              onClick={this.handleSubmit}
                              className="btn btn-primary btn-lg btn-block mt-3 ml-4 loc-bttn"
                              disabled={this.state.btndis}
                            >
                              {" "}
                              Submit{" "}
                            </button>
                          ) : (
                            <BallTriangle
                              className="text-center  offset-5"
                              // type="Bars"
                              color="grey"
                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                              height={80}
                              width={80}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(CoinForm));
