import store from "../store/index";
import seller from "../../services/marketplace/sellerService";
import { toast } from "react-toastify";

export var GET_SELLERLIST = "GET_SELLERLIST";

async function get_sellerslist() {
  try {
    const data = await seller.getsellerslist();

    store.dispatch({ type: GET_SELLERLIST, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_sellerslist;
