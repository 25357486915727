import { GET_REPORTS } from '../action/reportsAction';

const reportsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_REPORTS:
      return payload;
    default:
      return state;
  }
};
export default reportsReducer;
