import React from "react";
import { Route, Navigate, useLocation, Outlet } from "react-router-dom";
import auth from "../services/authService";
const ProtectedRoute = ({ path, element: Element, render, ...rest }) => {
  let location = useLocation();
  var user = auth.getCurrentUser();
  if (!user) {
    return (
      // <Navigate to="/login" />
      <Navigate
        to={{
          pathname: "/login",
          state: { from: location }
        }}
        replace
      />
    );
  } else {
    return <Outlet />;
  }



};

export default ProtectedRoute;
