import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';

import HomeHeader from '../../commons/Header';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { commenttickets } from '../../services/ticketService';

import get_opentickets from '../../reduxStore/action/openticketAction';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';

class Resolve extends Component {
  state = {
    rbtn: 'Open',
    content: '',
    tickdata: [],
    raisedname: '',
  };
  componentDidMount() {
    setTimeout(() => {
      if (
        this.state.tickdata &&
        this.state.tickdata.subject &&
        this.state.tickdata.subject.length > 0
      ) {
        this.setState({
          raisedname: this.state.tickdata.subject.slice(-1).pop().person,
        });
      }
    }, 2000);
  }
  handlechange = async (e) => {
    await this.setState({ rbtn: e.target.value });
  };
  handlechangetext = async (e) => {
    await this.setState({ content: e.target.value });
  };

  close = () => {
    const { navigate } = this.props;
    navigate('/openticks');
    // this.props.history.push("/openticks");
  };
  resolvetick = async (tick) => {
    const { navigate } = this.props;
    await this.setState({ btndis: true });
    try {
      if (!this.state.content) {
        await this.setState({ btndis: false });
        toast.error('content not allowed to be empty');
        return;
      }
      const obj = {
        ticketid: tick,
        description: this.state.content,
        status: this.state.rbtn,
      };
      const success = backEndCallObj('/admin/commentTicket', obj);
      if (success) {
        await get_opentickets();
        toastfun(success, 'success');
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (ex) {
      await this.setState({ btndis: false });

      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  render() {
    const tickdat = this.props?.location?.state;

    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title ml-3'>
                        <b> Resolve Ticket </b>
                      </h3>
                      <button
                        onClick={this.close}
                        type='button'
                        class='btn-close'
                        aria-label='Close'
                        style={{
                          cursor: 'pointer',
                          color: 'white',
                          background: 'red',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        X
                      </button>
                    </div>
                    <hr></hr>
                    <div className='card-body'>
                      <div className='tab-content'>
                        <div className='mx-2'>
                          <span className='float-right'>
                            <h3>Ticket-ID: #{tickdat?.ticket_id}</h3>
                          </span>
                        </div>
                        <div className='row'>
                          <span className='d-flex'>
                            <h5>Raised By:</h5>
                            <p
                              className='ml-2 text-primary '
                              style={{ fontSize: '15px' }}
                            >
                              {' '}
                              {this.state.raisedname}
                              ,&nbsp;{'+' + tickdat?.phone}
                            </p>
                          </span>
                        </div>
                        <span className='float-left'>
                          <h5>
                            Issue:
                            <span className='ml-3 text-primary'>
                              {tickdat?.subject}
                            </span>
                          </h5>
                        </span>
                      </div>
                    </div>
                    <div class='row mb-4'>
                      <div class='col-md-8 p-4 border m-auto pb-2'>
                        <div class='col-md-12 pt-3 m-auto'>
                          <div class='form-group raise-ticket'>
                            <textarea
                              onChange={this.handlechangetext}
                              class='form-control mb-2'
                              placeholder='Content..'
                              value={this.state.content}
                            ></textarea>

                            <div className='row'>
                              <div className='col-md-6'>
                                <div class='form-check form-check-inline'>
                                  <input
                                    class='form-check-input'
                                    onChange={(value) =>
                                      this.handlechange(value)
                                    }
                                    type='radio'
                                    name='inlineRadioOptions'
                                    id='inlineRadio1'
                                    value='Open'
                                    checked={
                                      this.state.rbtn === 'Open' ? true : false
                                    }
                                  />
                                  <label
                                    class='form-check-label m-0'
                                    for='inlineRadio1'
                                  >
                                    Open
                                  </label>
                                </div>
                                <div class='form-check form-check-inline'>
                                  <input
                                    onChange={(value) =>
                                      this.handlechange(value)
                                    }
                                    class='form-check-input'
                                    type='radio'
                                    name='inlineRadioOptions'
                                    id='inlineRadio2'
                                    value='Close'
                                    checked={
                                      this.state.rbtn === 'Close' ? true : false
                                    }
                                  />
                                  <label
                                    class='form-check-label m-0'
                                    for='inlineRadio2'
                                  >
                                    Close
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <button
                                  onClick={() =>
                                    this.resolvetick(tickdat.ticket_id)
                                  }
                                  disabled={this.state.btndis}
                                  type='submit'
                                  class='btn btn-primary btn-sm pull-right'
                                >
                                  Reply to Ticket
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-8 p-4 border m-auto pb-2'>
                        {tickdat?.descriptions
                          .sort((a, b) => b.datetime - a.datetime)
                          .map((t) => {
                            return (
                              <div class='col-md-12 m-auto'>
                                <div class='form-group raise-ticket'>
                                  <p>
                                    Replied on:
                                    <Moment
                                      unix
                                      format='DD-MM-YYYY hh:mm:ss A'
                                      fromNow
                                    >
                                      {t.datetime / 1000}
                                    </Moment>{' '}
                                  </p>
                                  <textarea
                                    class='form-control mb-2'
                                    value={t.description}
                                    disabled
                                  ></textarea>

                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <span className='text-right float-right'>
                                        Send By : {t.person}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default pushRoute(Resolve);
