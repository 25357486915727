import React, { Component } from "react";
import { pushRoute } from "../../commons/pushRoute";
import { connect } from "react-redux";
import { BallTriangle } from "react-loader-spinner";
import HomeHeader from "../../commons/Header";
import { Modal } from "react-responsive-modal";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import _ from "lodash";
import get_grplist from "../../reduxStore/action/groupListAction";
import { Table } from "tabler-react";

class UserProfile extends Component {
  state = {};

  async componentDidMount() {
    await this.intialload({ intial: true });
  }

  GoToEdit = () => {
    const { navigate } = this.props;
    const userDetails = this.props.location.state.user;

    var txc = userDetails?.user_limits;
    txc.phone = userDetails?.phone;
    txc.member_name = userDetails?.member_name;
    txc.user_type = userDetails?.user_type;

    navigate("/limitedit", { state: txc });
  };

  onOpenModal = async (item) => {
    await this.setState({
      popupOpen: true,
    });
  };

  intialload = async ({ intial, userBkn }) => {
    try {
      const user = this.props.location.state.user;

      const detailsCard = _.pick(
        user,
        "account_type",
        "intro_id",
        "kyc_status",
        "member_email",
        "member_name",
        "phone",
        "user_group",
        "user_type"
      );

      _.omit(user, ["age"]);

      if (!this.props.grouplist) {
        await get_grplist();
      }
      const gg = this.props.grouplist;

      const groups = gg.map((item) => {
        return item.user_group;
      });

      if (user?.images) {
        const userImages = [...Object.values(user?.images)];
        await this.setState({ userImages });
      }

      await this.setState({
        groups,
        user,
        detailsCard,
        cryptoaddress: user?.cryptoaddress,
      });
    } catch (error) {
      await this.setState({ loader: false });
    }
  };

  onCloseModal = async () => {
    await this.setState({
      popupOpen: false,
      groupChangePopupOpen: false,
      delpop: false,
    });
  };

  userGroupChange = async () => {
    await this.setState({ backEndBtn: true });
    try {
      const { changegroupvalue, user } = this.state;
      if (!changegroupvalue) {
        toastfun("Select User Group", "error");
        await this.setState({ backEndBtn: false });

        return;
      }
      const obj = {
        phone: user.phone,
        user_group: changegroupvalue,
      };

      const { success } = await backEndCallObj("/admin/change_user_group", obj);
      if (success) {
        await this.setState({ backEndBtn: false });
        await this.getUserBknd();

        await this.setState({ groupChangePopupOpen: false, user });
      }
    } catch (error) {
      await this.setState({ backEndBtn: false });
    }
  };

  close = async () => {
    await this.setState({
      popupOpen: false,
      userinfo: false,
      btndis: false,
      btndis1: false,
    });
  };

  getUserBknd = async () => {
    const { user } = this.state;
    try {
      const obj = {
        userid: user.userid.replaceAll(" ", ""),
      };
      const res = await backEndCallObj("/adminget/getuser", obj);
      if (res) {
        const {
          createdAt,
          address,
          __v,
          _id,
          fcm_token,
          transaction_pin,
          updatedAt,
          fee,
          images,
          ...dd
        } = res.user;

        await this.setState({ user: dd });
      }
    } catch (error) {
      toastfun(error.response.data, "error");
    }
  };

  handleChangeGroupInput = async (e) => {
    await this.setState({ changegroupvalue: e.target.value });
  };

  UpdateStatus = async () => {
    await this.setState({ backEndBtn: true });

    try {
      const user = { ...this.state.user };
      var obj = {
        userid: user.userid,
        status: user.status === "1" ? "0" : "1",
      };

      const data = await backEndCallObj("/admin/suspenduser", obj);
      if (data) {
        toastfun("Status Updated", "success");
        await this.getUserBknd();
        this.close();
        await this.setState({ backEndBtn: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }

      this.setState({ popupOpen: false, backEndBtn: false });
    }
  };

  onOpenGroupModal = async (item) => {
    await this.setState({ groupChangePopupOpen: true, userDetails: item });
  };

  renderObjValue = (title, obj) => {
    return (
      <>
        {obj &&
          Object.keys(obj).map((key) => {
            return (
              <>
                {obj[key] &&
                toString.call(obj[key]).slice(8, -1) === "Object" ? (
                  this.renderObjValue(title, obj[key])
                ) : (
                  <>
                    <li>
                      <ul className="d-flex-spa cap">
                        <span
                          className="ca"
                          style={{
                            color: "blue",
                          }}
                        >
                          {key} :
                        </span>
                        <span>{obj[key]}</span>
                      </ul>
                    </li>
                  </>
                )}
              </>
            );
          })}
      </>
    );
  };

  render() {
    const {
      user,
      userImages,
      popupOpen,
      backEndBtn,
      groupChangePopupOpen,
      groups,
      detailsCard,
    } = this.state;

    const { navigate } = this.props;

    return (
      <>
        <HomeHeader myprops={this.props} />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title">
                        <b> User Information:{user?.phone} </b>
                      </h3>
                      <button
                        value={user?.status === "1" ? "Suspend" : "Active"}
                        type="button"
                        onClick={(val) => this.onOpenModal()}
                        className={
                          user?.status === "1"
                            ? "btn btn-sm  btn-light mx-1 my-1 text-center"
                            : "btn btn-sm  btn-success mx-1 my-1 text-center"
                        }
                      >
                        {user?.status === "1" ? "Suspend" : "Active"}
                      </button>
                      <button
                        type="button"
                        onClick={() => this.onOpenGroupModal()}
                        className={
                          "btn btn-sm  btn-light mx-1 my-1 text-center"
                        }
                      >
                        {user?.user_group}
                      </button>

                      <button
                        onClick={() => navigate(-1)}
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <div
                            className="tab-pane fade show active row"
                            id="solid-justified-tab11"
                          >
                            <div className="card-body datatable-scroll">
                              <div
                                className="row "
                                style={{
                                  marginLeft: "4rem",
                                  marginRight: "-4rem",
                                }}
                              >
                                <div
                                  style={{
                                    width: "37%",
                                    padding: "4rem",
                                    marginTop: "-3rem",
                                    marginLeft: "-4rem",
                                  }}
                                >
                                  <div className="card card--secondary">
                                    <header className="card__header">
                                      <div className="ctitle mt-2">
                                        {" "}
                                        User Details
                                      </div>
                                    </header>
                                    <div className="card__body">
                                      {this.renderObjValue(
                                        " User Details",
                                        detailsCard
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    width: "37%",
                                    padding: "4rem",
                                    marginTop: "-3rem",
                                    marginLeft: "-5rem",
                                  }}
                                >
                                  <div className="card card--secondary">
                                    <header className="card__header">
                                      <div className="ctitle mt-2">
                                        {" "}
                                        Balances
                                      </div>
                                    </header>
                                    <div className="card__body">
                                      {this.renderObjValue(
                                        "Balances",
                                        user?.balances
                                      )}
                                    </div>
                                    <header
                                      className="card__header"
                                      style={{
                                        height: "2rem",
                                        backgroundColor: "#5a9be5",
                                      }}
                                    >
                                      <div className="ctitle mt-0">
                                        {" "}
                                        Address
                                      </div>
                                    </header>
                                    <div className="card__body">
                                      {this.renderObjValue(
                                        "Address",
                                        user?.cryptoaddress
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "37%",
                                    padding: "4rem",
                                    marginTop: "-3rem",
                                    marginLeft: "-5rem",
                                  }}
                                >
                                  <div className="card card--secondary">
                                    <header className="card__header">
                                      <div className="ctitle mt-2">
                                        {" "}
                                        User Limits
                                      </div>
                                    </header>
                                    <div className="card__body">
                                      {this.renderObjValue(
                                        "User Limits",
                                        user?.user_limits
                                      )}
                                    </div>
                                    <header
                                      className="card__header"
                                      style={{
                                        height: "2rem",
                                        backgroundColor: "#5a9be5",
                                      }}
                                    >
                                      <div className="ctitle mt-0">
                                        {" "}
                                        Address
                                      </div>
                                    </header>
                                    <div className="card__body">
                                      {this.renderObjValue(
                                        "Address",
                                        user?.cryptoaddress
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-header header-elements-inline border border-1 mt-3">
                            <h3 className="card-title">
                              <b> User Documents </b>
                            </h3>
                          </div>
                          <div className="card-header header-elements-inline border border-1">
                            {user?.kyc_status === "pending" ? (
                              <>
                                <h2> KYC Status Pending</h2>
                              </>
                            ) : (
                              <>
                                <>
                                  <div className=" row col-12 dflex_space_arounf">
                                    {userImages ? (
                                      Object.values(userImages).map((item) => {
                                        return (
                                          <>
                                            {item.substring(item.length - 3) ===
                                            "pdf" ? (
                                              <div className="col-2 p-3 req_height  ">
                                                {" "}
                                                <a
                                                  className="btn btn-primary p-1 "
                                                  href={item}
                                                >
                                                  Click Here to view PDF File
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                <img
                                                  className="col-4 p-3 req_height"
                                                  src={item}
                                                  alt="No "
                                                />

                                                {/* <a className="btn btn-primary p-1" href={this.state.image1} target="_blank"> Click Here to view PDF File</a> */}
                                              </>
                                            )}
                                          </>
                                        );
                                      })
                                    ) : (
                                      <>
                                        <h2>Documents Not Found</h2>
                                      </>
                                    )}
                                  </div>
                                </>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {popupOpen && (
          <Modal open={popupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Update {this.state.uid}{" "}
                      User Status
                    </h5>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={() => this.UpdateStatus()}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Yes
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {groupChangePopupOpen && (
          <Modal open={groupChangePopupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Change Group{" "}
                      {this.state.uid}{" "}
                    </h5>
                  </div>

                  <div className=" grid_center">
                    <div className="col-4">
                      <select
                        style={{
                          width: "10rem",
                          height: "80%",
                        }}
                        placeholder="min"
                        className="text-center py-1 m-1 "
                        onChange={(e) => this.handleChangeGroupInput(e)}
                      >
                        <option value="" required>
                          Select
                        </option>
                        {groups.map((r) => (
                          <option value={r} required>
                            {r}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={(val) => this.userGroupChange(val)}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Change
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grouplist: state.grouplist,
    user: state.getcurrentuser,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserProfile));
