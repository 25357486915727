import { GET_PROFITREPORTS } from '../action/profitReportsAction';

const profitReportsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_PROFITREPORTS:
      return payload;
    default:
      return state;
  }
};
export default profitReportsReducer;
