import React, { Component } from 'react';
import { connect } from 'react-redux';
import Joi from 'joi-browser';
import Form from '../../commons/form';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';
import { BallTriangle } from 'react-loader-spinner';
import { fields } from '../../data';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import get_grplist from '../../reduxStore/action/groupListAction';

class AddNewGroup extends Form {
  state = {
    data: {},
    disabledObj: {},
    btndisabled: false,
  };

  async componentDidMount() {
    const groupDetails = this.props.location.state.groupDetails;
    if (groupDetails) {
      const { data, disabledObj } = this.state;
      data.user_group = groupDetails.user_group;
      disabledObj.user_group = true;
      const group_id = this.props.grouplist.filter(
        (item) => item.user_group === groupDetails?.user_group
      )[0].group_id;
      await this.setState({ data, disabledObj, group_id });
    }
  }

  schema = {
    user_group: Joi.string().required(),
    partner_name: Joi.string().required(),
    channel: Joi.string().required(),
    min: Joi.number().required(),

    max: Joi.number().greater(
      Joi.ref('min', {
        adjust: (value) => ~~value,
      })
    ),

    fee: Joi.string().required(),
    type: Joi.string().valid('flat', 'percentage').required(),
  };

  doSubmit = async () => {
    const { navigate } = this.props;
    const { data } = this.state;

    this.setState({ btndisabled: true });

    if (data?.type === 'percentage') {
      if (data?.fee > 90) {
        toastfun('Fee must in below 90%', 'error');
        this.setState({ btndisabled: false });
        return;
      }
    }

    try {
      data.group_id = this.state.group_id;
      delete data.user_group;
      const { success } = await backEndCallObj('/admin/add_new_partner', data);

      if (success) {
        toastfun(success, 'success');
        await get_grplist();
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (ex) {
      toastfun(ex.response.data, 'error');
      this.setState({ btndisabled: false });
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
      console.warn(ex);
    }
  };

  render() {
    const { data, errors, disabledObj } = this.state;
    const { navigate } = this.props;
    return (
      <>
        <HomeHeader />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-10 offset-md-1'>
                  <div className='card'>
                    <div className='card-header header-elements-inline '>
                      <h3 className='card-title offset-md-4'>
                        <b>
                          {' '}
                          Add Partner -{' '}
                          <span
                            style={{
                              color: 'red',
                              fontSize: '1.5rem',
                              textDecoration: 'underline',
                            }}
                          >
                            {' '}
                            {data?.user_group}
                          </span>{' '}
                          Group
                        </b>
                      </h3>

                      <button
                        onClick={() => navigate(-1)}
                        type='button'
                        class='btn-close'
                        aria-label='Close'
                        style={{ cursor: 'pointer', float: 'right' }}
                      >
                        X
                      </button>
                    </div>

                    <hr className='mt-0'></hr>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='form-group col-md-3'>
                          <label> Group Name </label>
                          <input
                            disabled={disabledObj.user_group}
                            placeholder='Enter Group Name Ex.UZ3'
                            name='user_group'
                            value={data?.user_group}
                            className='form-control'
                            onChange={this.handleChangeNoValidate}
                          />

                          <small>{errors?.user_group}</small>
                        </div>

                        <div className='form-group col-md-3'>
                          <label> Partner Name </label>
                          <select
                            name='partner_name'
                            value={data?.partner_name}
                            className='form-control'
                            onChange={this.handleChangeNoValidate}
                          >
                            {fields?.partner_names.map((r) => (
                              <option value={r.value} required>
                                {r.label}
                              </option>
                            ))}
                          </select>
                          <small>{errors?.partner_name}</small>
                        </div>

                        <div className='form-group col-md-3'>
                          <label> Channel Name</label>
                          <input
                            type='text'
                            name='channel'
                            className='form-control'
                            value={data?.channel}
                            placeholder='Enter Channel Name'
                            onChange={this.handleChangeNoValidate}
                          />

                          <small>{errors?.channel}</small>
                        </div>

                        <div className='form-group col-md-3'>
                          <label> Type </label>
                          <select
                            name='type'
                            type='text'
                            className='form-control'
                            placeholder='Enter type'
                            value={data?.type}
                            onChange={this.handleChangeNoValidate}
                          >
                            <option value='' required>
                              --Select Type--
                            </option>
                            <option value='flat' required>
                              flat
                            </option>
                            <option value='percentage' required>
                              percentage
                            </option>
                          </select>
                          <small>{errors?.type}</small>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col-md-3'>
                          <label>Fee </label>
                          <input
                            type='text'
                            name='fee'
                            className='form-control'
                            placeholder='Enter fee Amount'
                            value={data?.fee}
                            onChange={this.handleNumberNoValidate}
                          />
                          <small>{errors?.fee}</small>
                        </div>

                        <div className='form-group col-md-3'>
                          <label>Minimum </label>
                          <input
                            type='text'
                            name='min'
                            className='form-control'
                            placeholder='Enter Minimum Amount'
                            value={data?.min}
                            onChange={this.handleNumberNoValidate}
                          />
                          <small>{errors?.min}</small>
                        </div>

                        <div className='form-group col-md-3'>
                          <label>Maximum </label>
                          <input
                            type='text'
                            name='max'
                            className='form-control'
                            placeholder='Enter Maximum Amount'
                            value={data?.max}
                            onChange={this.handleNumberNoValidate}
                          />
                          <small>{errors?.max}</small>
                        </div>

                        <div className='col-md-2'>
                          {!this.state.loader ? (
                            <button
                              type='button'
                              onClick={this.handleSubmit}
                              className='btn btn-primary btn-lg btn-block mt-3 ml-4 loc-bttn'
                              style={{
                                background: 'cadetblue',
                                fontSize: '18px',
                              }}
                              disabled={this.state.btndisabled}
                            >
                              {' '}
                              Create{' '}
                            </button>
                          ) : (
                            <BallTriangle
                              className='text-center  offset-5'
                              // type="Bars"
                              color='grey'
                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                              height={80}
                              width={80}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grouplist: state.grouplist,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(AddNewGroup));
