import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';

import HomeHeader from '../../commons/Header';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { commenttickets } from '../../services/ticketService';

class Viewtickets extends Component {
  state = {
    rbtn: 'Open',
    content: '',
  };
  handlechange = async (e) => {
    await this.setState({ rbtn: e.target.value });
  };
  handlechangetext = async (e) => {
    await this.setState({ content: e.target.value });
  };
  close = () => {
    const { navigate } = this.props;
    navigate('/closedticks');
  };
  render() {
    const tickdat = this.props.location.state;

    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title ml-3'>
                        <b> Resolve Ticket </b>
                      </h3>
                      <button
                        onClick={this.close}
                        type='button'
                        class='btn-close'
                        aria-label='Close'
                        style={{
                          cursor: 'pointer',
                          color: 'white',
                          background: 'red',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        X
                      </button>
                    </div>
                    <hr></hr>
                    <div className='card-body'>
                      <div className='tab-content'>
                        <div className='mx-2'>
                          <span className='float-right'>
                            <h3>Ticket-ID: #{tickdat?.ticket_id}</h3>
                          </span>
                        </div>
                        <div className='row'>
                          <span className='d-flex'>
                            <h5>Raised By:</h5>
                            <p
                              className='ml-2 text-primary '
                              style={{ fontSize: '15px' }}
                            >
                              {this.state.raisedname}
                              ,&nbsp;{'+' + tickdat?.phone}
                            </p>
                          </span>
                        </div>
                        <span className='float-left'>
                          <h5>
                            Issue:
                            <span className='ml-3 text-primary'>
                              {tickdat?.subject}
                            </span>
                          </h5>
                        </span>
                      </div>
                    </div>
                    <div class='row mb-4'>
                      <div class='col-md-8 p-4 border m-auto pb-2'>
                        {tickdat?.descriptions.reverse().map((t) => {
                          return (
                            <div class='col-md-12 m-auto'>
                              <div class='form-group raise-ticket'>
                                <p>
                                  Replied on:
                                  <Moment
                                    unix
                                    format='DD-MM-YYYY hh:mm:ss A'
                                    fromNow
                                  >
                                    {t.datetime / 1000}
                                  </Moment>{' '}
                                </p>
                                <textarea
                                  class='form-control mb-2'
                                  value={t.description}
                                  disabled
                                ></textarea>

                                <div className='row'>
                                  <div className='col-md-12'>
                                    <span className='text-right float-right'>
                                      Send By : {t.person}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default pushRoute(Viewtickets);
