import { GET_SELLERLIST } from "../action/sellerlistAction";

const sellerlistReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_SELLERLIST:
      return payload;
    default:
      return state;
  }
};
export default sellerlistReducer;
