import React, { Component } from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";
import { connect } from "react-redux";
// import { Table } from 'tabler-react';
import Footer from "../../commons/Footer";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import get_userslist from "../../reduxStore/action/userlistAction";
import Moment from "react-moment";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import CommonTable from "../../commons/commonTable";
import { getCurrentUser } from "../../services/authService";
import get_grplist from "../../reduxStore/action/groupListAction";

class UsersList extends Component {
  state = {
    user: [],
    alluser: [],
    account_type: "Individual",
    popupOpen: false,
    delpop: false,
    bknLoader: true,
    skip: 0,
    loadMoreButton: true,
    backEndBtn: false,
    loader: true,
    active: "0",
    uid: "",
    ustatus: "",
    alldata: [],
    checked: false,
    checked1: false,
    loadmore: false,
    userinfo: false,
    uf: [],
    btndis: false,
    btndis1: false,
    ntable: false,
  };

  componentDidMount = async () => {
    if (!this.props.getuserlist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  activeColumn = {
    label: "ACTION",
    content: (item) => (
      <button
        value={item.status === "1" ? "Suspend" : "Active"}
        type="button"
        onClick={(e) => this.onOpenModal(item)}
        className={
          item.status === "1"
            ? "btn btn-sm  bg-secondary bb  text-center"
            : "btn btn-sm  btn-success bb text-center"
        }
      >
        {item.status === "1" ? "Suspend" : "Active"}
      </button>
    ),
  };

  onOpenGroupModal = async (item) => {
    await this.setState({ groupChangePopupOpen: true, userDetails: item });
  };

  userChangeGroup = {
    label: "GROUP",
    content: (item) => (
      <button
        value={item.user_group}
        type="button"
        onClick={(e) => this.onOpenGroupModal(item)}
        className={"btn btn-sm bb  bg-primary fw-bolder  text-center"}
      >
        {item.user_group}
      </button>
    ),
  };

  userViewGroup = {
    label: "VIEW",
    content: (item) => (
      <button
        type="button"
        onClick={(e) => this.goUserViewPage(item)}
        className={"btn btn-sm bb bg-dark  text-center"}
      >
        View
      </button>
    ),
  };

  constructor() {
    super();

    const user = getCurrentUser();

    if (user && user.admin_type === "1") {
      this.colums.push(this.activeColumn);
      this.colums.push(this.userChangeGroup);
      this.colums.push(this.userViewGroup);
      this.colums.push(this.historyButton);
    }
  }

  goToHistoryPage = (user) => {
    const { navigate } = this.props;
    navigate("/txnhistory", { state: user });
  };

  colums = [
    {
      label: "DATE",
      content: (date) => (
        <Moment unix format="DD-MM-YYYY" fromNow>
          {date.date_of_join / 1000}
        </Moment>
      ),
    },
    { path: "member_name", label: "NAME" },

    { path: "userid", label: "USER ID" },
    { path: "phone", label: "PHONE" },
    {
      label: "STATUS",
      content: (item) => (item.status === "1" ? "Active" : "Suspend"),
    },

    { path: "intro_id_1", label: "REFERRAL I" },
    { path: "intro_id_2", label: "REFERRAL II" },
  ];

  handleChangeGroupInput = async (e) => {
    await this.setState({ changegroupvalue: e.target.value });
  };

  userGroupChange = async () => {
    await this.setState({ backEndBtn: true });
    try {
      const { changegroupvalue, userDetails } = this.state;
      if (!changegroupvalue) {
        toastfun("Select User Group", "error");
        await this.setState({ backEndBtn: false });

        return;
      }
      const obj = {
        phone: userDetails.phone,
        group_id: changegroupvalue,
      };
      const { success } = await backEndCallObj("/admin/change_user_group", obj);
      if (success) {
        await this.setState({ backEndBtn: false });

        this.intialload({ intial: true });
        await this.setState({ groupChangePopupOpen: false });
      }
    } catch (error) {
      await this.setState({ backEndBtn: false });
    }
  };

  intialload = async ({ intial }) => {
    try {
      await this.setState({ bknLoader: true });
      var obj = {
        skip: 0,
        type: this.state.groupid ? "" : this.state.account_type,
        user_group: this.state.groupid ? this.state.groupid : "",
      };
      if (intial) {
        const dd = await backEndCallObj("/adminget/get_users_by_type", obj);

        const data = { ...this.props.getuserlist };
        data[this.state.account_type] = dd.users;

        await get_userslist(data);
      }

      if (!this.props.grouplist) {
        await get_grplist();
      }
      const gg = this.props.grouplist;

      const groups = gg.map((item) => {
        return item.user_group;
      });

      const groups2 = gg.map((item) => {
        var data = { user_group: item.user_group, group_id: item.group_id };
        return data;
      });

      await this.setState({ bknLoader: false });
      const user = this.props.getuserlist[this.state.account_type];
      await this.setState({
        groups,
        groups2,
        user,
        alldata: user,
      });
    } catch (error) {
      await this.setState({ bknLoader: false });
    }
  };

  onOpenModal = async (item) => {
    const status = item?.status === "1" ? "Suspend" : "Active";
    await this.setState({
      popupOpen: true,
      ustatus: status,
      uid: item.phone,
    });
  };

  historyButton = {
    label: "HISTORY",
    content: (k) => (
      <button
        className=" btn  rounded-pill history_button "
        onClick={() => this.goToHistoryPage(k)}
      >
        History
      </button>
    ),
  };

  selectedValue = async (val, id) => {
    await this.setState({ backEndBtn: true });
    try {
      var obj = {
        userid: this.state.uid,
        status: this.state.ustatus === "Active" ? "1" : "0",
      };

      const data = await backEndCallObj("/admin/suspenduser", obj);
      if (data) {
        toastfun("Status Updated", "success");
        await this.intialload({ intial: true });
        this.close();
        await this.setState({ backEndBtn: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }

      this.setState({ popupOpen: false, backEndBtn: false });
    }
  };
  onCloseModal = async () => {
    await this.setState({
      popupOpen: false,
      groupChangePopupOpen: false,
      delpop: false,
    });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      // window.location = "/userslist";
      this.setState({ user: this.state.alldata });
    } else {
      if (this.state.alldata && this.state.alldata.length) {
        const fd = Object.values(this.state.alldata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ user: fd, currentPage: 1 });
      }
    }
  };

  fetchData = async () => {
    this.setState({ loadmore: true });
    try {
      await this.setState({
        skip: this.state.alldata.length,
      });

      const obj = {
        skip: parseInt(this.state.skip),
        type: this.state.groupid ? "" : this.state.account_type,
        user_group: this.state.groupid ? this.state.groupid : "",
      };

      const dd = await backEndCallObj("/adminget/get_users_by_type", obj);
      const data = dd.users;

      this.setState({ loadmore: false });

      if (data && data.length === 0) {
        await this.setState({ loadMoreButton: false });
      } else {
        let newr = [...this.state.user, ...data];

        const ttt = { ...this.props.getuserlist };

        ttt[this.state.account_type] = newr;

        const uniqueValues = new Set(newr.map((v) => v.name));

        if (uniqueValues.size < newr.length) {
        }

        await get_userslist(ttt);

        const user = this.props.getuserlist[this.state.account_type];

        await this.setState({
          user,
          alldata: user,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }
    }
  };

  close = async () => {
    await this.setState({
      popupOpen: false,
      userinfo: false,
      btndis: false,
      btndis1: false,

      imagebtn: false,
      signbtn: false,
    });
  };

  handleUser = (e) => {
    this.setState({ us: e.target.value });
  };

  goUserViewPage = async (item) => {
    this.setState({ btndis: true });
    const { navigate } = this.props;
    try {
      const obj = {
        userid: item.userid.replaceAll(" ", ""),
      };

      const dd = this.props.getuserlist[this.state.account_type];

      const gain = dd?.filter((el) => el?.userid === obj?.userid);
      if (gain.length) {
        navigate("/usertabs", { state: { user: gain[0] } });
        return;
      }

      const { user } = await backEndCallObj("/adminget/getuser", obj);
      if (user) {
        navigate("/usertabs", { state: { user } });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }
      this.setState({ btndis: false });
    }
  };

  handleTableData = async (account_type) => {
    await this.setState({ account_type, loadMoreButton: true, groupid: "" });

    if (!this.props.getuserlist[this.state.account_type]) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  getUserByGroupid = async () => {
    this.setState({ account_type: "" });
    await this.intialload({ intial: true });
  };

  opendelpopup = async (user) => {
    this.setState({ delpop: true, userr: user });
  };

  refresh = () => {
    this.intialload({ intial: true });
  };
  close = () => {
    const { navigate } = this.props;
    navigate("/userslist");
  };
  render() {
    const {
      user,
      popupOpen,
      backEndBtn,
      groupChangePopupOpen,
      account_type,
      groupid,
      groups2,
    } = this.state;

    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />

        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline"></div>

                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="solid-justified-tab11"
                          >
                            {this?.props?.location?.state?.length > 0 ? (
                              <div className="card-body datatable-scroll">
                                <div className="d-flex col-md-1 offset-md-11 my-3">
                                  <i
                                    class="fa fa-close text-danger"
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.close}
                                  ></i>
                                </div>
                                <div className="mb-2 table-responsive dt-table">
                                  <>
                                    <CommonTable
                                      colums={this.colums}
                                      data={this?.props?.location?.state}
                                    />
                                  </>
                                </div>
                              </div>
                            ) : (
                              <div className="card-body datatable-scroll">
                                <div
                                  className="form-group row"
                                  // style={{ alignItems: 'center' }}
                                >
                                  <div className="col d-flex m-2">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Individual");
                                      }}
                                      className={
                                        this.state.account_type === "Individual"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Individual Users
                                    </button>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Corporate");
                                      }}
                                      className={
                                        this.state.account_type === "Corporate"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Corporate Users
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Basic Silver");
                                      }}
                                      className={
                                        this.state.account_type ===
                                        "Basic Silver"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Basic Silver Users
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Trader");
                                      }}
                                      className={
                                        this.state.account_type === "Trader"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Trader Users
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Un-verified");
                                      }}
                                      className={
                                        this.state.account_type ===
                                        "Un-verified"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Un-Verified Users
                                    </button>
                                    <div className="col-md-4 offset-md-3">
                                      <div className="d-flex mr-5">
                                        <select
                                          type="text"
                                          value={this.state.groupid}
                                          placeholder="Get Details By User ID/Username"
                                          pattern="[0-9]*"
                                          className=""
                                          onChange={(e) =>
                                            this.setState({
                                              groupid: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="">
                                            --Users By Group--
                                          </option>

                                          {groups2 &&
                                            groups2.map((item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.user_group}
                                                >
                                                  {item.user_group}
                                                </option>
                                              );
                                            })}
                                        </select>
                                        <button
                                          disabled={this.state.btndis1}
                                          className="btn bg-warning btn-sm"
                                          onClick={() =>
                                            this.getUserByGroupid()
                                          }
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className='d-flex no-gutters'> */}
                                  {/* <div className="col-lg-3 col-md-10 col-sm-10 d-flex ">
                                  <input
                                    type="text"
                                    style={{
                                      marginBottom: "1.2rem",
                                      width: "18rem",
                                    }}
                                    placeholder="Search.."
                                    pattern="[0-9]*"
                                    className="py-1"
                                    onChange={this.handleSearch}
                                  />
                                  <i
                                    style={{ fontSize: "1.1rem" }}
                                    className="fa fa-search btn btn-primary mb-3  btn-sm lh-22 rounded-0"
                                  ></i>
                                   {this.state.loadmore ? null : (
                                     <i
                                       className="fa fa-refresh ml-2"
                                       style={{ fontSize: "2rem" }}
                                       aria-hidden="true"
                                       onClick={this.refresh}
                                     ></i>
                                   )}
                                </div> */}
                                </div>
                                <div className="row">
                                  <h3
                                    className="card-title offset-md-5 row text-primary"
                                    style={{ fontSize: "1.6rem" }}
                                  >
                                    <b>
                                      <span style={{ fontSize: "1.6rem" }}>
                                        {account_type
                                          ? account_type?.toUpperCase()
                                          : groupid?.toUpperCase()}
                                        &nbsp;&nbsp;
                                      </span>
                                      USERS
                                    </b>
                                  </h3>
                                  <div className="offset-md-3">
                                    {this.state.loadmore ? null : (
                                      <i
                                        className="fa fa-refresh ml-2 offset-md-4 text-danger"
                                        style={{ fontSize: "2rem" }}
                                        aria-hidden="true"
                                        onClick={this.refresh}
                                      ></i>
                                    )}
                                  </div>
                                </div>

                                <div className="mb-2 table-responsive dt-table">
                                  <>
                                    <CommonTable
                                      colums={this.colums}
                                      data={user}
                                      bknLoader={this.state.bknLoader}
                                      loadMoreButton={this.state.loadMoreButton}
                                      loadmore={this.state.loadmore}
                                      fetchData={this.fetchData}
                                    />
                                  </>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        {popupOpen && (
          <Modal open={popupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Update{" "}
                      <span className="pop_phone">{"+" + this.state.uid} </span>
                      User Status
                    </h5>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={(val) => this.selectedValue(val)}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Yes
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {groupChangePopupOpen && (
          <Modal open={groupChangePopupOpen} onClose={this.onCloseModal} center>
            <div>
              <div className="register_top in">
                <div className="signinsignup-widget">
                  <div className="popup-title py-1">
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Are You Sure? <br></br>You Want to Change Group{" "}
                      <span className="pop_phone">{this.state.uid} </span>
                    </h5>
                  </div>

                  <div className=" grid_center">
                    <div className="col-4">
                      <select
                        style={{
                          width: "10rem",
                          height: "80%",
                        }}
                        placeholder="min"
                        className="text-center py-1 m-1 "
                        onChange={(e) => this.handleChangeGroupInput(e)}
                      >
                        <option value="" required>
                          Select
                        </option>
                        {groups2.map((r) => (
                          <option value={r.group_id} required>
                            {r.user_group}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="login-form">
                    <div className="signin_username">
                      <div className="signin-form">
                        <form className="form-horizontal">
                          <div className="mck-form-group"></div>
                          <div className="getstarted text-center py-2">
                            <button
                              onClick={(val) => this.userGroupChange(val)}
                              disabled={backEndBtn}
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-success m-1 "
                            >
                              Change
                            </button>
                            <button
                              disabled={backEndBtn}
                              type="button"
                              className="btn btn-danger m-1"
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <Modal open={this.state.delpop} onClose={this.onCloseModal} center>
          <div onClick={this.onOpenpopupModal}>
            <div className="register_top in">
              <div className="signinsignup-widget">
                <div className="popup-title py-1">
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Are You Sure? <br></br>You Want to Permanantly Delete{" "}
                    {this.state.userr?.Mobile} User ?
                  </h5>
                </div>

                <div className="login-form">
                  <div className="signin_username">
                    <div className="signin-form">
                      <form className="form-horizontal">
                        <div className="mck-form-group"></div>
                        <div className="getstarted text-center py-2">
                          <button
                            onClick={(val) => this.deluserpopup(val)}
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-success m-1"
                            disabled={this.state.btndis}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger m-1"
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(UsersList));
