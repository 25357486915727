import store from "../store/index";
import user from "../../services/userService";
import { toast } from "react-toastify";

export var GET_BANKLISTS = "GET_BANKLISTS";

async function get_bankslist() {
  try {
    const data = await user.getuserbanks();
    store.dispatch({ type: GET_BANKLISTS, payload: data });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_bankslist;
