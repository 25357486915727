import { backEndCallObj } from '../../services/MainService';
import store from '../store/index';

export var GET_GRPLIST = 'GET_GRPLIST';

async function get_grplist() {
  try {
    const data = await backEndCallObj('/adminget/get_usergroup_list');
    store.dispatch({ type: GET_GRPLIST, payload: data });
  } catch (ex) {
    console.warn(ex);
  }
}

export default get_grplist;
