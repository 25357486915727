import React, { Component } from 'react';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import Footer from '../../commons/Footer';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import get_opentickets from '../../reduxStore/action/openticketAction';
import CommonTable from '../../commons/commonTable';

class Opentickets extends Component {
  state = {
    openticks: [],
    loadMoreButton: false,
    backEndBtn: false,
    stoploader: true,
  };
  componentDidMount = async () => {
    if (!this.props.getopentickets) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  intialload = async ({ intial }) => {
    try {
      await this.setState({ bknLoader: true });

      if (intial) {
        await get_opentickets();
      }
      const openticks = this.props.getopentickets;
      await this.setState({ bknLoader: false });

      await this.setState({
        openticks,
      });
    } catch (error) {
      await this.setState({ bknLoader: false });
    }
  };

  GotoResolvePage = (t) => {
    const { navigate } = this.props;
    navigate('/resolve', {
      state: t,
    });
  };

  colums = [
    {
      label: 'DATE',
      content: (date) => (
        <>
          <Moment format='DD-MM-YYYY hh:mm A'>{date.createdAt}</Moment>
        </>
      ),
    },
    { path: 'ticket_id', label: 'TICKET ID' },
    { path: 'userid', label: 'USER ID' },
    { path: 'phone', label: 'PHONE' },

    { path: 'subject', label: 'SUBJECT' },
    { path: 'status', label: 'STATUS' },
    {
      label: 'ACTION',
      content: (t) => (
        <div className='no-gutters'>
          <div
            className='btn text-white theme-btn bg-success'
            style={{
              fontWeight: '600',
              fontSize: '14px',
            }}
            onClick={(e) => {
              this.GotoResolvePage(t);
            }}
          >
            Resolve
          </div>
        </div>
      ),
    },
  ];

  render() {
    const { openticks } = this.state || {};

    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Open Support Tickets </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'></div>

                              <div className='mb-2 table-responsive dt-table'>
                                <CommonTable
                                  colums={this.colums}
                                  data={openticks}
                                  bknLoader={this.state.bknLoader}
                                  loadMoreButton={this.state.loadMoreButton}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getopentickets: state.getopentickets,
    user: state.getcurrentuser,
  };
};

export default connect(mapStateToProps)(pushRoute(Opentickets));
// export default Opentickets;
