import { GET_OPENTICKETS } from "../action/openticketAction";

const openticketsReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_OPENTICKETS:
      return payload;
    default:
      return state;
  }
};
export default openticketsReducer;
