import http from './commonServices/httpService';
// import { apiUrl } from "../config.json";
import helpers from './commonServices/cryptos';

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + '/nimdfs';
// "https://vidlymovie.herokuapp.com/api";

export async function commenttickets(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/commentticket', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function opentickets() {
  const { data } = await http.post(apiUrl + '/nimv/opentickets');
  return helpers.decryptobj(data);
}

export async function closetickets() {
  const { data } = await http.post(apiUrl + '/nimv/closedtick');
  return helpers.decryptobj(data);
}
export default {
  opentickets,
  commenttickets,
  closetickets,
};
