import { GET_USERSLIST } from "../action/userlistAction";

const userlisttReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_USERSLIST:
      return payload;
    default:
      return state;
  }
};
export default userlisttReducer;
