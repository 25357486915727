import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_PENDPESOWIDR = 'GET_PENDPESOWIDR';

async function get_pendingpesopwtdr(data) {
  const { success } = await backEndCall('/adminget/get_pending_peso_withdraw');
  try {
    store.dispatch({ type: GET_PENDPESOWIDR, payload: success });
  } catch (ex) {
    console.warn(ex);
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_pendingpesopwtdr;
