import React from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";
import Footer from "../../commons/Footer";
import { BallTriangle, Bars, Audio, ColorRing } from "react-loader-spinner";
import Joi from "joi-browser";
import { fields } from "../../data";
import Form from "../../commons/form";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import get_adminslist from "../../reduxStore/action/adminslistAction";
import { connect } from "react-redux";

class APIKeysUpdate extends Form {
  state = {
    key: "",
    secval: "",
    disabledObj: {},
    btndis: false,
    loader: false,
  };

  schema = {
    name: Joi.string().required(),
    API_KEY: Joi.string().required(),
    API_SECRECT: Joi.string().required(),
  };
  handleChangekey = (e) => {
    this.setState({ key: e.target.value });
  };
  handleChangevalue = (e) => {
    this.setState({ secval: e.target.value });
  };

  doSubmit = async () => {
    this.setState({ loader: true });
    if (this.state.key.length >= 5 && this.state.secval.length >= 5) {
      try {
        var data = {
          name: "BINANCE",
          API_KEY: this.state.key,
          API_SECRECT: this.state.secval,
        };

        const res = await backEndCallObj("/admin/edit_APIKEYS", data);
        if (res) {
          toastfun(res.success, "success");
          setTimeout(() => {
            this.setState({ key: "", secval: "" });
            this.setState({ loader: false });
          }, 2000);
        }
      } catch (error) {
        toastfun(error.response.data, "error");
        await this.setState({ btndis: false });
        this.setState({ loader: false });
      }
    } else {
      toastfun("Please Enter Valid API Keys", "error");
      await this.setState({ btndis: false });
      this.setState({ loader: false });
    }
  };

  render() {
    const { btndis, data, errors, disabledObj } = this.state;

    const { navigate } = this.props;
    return (
      <React.Fragment>
        <HomeHeader />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title">
                        <b> Update Binance API Keys</b>
                      </h3>

                      <button
                        onClick={() => {
                          navigate(-1);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>
                    <hr className="mt-0"></hr>
                    <div className="card-body">
                      <div className="row offset-md-1">
                        <div className="col-md-10">
                          <div className="mb-3">
                            <label className="form-label">API KEY</label>
                            <input
                              type="text"
                              name="key"
                              className="form-control"
                              value={this.state?.key}
                              onChange={this.handleChangekey}
                            />
                            <small>{errors?.API_KEY}</small>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="mb-3">
                            <label className="form-label">
                              API SECRETE KEY{" "}
                            </label>
                            <input
                              type="text"
                              name="secval"
                              className="form-control"
                              value={this.state?.secval}
                              onChange={this.handleChangevalue}
                            />
                            <small>{errors?.API_SECRECT}</small>
                          </div>
                        </div>
                      </div>
                      <div className="row offset-md-5">
                        <div className="col-md-2">
                          {!this.state.loader ? (
                            <button
                              type="button"
                              onClick={this.handleSubmit}
                              className="btn btn-primary btn-lg btn-block mt-3 ml-4 loc-bttn"
                              disabled={this.state.btndis}
                            >
                              {" "}
                              Submit{" "}
                            </button>
                          ) : (
                            <ColorRing
                              className="text-center  offset-5"
                              // type="Bars"
                              color="grey"
                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                              height={80}
                              width={80}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(APIKeysUpdate));
