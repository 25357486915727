import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_OPENTICKETS = 'GET_OPENTICKETS';

async function get_opentickets(data) {
  const { success } = await backEndCall('/adminget/get_open_tickets');

  try {
    store.dispatch({ type: GET_OPENTICKETS, payload: success });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_opentickets;
