import { GET_BANKLISTS } from "../action/userbankslistAction";

const userbanklistReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_BANKLISTS:
      return payload;
    default:
      return state;
  }
};
export default userbanklistReducer;
