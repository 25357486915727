import React, { Component } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { NavLink, useHref } from 'react-router-dom';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import { kycreq } from '../../services/kycService';
import { connect } from 'react-redux';
import get_kycreq from '../../reduxStore/action/kycreqAction';
import { backEndCall } from '../../services/MainService';
import get_traderkycreq from '../../reduxStore/action/traderkycreqAction';
import CommonTable from '../../commons/commonTable';
import { Moment } from 'react-moment';

class TraderKYCReq extends Component {
  state = {
    kdata: [],
    data: [],
    bknLoader: false,
    loadMoreButton: false,
    refresh: true,
    stoploader: true,
  };
  componentDidMount = async () => {
    if (!this.props.traderkycreqlist) {
      this.intialload({ intial: false });
    } else {
      if (this.props.location.state?.intialload === false) {
        await this.intialload({ intial: false });
        return;
      }
      this.intialload({ intial: true });
    }
  };

  colums = [
    // {
    //   label: 'DATE',
    //   content: (date) => (
    //     <Moment unix format='DD-MM-YYYY' fromNow>
    //       {date.date_of_join / 1000}
    //     </Moment>
    //   ),
    // },
    { path: 'member_name', label: 'NAME' },
    { path: 'userid', label: 'USER ID' },
    { path: 'phone', label: 'PHONE' },
    {
      label: 'STATUS',
      path: 'kyc_status',
    },

    {
      label: 'VALIDATE',
      content: (k) => (
        <div
          className='btn btn-primary'
          onClick={(e) => this.kycValidatePage(k)}
        >
          Validate
        </div>
      ),
    },
  ];

  intialload = async ({ intial }) => {
    await this.setState({ bknLoader: true, refresh: false });
    if (!intial) {
      await this.setState({ data: [], kdata: [] });

      const kdata = await backEndCall('/adminget/traderkycList');
      await get_traderkycreq(kdata);
    }
    const kdata = this.props?.traderkycreqlist;

    if (!kdata?.length) {
      this.setState({ stoploader: false });
    }

    await this.setState({ refresh: true, bknLoader: false });
    await this.setState({ kdata, data: kdata });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ kdata: this.state.data });
    } else {
      if (this.state.kdata && this.state.kdata.length > 0) {
        const fd = Object.values(this.state.kdata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ kdata: fd, currentPage: 1 });
      }
    }
  };

  kycValidatePage = (arg) => {
    const { navigate } = this.props;
    navigate('/kycvalidate', { state: arg });
  };
  refresh = () => {
    this.intialload({ intial: false });
  };

  render() {
    const { kdata } = this.state;
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Pending Trader KYC Requests </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                                {this.state.refresh ? (
                                  <i
                                    className='fa fa-refresh'
                                    style={{ fontSize: 'xx-large' }}
                                    aria-hidden='true'
                                    onClick={this.refresh}
                                  ></i>
                                ) : null}
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <CommonTable
                                  colums={this.colums}
                                  data={kdata}
                                  bknLoader={this.state.bknLoader}
                                  loadMoreButton={this.state.loadMoreButton}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    traderkycreqlist: state.traderkycreqlist,
  };
};

export default connect(mapStateToProps)(pushRoute(TraderKYCReq));
