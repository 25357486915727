import store from '../store/index';

import admin from '../../services/adminService';
import { toast } from 'react-toastify';

export var GET_TXCONTROLS = 'GET_TXCONTROLS';

async function get_txcontrols() {
  try {
    const data = await admin.txcontrols();
    store.dispatch({ type: GET_TXCONTROLS, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_txcontrols;
