import http from '../commonServices/httpService';
// import { apiUrl } from "../../config.json";
import helpers from '../commonServices/httpService';

const apiEndpoint = process.env.REACT_APP_API_URL + '/nimecom';

export async function getcategories() {
  const { data } = await http.post(apiEndpoint + '/getallcat');
  return helpers.decryptobj(data);
}
export async function addsubcat(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/addsub', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function delcat(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/delcat', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function addcat(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/addcat', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function deletesubcat(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/delsub', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export default {
  getcategories,
  addsubcat,
  deletesubcat,
  addcat,
  delcat,
};
