import React from 'react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { pushRoute } from '../../commons/pushRoute';
import Joi from 'joi-browser';

import { connect } from 'react-redux';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';
import Form from '../../commons/form';

class CROPValidate extends Form {
  state = {
    data: {
      daily_limit: 1000000,
      monthly_limit: 2500000,
      yearly_limit: 30000000,

      cashindaily_limit: 1000000,
      cashinmonthly_limit: 2500000,
      cashinyearly_limit: 30000000,

      cashin_fee: 2,
      cashout_fee: 3,
      phone: '',
      status: 'null',
    },

    btndisable: false,
  };

  async componentDidMount() {
    setTimeout(() => {
      this.redirected();
    }, 200);
    const { data } = this.state;
    var userDeatils = this.props.location?.state;
    data.phone = userDeatils?.phone;

    await this.setState({ userDeatils, data });
  }

  schema = {
    phone: Joi.string().required(),
    status: Joi.string().valid('accept', 'reject', 'null').required(),
    daily_limit: Joi.number().required(),
    monthly_limit: Joi.number().required(),
    yearly_limit: Joi.number().required(),

    cashindaily_limit: Joi.number().required(),
    cashinmonthly_limit: Joi.number().required(),
    cashinyearly_limit: Joi.number().required(),

    cashin_fee: Joi.number().max(90).required(),
    cashout_fee: Joi.number().max(90).required(),
  };

  redirected = () => {
    const { navigate } = this.props;
    if (!this.props.location.state) {
      navigate('/cropkycreq');
    }
  };

  doSubmit = async (status) => {
    const { navigate } = this.props;
    const { data } = this.state;
    await this.setState({ loader: !this.state.loader, btndisable: true });
    data.status = status;
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to Validate this Client.',
      buttons: [
        {
          label: 'Yes',

          onClick: async () => {
            try {
              const { success } = await backEndCallObj(
                '/admin/verify_reject_corporate',
                data
              );

              if (success) {
                toastfun(success, 'success');

                this.setState({ loader: !this.state.loader });
                navigate('/cropkycreq', { state: { intialload: false } });
              }
            } catch (ex) {
              if (ex.response && ex.response.status === 400) {
                toastfun(ex.response.data, 'error');
              }
            }

            this.setState({ loader: !this.state.loader, btndisable: false });
          },
        },
        {
          label: 'No',
          onClick: () =>
            this.setState({ loader: !this.state.loader, btndisable: false }),
        },
      ],
    });
  };

  render() {
    const { userDeatils } = this.state;
    const { data } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline dflex_center'>
                      <h3 className='card-title'>
                        <b>
                          {' '}
                          KYC Request Of:{' '}
                          <span className='userid_title'>
                            &nbsp;
                            {userDeatils?.userid}
                          </span>{' '}
                        </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div class='card bg-dark row'>
                              <div
                                class='card-body text-center col-lg-12 '
                                style={{
                                  fontSize: '1.2rem',
                                  display: 'flex',
                                }}
                              >
                                <span className='col-lg-7 dflex_start'>
                                  {' '}
                                  Account Status: &nbsp;{' '}
                                  {userDeatils?.user_type}
                                </span>

                                <div
                                  className='col-lg-6 row'
                                  style={{
                                    display: 'inline',
                                  }}
                                >
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn  btn-success mt-1 col-lg-2 m-1 '
                                    onClick={() =>
                                      this.handleSubmitToast('accept')
                                    }
                                  >
                                    Verify User
                                  </button>
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn btn-danger col-lg-2 m-1'
                                    onClick={() =>
                                      this.handleSubmitToast('reject')
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className='card-body datatable-scroll'>
                              <>
                                <div class='row'>
                                  <div className=' row col-12 dflex_center'>
                                    {userDeatils?.images &&
                                      Object.values(userDeatils?.images).map(
                                        (item) => {
                                          return (
                                            <>
                                              {item.substring(
                                                item.length - 3
                                              ) === 'pdf' ? (
                                                <div className='col-2 p-3 req_height  '>
                                                  {' '}
                                                  <a
                                                    className='btn btn-primary p-1 '
                                                    href={item}
                                                  >
                                                    Click Here to view PDF File
                                                  </a>
                                                </div>
                                              ) : (
                                                <>
                                                  <img
                                                    className='col-2 p-3 req_height'
                                                    src={item}
                                                    alt='No '
                                                  />

                                                  {/* <a className="btn btn-primary p-1" href={this.state.image1} target="_blank"> Click Here to view PDF File</a> */}
                                                </>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                                <div className='row'>
                                  {data &&
                                    Object.keys(data).map((key, index) => {
                                      return (
                                        <>
                                          <table class='table col-md-6'>
                                            <tr>
                                              <th
                                                scope='row'
                                                className='text-left '
                                              >
                                                <b>{key}</b>
                                              </th>
                                              <td className='text-right'>
                                                <input
                                                  type='text'
                                                  disabled={
                                                    key === 'phone' ||
                                                    key === 'status'
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={
                                                    this.handleChangeWithKey
                                                  }
                                                  name={key}
                                                  value={data[key]}
                                                />
                                              </td>
                                            </tr>
                                          </table>
                                        </>
                                      );
                                    })}
                                </div>
                              </>
                            </div>
                            <div className='row dflex_center'>
                              <div className='ml-4'>
                                <div>
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn  btn-success mt-1'
                                    onClick={() =>
                                      this.handleSubmitToast('accept')
                                    }
                                  >
                                    Verify User
                                  </button>
                                </div>
                              </div>
                              <div className='ml-4 mt-1'>
                                <div>
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn btn-danger'
                                    onClick={() =>
                                      this.handleSubmitToast('reject')
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getadminslist: state.getadminslist,
  };
};

export default connect(mapStateToProps)(pushRoute(CROPValidate));
