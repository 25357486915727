import http from './commonServices/httpService';
// import { apiUrl } from "../config.json";
import helpers from './commonServices/cryptos';

const apiUrl = process.env.REACT_APP_API_URL;
const apiEndpoint = apiUrl + '/nimv';

export async function getuserlist(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/userlist', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function getuserlimits(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/userlimits', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function getuserbal(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/balancelist', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function getuserbanks() {
  const { data } = await http.post(apiEndpoint + '/banklist');
  return helpers.decryptobj(data);
}
export async function getusercryptoadrs() {
  const { data } = await http.post(apiEndpoint + '/addresseslist');
  return helpers.decryptobj(data);
}

export async function userstatus(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiUrl + '/nimdfs/memberaction', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function usertxn(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/historybyuser', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function userbill(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/billbyuser', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function getUserDetails(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/userbyid', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function UserDelete(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiUrl + '/nimdfs/userdel', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function getusertxn(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/lasthistory', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function getUserTxnDetails(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/historybyuser', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}
export async function LimitUpdate(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiUrl + '/nimdfs/limiupuser', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export default {
  LimitUpdate,
  getUserTxnDetails,
  getusertxn,
  getuserlimits,
  getuserlist,
  getuserbal,
  getuserbanks,
  getusercryptoadrs,
  userstatus,
  usertxn,
  userbill,
  getUserDetails,
  UserDelete,
};
