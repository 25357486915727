import { GET_KYCREQLIST } from "../action/kycreqAction";

const kycreqreducer = (state = null, { type, payload }) => {
    switch (type) {
        case GET_KYCREQLIST:
            return payload;
        default:
            return state;
    }
};
export default kycreqreducer;
