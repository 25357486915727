import { backEndCall } from '../../services/MainService';
import store from '../store/index';

export var GET_ADMINCONTROLS = 'GET_ADMINCONTROLS';

async function get_admincontrols(data) {
  try {
    store.dispatch({ type: GET_ADMINCONTROLS, payload: data });
  } catch (ex) {
    console.warn(ex);
  }
}

export default get_admincontrols;
