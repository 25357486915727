import React, { Component } from 'react';
import { pushRoute } from '../../commons/pushRoute';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { backEndCallObj } from '../../services/MainService';
import get_userslist from '../../reduxStore/action/userlistAction';
import { connect } from 'react-redux';

class UserBalance extends Component {
  state = {
    kdata: [],
    ldata: [],
    stoploader: true,
    loadmore: false,
    skip: 0,
    loader: true,
  };
  componentDidMount = async () => {
    if (!this.props.getuserlist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  fetchData = async () => {
    this.setState({ loadmore: true });
    try {
      const obj = {
        skip: this.state.alldata.length,
      };

      const dd = await backEndCallObj('/adminget/getusers', obj);
      const data = dd.users;

      var newr = [...this.state.alldata, ...data];
      await get_userslist(newr);

      this.setState({ loadmore: false });
      if (data && data.length === 0) {
        this.setState({ loader: false });
      } else {
        this.intialload({ intial: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  intialload = async ({ intial }) => {
    try {
      this.setState({ loadmore: true });
      var obj = { skip: 0 };

      if (intial) {
        await this.setState({ limits: [] });
        const dd = await backEndCallObj('/adminget/getusers', obj);

        const data = dd.users;

        await get_userslist(data);
        this.setState({ loadmore: false });

        if (data && data.length === 0) {
          await this.setState({ loader: false });
        }
      }
      const userlimits = this.props.getuserlist;

      const data = userlimits?.map((item) => {
        return {
          ...item.balances,
          phone: item.phone,
          member_name: item.member_name,
          user_type: item.user_type,
        };
      });

      await this.setState({
        kdata: data,
        ldata: data,
        alldata: userlimits,
        loadmore: false,
      });
    } catch (error) {
      console.warn(error);
      await this.setState({ loader: false });
      toast.error('error');
    }
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ kdata: this.state.ldata });
    } else {
      if (this.state.kdata && this.state.kdata.length > 0) {
        const fd = Object.values(this.state.kdata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ kdata: fd });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b>All User Balances </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <th>SNO</th>
                                    <th>NAME</th>
                                    <th>PHONE</th>
                                    <th>USER TYPE</th>
                                    <th>PESO BALANCE</th>
                                    <th>BTC BALANCE</th>
                                    <th>ETH BALANCE</th>
                                    <th>USDT BALANCE</th>
                                  </thead>
                                  {this.state.kdata &&
                                  this.state.kdata.length > 0 ? (
                                    <tbody>
                                      {this.state.kdata.map((k, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{k.member_name}</td>

                                            <td>{k.phone}</td>
                                            <td>{k.user_type}</td>
                                            <td>{k.peso}</td>
                                            <td>{k.bitcoin}</td>
                                            <td>{k.ethereum}</td>
                                            <td>{k.usdt}</td>
                                          </tr>
                                        );
                                      })}
                                      {this.state.loader === true ? (
                                        <tr className=' m-auto py-5'>
                                          <th colSpan='8'>
                                            <div className='offset-md-5'>
                                              <button
                                                disabled={this.state.loadmore}
                                                onClick={this.fetchData}
                                                className='btn btn-warning'
                                              >
                                                Load more
                                              </button>
                                            </div>
                                          </th>
                                        </tr>
                                      ) : null}
                                    </tbody>
                                  ) : (
                                    <tr className=' m-auto py-5'>
                                      <th colSpan='8'>
                                        <div className=''>
                                          <BallTriangle
                                            className='text-center offset-md-5 py-5'
                                            // type="Bars"
                                            // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                            color='grey'
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </th>
                                    </tr>
                                  )}
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getuserlimits: state.getuserlimits,
    user: state.getcurrentuser,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserBalance));
