import React, { Component } from 'react';
import HomeHeader from '../../commons/Header';
import { connect } from 'react-redux';
import { pushRoute } from '../../commons/pushRoute';
import { paginate } from '../../commons/paginate';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import get_cryptoadrs from '../../reduxStore/action/cryptoadrsAction';
import { BallTriangle } from 'react-loader-spinner';
import Pagination from '../../commons/pagination';
import { backEndCallObj } from '../../services/MainService';
import get_userlimits from '../../reduxStore/action/userlimitsAction';
import get_userslist from '../../reduxStore/action/userlistAction';
import { toast } from 'react-toastify';
import CommonTable from '../../commons/commonTable';
import { toastfun } from '../../commons/toast';

class CryptoAddresses extends Component {
  state = {
    adrs: [],
    loader: true,
    active: 'active',
    dmov: [],
    currentPage: 1,
    pageSize: 100,
    stoploader: true,
  };

  componentDidMount = async () => {
    if (!this.props.getuserlist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  intialload = async ({ intial }) => {
    try {
      this.setState({ loadmore: true });
      var obj = { skip: 0 };

      if (intial) {
        await this.setState({ limits: [] });
        const dd = await backEndCallObj('/adminget/getusers', obj);

        const data = dd.users;

        await get_userslist(data);
        this.setState({ loadmore: false });

        if (data && data.length === 0) {
          await this.setState({ loader: false });
        }
      }
      const userlimits = [...this.props.getuserlist];

      const data = userlimits.map((item) => {
        return {
          ...item.cryptoaddress,
          phone: item.phone,
          member_name: item.member_name,
          user_type: item.user_type,
        };
      });

      await this.setState({
        adrs: data,
        dmov: data,
        alldata: userlimits,
        loadmore: false,
      });
    } catch (error) {
      console.warn(error);
      await this.setState({ loader: false });
      toast.error('error');
    }
  };

  refresh = () => {
    this.intialload({ intial: true });
  };

  fetchData = async () => {
    this.setState({ loadmore: true });

    try {
      await this.setState({ skip: this.state.alldata.length });

      const obj = {
        skip: parseInt(this.state.skip),
      };

      const dd = await backEndCallObj('/adminget/getusers', obj);
      const data = dd.users;

      this.setState({ loadmore: false });

      if (data && data.length === 0) {
        await this.setState({ loader: false });
      } else {
        let newr = [...this.state.user, ...data];

        const uniqueValues = new Set(newr.map((v) => v.name));

        if (uniqueValues.size < newr.length) {
        }

        await get_userslist(newr);

        await this.setState({
          user: this.props.getuserlist,
          alldata: this.props.getuserlist,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
    }
  };

  colums = [
    { path: 'phone', label: 'PHONE' },
    { path: 'member_name', label: 'NAME' },
    { path: 'user_type', label: 'USER TYPE' },

    { path: 'bitcoin', label: 'BIT COIN' },
    { path: 'ethereum', label: 'ETHEREUM' },
    { path: 'usdt', label: 'USDT' },
  ];

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ adrs: this.state.dmov });
    } else {
      if (this.state.adrs && this.state.adrs.length) {
        const fd = Object.values(this.state.adrs).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ adrs: fd, currentPage: 1 });
      }
    }
  };

  getPagedData = () => {
    const { pageSize, currentPage } = this.state;
    const movies = paginate(this.state.adrs, currentPage, pageSize);
    return { totalCount: this.state.adrs?.length, movies };
  };
  render() {
    const { pageSize, currentPage } = this.state;
    const { totalCount, movies } = this.getPagedData();
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> User Crypto Addresses </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                  {this.state.loadmore ? null : (
                                    <i
                                      className='fa fa-refresh ml-2'
                                      style={{ fontSize: 'xx-large' }}
                                      aria-hidden='true'
                                      onClick={this.refresh}
                                    ></i>
                                  )}
                                </div>
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <CommonTable
                                  colums={this.colums}
                                  data={movies}
                                  stoploader={this.state.stoploader}
                                  loader={this.state.loader}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
    getcryptoadrs: state.getcryptoadrs,
  };
};

export default connect(mapStateToProps)(pushRoute(CryptoAddresses));
