import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { pushRoute } from '../../commons/pushRoute';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { backEndCallObj } from '../../services/MainService';
import { connect } from 'react-redux';
import get_grplist from '../../reduxStore/action/groupListAction';
import { Modal } from 'react-responsive-modal';
import { toastfun } from '../../commons/toast';
import Form from '../../commons/form';
import Joi from 'joi-browser';

class SiteControlsAdvan extends Form {
  state = {
    kdata: [],
    data: {},

    groups: [],
    stoploader: true,
    loadmore: false,
    skip: 0,
    finalData: [],
    loader: true,
  };

  componentDidMount = async () => {
    if (!this.props.grouplist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  schema = {
    type: Joi.string().valid('flat', 'percentage').required(),

    fee: Joi.when('type', {
      is: 'percentage',
      then: Joi.number().max(90).required(),
      otherwise: Joi.number().required(),
    }),

    min: Joi.number().required(),

    max: Joi.number().greater(
      Joi.ref('min', {
        adjust: (value) => ~~value,
      })
    ),
  };

  doSubmit = async () => {
    try {
      await this.setState({ buttondisable: true });
      const { data, editGroup, alldata } = this.state;
      const group_id = alldata.filter(
        (item) => item.user_group === editGroup.user_group
      )[0].group_id;

      const obj2 = {
        channel_id: editGroup.channel_id,
        max: parseFloat(data.max),
        group_id: group_id,
        min: parseFloat(data.min),
        fee: data.fee,
        type: data.type,
      };

      const res = await backEndCallObj('/admin/edit_user_group', obj2);
      if (res) {
        await this.intialload({ intial: true });
        toastfun(res.success, 'success');
        this.setState({ popupOpen: false, buttondisable: false });

        this.handleTableData({ user_group: res.user_group });
      }
    } catch (error) {
      toastfun(error.response.data, 'error');
      await this.setState({ buttondisable: false });
      console.warn(error);
    }
  };

  deletePartner = async () => {
    try {
      await this.setState({ buttondisable: true });
      const { editGroup, alldata } = this.state;

      const group_id = alldata.filter(
        (item) => item.user_group === editGroup.user_group
      )[0].group_id;

      const obj = {
        channel_id: editGroup.channel_id,
        group_id: group_id,
      };

      const { success } = await backEndCallObj('/admin/delete_channel', obj);
      if (success) {
        this.intialload({ intial: true });
        toastfun(success, 'success');

        this.setState({ deleteOpen: false, buttondisable: false });
      }
    } catch (error) {
      toastfun(error.response.data, 'error');
      await this.setState({ deleteOpen: false, buttondisable: false });
      console.warn(error);
    }
  };

  popupOpen = async (item) => {
    const data = { ...this.state.data };
    data.type = item?.type;
    data.min = item?.min;
    data.max = item?.max;
    data.fee = item?.fee;

    await this.setState({ popupOpen: true, editGroup: item, data, errors: {} });
  };

  deletePopup = async (item) => {
    await this.setState({ deleteOpen: true, editGroup: item });
  };

  onCloseModal = () => {
    this.setState({
      popupOpen: false,
      deleteOpen: false,
      addNewGroup: false,
      groupDeletePopup: false,
    });
  };

  delGroupCall = async () => {
    try {
      await this.setState({ backEndBtn: true });

      const obj = {
        group_id: this.state.deleteGroupDetails.group_id,
      };

      const res = await backEndCallObj('/admin/delete_user_group', obj);

      if (res) {
        toastfun(res.success, 'success');
        await this.intialload({ intial: true });
        this.setState({ groupDeletePopup: false, backEndBtn: false });
      }
    } catch (error) {
      await this.setState({ backEndBtn: false });
      toastfun(error.response.data, 'error');
    }
  };

  deleteGroupPopup = async (item) => {
    await this.setState({ deleteGroupDetails: item, groupDeletePopup: true });
  };

  addNewGroup = async () => {
    const { newGroupName } = this.state;
    await this.setState({ backEndBtn: true });
    try {
      if (!newGroupName) {
        toastfun('Enter Group Name', 'warn');
        await this.setState({ backEndBtn: false });
        return;
      }
      const obj = {
        user_group: this.state.newGroupName,
      };

      const { success } = await backEndCallObj('/admin/user_group_add', obj);
      if (success) {
        await this.setState({ backEndBtn: false });

        this.intialload({ intial: true });
        toastfun(success, 'success');
        await this.setState({ addNewGroup: false });
      }
    } catch (error) {
      toastfun(error.response.data, 'error');
      await this.setState({ backEndBtn: false });
    }
  };
  intialload = async ({ intial }) => {
    try {
      await this.setState({ bknLoader: false });

      if (intial) {
        await get_grplist();
      }

      const gg = [...this.props.grouplist];

      const groups = gg.map((item) => {
        const group = { user_group: item.user_group, group_id: item.group_id };
        return group;
      });

      await this.setState({
        groups,
        alldata: gg,
      });

      this.handleTableData(groups[0]);
      await this.setState({ bknLoader: true });
    } catch (error) {
      console.warn(error);
      await this.setState({ bknLoader: false });
      toast.error('error');
    }
  };

  handleTableData = (groupDetails) => {
    const alldata = [...this.props.grouplist];

    const filtredData = alldata.find(
      (ele) => ele.user_group === groupDetails.user_group
    );
    const deleteGroupDetails = {
      user_group: filtredData?.user_group,
      group_id: filtredData?.group_id,
    };

    const kdata = filtredData.partners.map((item) => {
      delete item._id;
      item.user_group = groupDetails?.user_group;
      return item;
    });
    this.setState({ kdata, groupDetails: groupDetails, deleteGroupDetails });
  };

  GoToFormPage = async () => {
    const { navigate } = this.props;
    navigate('/addnewgroup', {
      state: { groupDetails: this.state.groupDetails },
    });
  };

  render() {
    const {
      groups,
      editGroup,
      data,
      errors,
      addNewGroup,
      backEndBtn,
      bknLoader,
      groupDeletePopup,
    } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b>
                          {' '}
                          User Group :- {
                            this.state?.groupDetails?.user_group
                          }{' '}
                        </b>
                      </h3>

                      <div className='pull-right no-gutters'>
                        <button
                          onClick={(e) => {
                            this.setState({ addNewGroup: true });
                          }}
                          type='button'
                          className='btn text-white theme-btn'
                          style={{
                            background:
                              'linear-gradient(to bottom, #4e4376, #2b5876)',
                            fontWeight: '600',
                            fontSize: '14px',
                          }}
                        >
                          Add Group
                        </button>
                      </div>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              {/* <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                              </div> */}

                              <div className='d-flex no-gutters'>
                                <div className='col d-flex m-2'>
                                  {groups.map((item) => {
                                    return (
                                      <>
                                        <button
                                          type='button'
                                          onClick={() => {
                                            this.handleTableData(item);
                                          }}
                                          className={
                                            this.state.groupDetails
                                              ?.user_group === item?.user_group
                                              ? 'btn text-white theme-btn ml-2 active_button'
                                              : 'btn  theme-btn ml-2'
                                          }
                                        >
                                          {item?.user_group}{' '}
                                        </button>{' '}
                                        <button
                                          onClick={() =>
                                            this.deleteGroupPopup(item)
                                          }
                                          className={
                                            this.state.groupDetails
                                              ?.user_group === item?.user_group
                                              ? 'btn text-white theme-btn active_button del_hov2 btn_del'
                                              : 'btn  theme-btn del_hov btn_del'
                                          }
                                        >
                                          <i
                                            class='fa fa-window-close'
                                            aria-hidden='true'
                                          ></i>
                                        </button>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <th>S NO</th>
                                    <th> GROUP NAME</th>
                                    <th>PARTNERS NAME</th>
                                    <th>CHANNEL NAME</th>
                                    <th>MINIMUM</th>
                                    <th>MAXIMUM</th>
                                    <th>FEE</th>
                                    <th>TYPE</th>
                                    <th>EDIT</th>
                                    <th>DELETE</th>
                                  </thead>

                                  {bknLoader ? (
                                    <>
                                      {this.state.kdata &&
                                      this.state.kdata.length > 0 ? (
                                        <tbody>
                                          {this.state.kdata.map((k, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{k.user_group}</td>

                                                <td
                                                  style={{
                                                    textTransform: 'uppercase',
                                                  }}
                                                >
                                                  {k.partner_name}
                                                </td>
                                                <td
                                                  style={{
                                                    textTransform: 'uppercase',
                                                  }}
                                                >
                                                  {k.channel}
                                                </td>
                                                <td>{k.min}</td>
                                                <td>{k.max}</td>
                                                <td>
                                                  {k.fee}{' '}
                                                  {k.type === 'percentage'
                                                    ? '%'
                                                    : '₱'}
                                                </td>
                                                <td>{k.type}</td>
                                                <td>
                                                  {' '}
                                                  <div
                                                    className=' text-center'
                                                    onClick={() =>
                                                      this.popupOpen(k)
                                                    }
                                                  >
                                                    <i className='fa fa-edit fa-2x'></i>
                                                  </div>
                                                </td>
                                                <td>
                                                  {' '}
                                                  <div
                                                    onClick={() =>
                                                      this.deletePopup(k)
                                                    }
                                                  >
                                                    <i
                                                      class='fa fa-trash fa-2x'
                                                      style={{ color: 'red' }}
                                                      aria-hidden='true'
                                                    ></i>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      ) : (
                                        <tr className=' m-auto py-5'>
                                          <th colSpan='8'>
                                            <div className=''>
                                              <h2> No Data Found</h2>
                                              {/* <BallTriangle
                                            className='text-center offset-md-5 py-5'
                                            // type="Bars"
                                            // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                            color='grey'
                                            height={80}
                                            width={80}
                                          /> */}
                                            </div>
                                          </th>
                                        </tr>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      <tr className=' m-auto py-5'>
                                        <th colSpan='8'>
                                          <div className=''>
                                            <BallTriangle
                                              className='text-center offset-md-7 py-5'
                                              // type="bars-loding"
                                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                              color='grey'
                                              height={80}
                                              width={80}
                                            />
                                          </div>
                                        </th>
                                      </tr>
                                    </>
                                  )}
                                </Table>
                                <div className='pull-right no-gutters m-2'>
                                  <button
                                    onClick={() => this.GoToFormPage()}
                                    type='button'
                                    className='btn text-white theme-btn ml-2 '
                                    style={{
                                      background:
                                        'linear-gradient(to bottom, #4e4376, #2b5876)',
                                      fontWeight: '600',
                                      fontSize: '14px',
                                    }}
                                  >
                                    Add Partner
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.popupOpen && (
          <Modal open={this.state.popupOpen} onClose={this.onCloseModal} center>
            <div className='popup-title'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1'>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Change Limits {editGroup.user_group} of{' '}
                    {editGroup.partner_name} of {editGroup.channel}
                  </h5>
                </div>
                <div className='grid_center'>
                  <>
                    <label className=''> Type</label>
                    <select
                      type='number'
                      value={data.type}
                      name='type'
                      className='text-center py-1 m-1 edit_chan_input'
                      onChange={this.handleChangeNoValidate}
                    >
                      <option value='' required>
                        --Select Type--
                      </option>
                      <option value='flat' required>
                        flat
                      </option>
                      <option value='percentage' required>
                        percentage
                      </option>
                    </select>
                    <small>{errors?.type}</small>
                  </>

                  <div className=''>
                    <label className=''> Fee</label> <br></br>
                    <input
                      name='fee'
                      type='number'
                      value={data.fee}
                      placeholder='Enter Fee'
                      className='text-center py-1 m-1 edit_chan_input'
                      onChange={this.handleNumberNoValidate}
                    />
                  </div>
                  <small>{errors?.fee}</small>

                  <div className=''>
                    <label className=''> Minimum</label> <br></br>
                    <input
                      name='min'
                      type='number'
                      value={data.min}
                      placeholder='Enter Minimum Amount'
                      className='text-center py-1 m-1 edit_chan_input'
                      onChange={this.handleNumberNoValidate}
                    />
                  </div>
                  <small>{errors?.min}</small>

                  <div className=''>
                    <label className=''> Maximum</label> <br></br>
                    <input
                      name='max'
                      type='number'
                      value={data.max}
                      placeholder='Enter Maximum Amount'
                      className='text-center py-1 m-1 edit_chan_input '
                      onChange={this.handleNumberNoValidate}
                    />
                    <br></br> <small>{errors?.max}</small>
                  </div>
                </div>

                <form>
                  <div className='mck-form-group'></div>
                  <div className='getstarted text-center py-2'>
                    <button
                      disabled={this.state.buttondisable}
                      onClick={this.handleSubmit}
                      type='button'
                      data-dismiss='modal'
                      className='btn btn-success '
                    >
                      Submit
                    </button>
                    <button
                      disabled={this.state.buttondisable}
                      type='button'
                      className='btn btn-danger ml-1'
                      onClick={this.onCloseModal}
                    >
                      No
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        )}

        {addNewGroup && (
          <Modal open={addNewGroup} onClose={this.onCloseModal} center>
            <div>
              <div className='register_top in'>
                <div className='signinsignup-widget'>
                  <div className='popup-title py-1'>
                    <h5
                      className='modal-title text-center'
                      id='exampleModalLabel'
                    >
                      Are You Sure? <br></br>You Want to Create Group{' '}
                    </h5>
                  </div>

                  <div className=' grid_center'>
                    <div className='col-4'>
                      <input
                        style={{
                          width: '10rem',
                          height: '80%',
                        }}
                        placeholder='Enter New Group'
                        className='text-center py-1 m-1 '
                        onChange={(e) => {
                          this.setState({ newGroupName: e.target.value });
                        }}
                      />
                    </div>
                  </div>

                  <div className='login-form'>
                    <div className='signin_username'>
                      <div className='signin-form'>
                        <form className='form-horizontal'>
                          <div className='mck-form-group'></div>
                          <div className='getstarted text-center py-2'>
                            <button
                              onClick={(val) => this.addNewGroup(val)}
                              disabled={backEndBtn}
                              type='button'
                              data-dismiss='modal'
                              className='btn btn-success m-1 '
                            >
                              Submit
                            </button>
                            <button
                              disabled={backEndBtn}
                              type='button'
                              className='btn btn-danger m-1'
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {this.state.deleteOpen && (
          <Modal
            open={this.state.deleteOpen}
            onClose={this.onCloseModal}
            center
          >
            <div className='popup-title'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1'>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Are You Sure Delete &nbsp;
                    {editGroup.partner_name} ?
                  </h5>
                </div>

                <div className='login-form'>
                  <div className='signin_username'>
                    <div className='signin-form'>
                      <form className='form-horizontal'>
                        <div className='mck-form-group'></div>
                        <div className='getstarted text-center py-2'>
                          <button
                            disabled={this.state.buttondisable}
                            onClick={this.deletePartner}
                            type='button'
                            data-dismiss='modal'
                            className='btn btn-success '
                          >
                            Submit
                          </button>
                          <button
                            disabled={this.state.buttondisable}
                            type='button'
                            className='btn btn-danger ml-1'
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {groupDeletePopup && (
          <Modal open={groupDeletePopup} onClose={this.onCloseModal} center>
            <div onClick={this.onOpenpopupModal}>
              <div className='register_top in'>
                <div className='signinsignup-widget'>
                  <div className='popup-title py-1'>
                    <h5
                      className='modal-title text-center'
                      id='exampleModalLabel'
                    >
                      Are You Sure? <br></br>You Want to Permanantly Delete{' '}
                      {this.state.deleteGroupDetails.user_group}
                    </h5>
                  </div>

                  <div className='login-form'>
                    <div className='signin_username'>
                      <div className='signin-form'>
                        <form className='form-horizontal'>
                          <div className='mck-form-group'></div>
                          <div className='getstarted text-center py-2'>
                            <button
                              onClick={() => this.delGroupCall()}
                              type='button'
                              data-dismiss='modal'
                              className='btn btn-success m-1'
                              disabled={this.state.backEndBtn}
                            >
                              Yes
                            </button>
                            <button
                              type='button'
                              className='btn btn-danger m-1'
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grouplist: state.grouplist,
    user: state.getcurrentuser,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(SiteControlsAdvan));
