import http from './commonServices/httpService';
// import { apiUrl } from "../config.json";
import helpers from './commonServices/cryptos';

const apiEndpoint = process.env.REACT_APP_API_URL + '/nimdfs';

export async function kycreq() {
  const { data } = await http.post(apiEndpoint + '/pendkyclist');
  return helpers.decryptobj(data);
}

export async function kycgetuploads(phone, number) {
  //   const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/getuploads', {
    phone,
    number,
  });
  return data;
}
export async function corporatekycup(phone) {
  var userphone = await helpers.encryptobj({ phone: phone });

  var returndata = await http.post(apiEndpoint + '/getcorpuploads', {
    enc: userphone,
  });
  return helpers.decrypt(returndata.data);
}
export async function succverifi(obj) {
  var userphone = await helpers.encryptobj(obj);
  var returndata = await http.post(apiEndpoint + '/succverifi', {
    enc: userphone,
  });
  return helpers.decrypt(returndata.data);
}
export async function verifireject(obj) {
  var userphone = await helpers.encryptobj(obj);
  var returndata = await http.post(apiEndpoint + '/rejeverifi', {
    enc: userphone,
  });
  return helpers.decrypt(returndata.data);
}
export async function corpverifi(phone) {
  var userphone = await helpers.encryptobj({ phone: phone });

  var returndata = await http.post(apiEndpoint + '/corprejverifi', {
    enc: userphone,
  });
  return helpers.decrypt(returndata.data);
}

export default {
  kycreq,
  kycgetuploads,
  corporatekycup,
  succverifi,
  verifireject,
  corpverifi,
};
