/* eslint-disable */

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { PieChart } from "react-minimal-pie-chart";

class Balances extends Component {
  state = { date: new Date() };
  onChange = (date) => this.setState({ date });
  render() {
    return (
      <React.Fragment>
        <div className="col-sm-12 col-xs-12">
          <div className="card text-center">
            <div className="pt-2 header-elements-inline border-btm">
              <ul className="nav  nav-tabs-bottom ">
                <li className="nav-item">
                  <a
                    href="#bottom-justified-tab11"
                    className="nav-link active show"
                    data-toggle="tab"
                  >
                    <h6>Peso Transactions </h6>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body pt-0">
              <div className="mb-1 ">
                <Calendar
                  onChange={this.onChange}
                  value={this.state.date}
                  className="calendar"
                />
              </div>
            </div>

            <div className=" pt-2header-elements-inline border-btm p-0">
              <ul className="nav  nav-tabs-bottom ">
                <li className="nav-item">
                  <a
                    href="#bottom-justified-tab11"
                    className="nav-link active show"
                    data-toggle="tab"
                  >
                    <h6> Pie Chart </h6>
                  </a>
                </li>
              </ul>
            </div>
            <PieChart
              animate={true}
              reveal="10%"
              paddingAngle={1}
              className="pie-chart pb-2"
              //  label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
              data={[
                { title: "One", value: 18, color: "#f9d035 " },
                { title: "Two", value: 20, color: "#23afff" },
                { title: "Four", value: 10, color: " #f3407d" },
              ]}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Balances;
