/* eslint-disable */
import React, { Component } from 'react';
import HomeHeader from '../../commons/Header';
import { pushRoute } from '../../commons/pushRoute';
import ProfitStats from './profitstats';
import Balances from './balances';
import MyForms from './myforms';
import Footer from '../../commons/Footer';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import auth from '../../services/authService';
import { dashboardValues } from '../../services/dashboardService';
// import { dailystats } from "../../services/historyService";
import get_dashboardvalues from '../../reduxStore/action/dashboardValuesaAction';
import Calendar from 'react-calendar';
import { PieChart } from 'react-minimal-pie-chart';

class Dashboard extends Component {
  state = {
    user: [],
    dailystats: [],
  };

  componentDidMount = async () => {
    if (!this.props.getdashboardvalueslist) {
      await get_dashboardvalues();
    }

    try {
      const res = this.props.getdashboardvalueslist;

      await this.setState({ dailystats: res });
    } catch (error) {
      //
    }
    // if (!this.props.dailystats) {
    //   await get_stats();
    // }
    var user = auth.getCurrentUser();
    // var stat = await this.props.dailystats;

    this.setState({ user });
  };

  render() {
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          user={this.state.user}
          //   ticker={this.props.data.ticker}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-xl-12'>
                  <ProfitStats
                    data={this.state.dailystats ? this.state.dailystats : 0}
                  />
                  <div className='row'>
                    <div className='col-md-12'>
                      <MyForms
                        data={this.state.dailystats ? this.state.dailystats : 0}
                      />
                    </div>

                    {/* <div className='col-xl-3'>
                      <div className='row'>
                        <Balances data={this.props.data} />
                      </div>
                    </div> */}
                    {/* <div className='col-xl-3'>
                      <div className='row'>
                        <div className='col-sm-12 col-xs-12'>
                          <div className='card text-center'>
                            <div className='pt-2 header-elements-inline border-btm'>
                              <ul className='nav  nav-tabs-bottom '>
                                <li className='nav-item'>
                                  <a
                                    href='#bottom-justified-tab11'
                                    className='nav-link active show'
                                    data-toggle='tab'
                                  >
                                    <h6>Crypto Holdings</h6>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className='card-body pt-0'>
                              <div className='mb-1 '>
                                <Calendar
                                  onChange={this.onChange}
                                  value={this.state.date}
                                  className='calendar'
                                />
                              </div>
                            </div>

                            <div className=' pt-2header-elements-inline border-btm p-0'>
                              <ul className='nav  nav-tabs-bottom '>
                                <li className='nav-item'>
                                  <a
                                    href='#bottom-justified-tab11'
                                    className='nav-link active show'
                                    data-toggle='tab'
                                  >
                                    <h6> Pie Chart </h6>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <PieChart
                              animate={true}
                              reveal='10%'
                              paddingAngle={1}
                              className='pie-chart pb-2'
                              //  label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
                              data={[
                                { title: 'One', value: 18, color: '#f9d035 ' },
                                { title: 'Two', value: 20, color: '#23afff' },
                                { title: 'Four', value: 10, color: ' #f3407d' },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getdashboardvalueslist: state.getdashboardvalueslist,
  };
};

export default connect(mapStateToProps)(pushRoute(Dashboard));
