import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_PENDCRYWIDR = 'GET_PENDCRYWIDR';

async function get_pendingcrypwtdr(data) {
  const dd = await backEndCall('/adminget/get_crypto_withdraw_list');
  try {
    store.dispatch({ type: GET_PENDCRYWIDR, payload: dd });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_pendingcrypwtdr;
