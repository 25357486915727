import { GET_RIDERS } from "../action/ridersAction";

const ridersReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_RIDERS:
      return payload;
    default:
      return state;
  }
};
export default ridersReducer;
