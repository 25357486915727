import store from "../store/index";
// import user from "../../services/userService";
import { toast } from "react-toastify";

export var GET_USERSLIST = "GET_USERSLIST";

async function get_userslist(data) {
  try {
    // const data = await user.getuserlist();
    store.dispatch({ type: GET_USERSLIST, payload: data });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_userslist;
