import { GET_ONLINEORDERS } from "../action/onlineordersAction";

const onlineeordereducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_ONLINEORDERS:
      return payload;
    default:
      return state;
  }
};
export default onlineeordereducer;
