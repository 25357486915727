import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushRoute } from './pushRoute';
import _ from 'lodash';
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Table } from 'tabler-react';

// import throttle from 'lodash.throttle';
import { Bars } from 'react-loader-spinner';

class CommonTable extends Component {
  state = {};

  componentDidMount() {
    //
  }

  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    if (column.path === 'phone') return '+' + item[column.path];
    return _.get(item, column.path);
  };

  render() {
    const { data, colums } = this.props || {};
    return (
      <>
        <Table responsive className='card-table table-vcenter text-nowrap'>
          <thead>
            <tr>
              {colums && colums.length > 0 ? (
                <>
                  <th>SNO</th>
                  {colums.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </>
              ) : null}
            </tr>
          </thead>
          {!this.props.bknLoader ? (
            <>
              {' '}
              {data && data.length > 0 ? (
                <>
                  {' '}
                  <tbody>
                    {data.map((item, rr) => (
                      <tr key={rr}>
                        <td key={rr}>{++rr}</td>

                        {colums.map((column, index) => (
                          <td
                            key={index}
                            className={
                              item[column.path] === 'Withdraw' ||
                              item[column.path] === 'BUY'
                                ? 'text-danger cap'
                                : item[column.path] === 'Deposit' ||
                                  item[column.path] === 'SELL'
                                ? 'text-success cap'
                                : 'cap'
                            }
                          >
                            {this.renderCell(item, column)}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {this.props.loadMoreButton === true ? (
                      <tr className=' m-auto py-5'>
                        <th colSpan='12'>
                          <div className='dflex_center'>
                            <button
                              disabled={this.props.loadmore}
                              onClick={this.props.fetchData}
                              className='btn btn-warning'
                            >
                              Load more
                            </button>
                          </div>
                        </th>
                      </tr>
                    ) : null}
                  </tbody>
                </>
              ) : (
                <>
                  <th colSpan='12'>
                    {' '}
                    <div className='text-center  offset-md-1 py-5'>
                      No Data Found
                    </div>
                  </th>
                </>
              )}{' '}
            </>
          ) : (
            <>
              <tr className=' m-auto py-5'>
                <th colSpan='12'>
                  {' '}
                  <div
                    className='bars'
                    style={{
                      marginLeft: '35rem',
                    }}
                  >
                    <Bars
                      className='text-center  offset-md-5 py-5'
                      color='grey'
                      height={80}
                      width={80}
                    />
                  </div>{' '}
                </th>
              </tr>
            </>
          )}
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(CommonTable));
