import React, { Component } from 'react';
import { Table } from 'tabler-react';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import { CSVLink } from 'react-csv';
import Footer from '../../commons/Footer';
import { btwdatesreports } from '../../services/adminService';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import { Bars } from 'react-loader-spinner';

import { toast } from 'react-toastify';
import { toastfun } from '../../commons/toast';

import Moment from 'react-moment';
import CommonTable from '../../commons/commonTable';
import _ from 'lodash';
import get_grplist from '../../reduxStore/action/groupListAction';
import { backEndCallObj } from '../../services/MainService';
import moment from 'moment';
import get_ProfitReportslists from '../../reduxStore/action/profitReportsAction';
import Form from '../../commons/form';
import Joi from 'joi-browser';

class ProfitReports extends Form {
  state = {
    repo: [],
    bknLoader: true,
    skip: 0,
    loadMoreButton: false,
    backEndBtn: false,
    startDate: '',
    EndDate: '',
    gname: 'UG-Basic',
    earnings: [],
    dbtn: false,
    loader: true,
    groups: [],
  };
  componentDidMount = async () => {
    if (!this.props.grouplist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  schema = {
    start_date: Joi.required().label('Start Date '),
    end_date: Joi.required().label('End Date '),
  };

  headers = [
    { label: 'Date', key: 'date' },
    { label: 'USER GROUP', key: 'user_group' },
    { label: 'PESO', key: 'peso' },
    { label: 'BTC', key: 'btc' },
    { label: 'USDT', key: 'usdt' },
  ];

  intialload = async ({ intial }) => {
    try {
      await this.setState({ bknLoader: true });

      if (intial) {
        await get_grplist();
      }

      const gg = this.props.grouplist;

      const groups = gg.map((item) => {
        var data = { user_group: item.user_group, group_id: item.group_id };
        return data;
      });

      await this.setState({ bknLoader: false });
      await this.setState({
        groups,
      });
      await this.handleTableData(groups[0], intial);
    } catch (error) {
      await this.setState({ bknLoader: false });
    }
  };

  handleChangeDate = async (fdate) => {
    var d = fdate;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();
    const dd = year + '-' + month + '-' + day;
    await this.setState({
      startDate: dd,
    });
  };

  handleChangeToDate = async (tdate) => {
    var d = tdate;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();
    const dd = year + '-' + month + '-' + day;
    await this.setState({
      EndDate: dd,
    });
  };

  doSubmit = async () => {
    const { data, groupselected } = this.state;
    this.setState({ dbtn: true });
    try {
      const obj = {
        from_date: data.start_date,
        to_date: data.end_date,
        group_id: groupselected.group_id,
      };
      const res = await backEndCallObj(
        '/adminget/get_group_profits_by_dates',
        obj
      );
      if (res) {
        this.setState({
          dbtn: false,
          groups_profit: res.success,
        });
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status === 400
      ) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ dbtn: false });
      }
    }
  };
  handleSearch = async (evt) => {
    if (!evt.target.value) {
      const reports = await this.props.fullstats;
      this.setState({ repo: reports.sta });
    } else {
      if (this.state.repo && this.state.repo.length > 0) {
        const fd = Object.values(this.state.repo).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          } else {
            let mdate = moment(val.date).format('D MMM YYYY hh:mm A');
            if (
              mdate
                .toLowerCase()
                .includes(evt.target.value.trim().toString().toLowerCase())
            ) {
              return val;
            }
          }
        });
        await this.setState({ repo: fd, currentPage: 1 });
      }
    }
  };

  totalValues = (objName) => {
    const rr = [...this.state.groups_profit];
    var tpd = rr
      .map((row) => row[objName])
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    if (tpd) return tpd.toFixed(8);
    else return 0;
  };

  refresh = () => {
    this.intialload({ intial: true });
  };

  handleTableData = async (item, intial) => {
    await this.setState({ bknLoader: false });

    var gname = item.user_group;
    await this.setState({ gname: gname, groupselected: item });

    try {
      var obj = {
        group_id: item.group_id,
      };
      if (intial) {
        const groups_profit = { ...this.props.profitreportslist };
        const dd = await backEndCallObj('/adminget/get_group_profits', obj);
        if (dd && dd.success) {
          groups_profit[item.user_group] = dd.success;
          await get_ProfitReportslists(groups_profit);
        }
      }

      const groups_profit = { ...this.props.profitreportslist };
      if (!groups_profit[item.user_group]) {
        const dd = await backEndCallObj('/adminget/get_group_profits', obj);
        if (dd && dd.success) {
          groups_profit[item.user_group] = dd.success;
          await get_ProfitReportslists(groups_profit);
        }
      }
      const dd = groups_profit[item.user_group];

      await this.setState({ groups_profit: dd });
      await this.setState({ bknLoader: false });
    } catch (ex) {
      await this.setState({ bknLoader: false });
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
    }
  };

  render() {
    const { groups_profit, groups, data, errors } = this.state;
    const det = moment();
    var start = '';
    var end = '';
    if (data?.start_date) {
      start = new Date(data?.start_date);
    }
    if (data?.end_date) {
      end = new Date(data?.end_date);
    }

    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b>
                          Groups Profit Reports -{' '}
                          <span className='text-danger'>
                            {' '}
                            {this.state.gname}
                          </span>{' '}
                        </b>
                      </h3>
                      <div className='pull-right no-gutters dflex_center'>
                        <CSVLink
                          headers={this.headers}
                          data={groups_profit ? groups_profit : []}
                          filename={'profitreports.csv'}
                        >
                          <button
                            type='button'
                            class='btn btn-sm theme-btn  btn-success text-center'
                            style={{
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          >
                            <i
                              class='fa fa-download mr-1'
                              aria-hidden='true'
                            ></i>
                            Download as Excel
                          </button>
                        </CSVLink>
                        {this.state.bknLoader ? null : (
                          <i
                            className='fa fa-refresh ml-3 text-danger'
                            style={{ fontSize: '2rem' }}
                            aria-hidden='true'
                            onClick={this.refresh}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className='d-flex no-gutters'>
                      <div className='col d-flex m-2'>
                        {groups.map((item) => {
                          return (
                            <>
                              <button
                                type='button'
                                onClick={() => {
                                  this.handleTableData(item, false);
                                }}
                                className={
                                  this.state.groupDetails?.user_group ===
                                  item?.user_group
                                    ? 'btn text-white theme-btn ml-2 active_button'
                                    : 'btn  theme-btn ml-2'
                                }
                              >
                                {item?.user_group}
                              </button>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          class='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            class='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div
                                  className='col d-flex'
                                  style={{ height: 'fit-content' }}
                                >
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-40'
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                                <div>
                                  <DatePicker
                                    className='mt-1 mx-1 border py-1 text-center'
                                    value={data?.start_date}
                                    name='start_date'
                                    placeholderText='From Date'
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={det._d}
                                    selected={start}
                                    onChange={(val) =>
                                      this.handleDate(val, 'start_date')
                                    }
                                  />
                                  <small>{errors?.start_date}</small>
                                </div>
                                <div className='mx-1 '>
                                  <DatePicker
                                    name='end_date'
                                    className='mt-1 mx-1 border py-1 text-center'
                                    value={data?.end_date}
                                    placeholderText='To Date'
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={det._d}
                                    selected={end}
                                    onChange={(val) =>
                                      this.handleDate(val, 'end_date')
                                    }
                                  />
                                  <small>{errors?.end_date}</small>
                                </div>
                                <div className='mx-1'>
                                  <button
                                    disabled={this.state.dbtn}
                                    onClick={this.handleSubmit}
                                    type='button'
                                    className='btn bg-warning mt-1 py-1 border'
                                  >
                                    Get Reports by Date
                                  </button>
                                </div>
                              </div>

                              <div className='mt-2 mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <tr>
                                      <th className='upp'>DATE</th>
                                      <th className='upp table_border'>
                                        GROUP NAME
                                      </th>
                                      <th className='upp table_border'>PESO</th>
                                      <th className='upp table_border'>BTC</th>
                                      <th className='upp table_border'>USDT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groups_profit && groups_profit.length ? (
                                      <>
                                        {' '}
                                        {groups_profit.map((i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <Moment
                                                  format='DD-MM-YYYY'
                                                  fromNow
                                                >
                                                  {i.date}
                                                </Moment>
                                              </td>

                                              <td>{i.user_group}</td>
                                              <td>{i.peso ? i.peso : 0}</td>
                                              <td>
                                                {i.bitcoin ? i.bitcoin : 0}
                                              </td>
                                              <td>{i.usdt ? i.usdt : 0}</td>
                                            </tr>
                                          );
                                        })}
                                        <tr className='bg-sums f-22'>
                                          <td>
                                            <span className=''> TOTAL</span>
                                          </td>
                                          <td>
                                            <span className=''>
                                              {' '}
                                              {this.state.gname}
                                            </span>
                                          </td>
                                          <td>
                                            <span>
                                              {this.totalValues('peso')}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues('bitcoin')}
                                            </span>
                                          </td>

                                          <td>
                                            <span>
                                              {this.totalValues('usdt')}
                                            </span>
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <>
                                        {' '}
                                        {this.state.backEndBtn ? (
                                          <>
                                            {' '}
                                            <tr className=' m-auto py-5'>
                                              <th colSpan='8'>
                                                {' '}
                                                <div
                                                  className='bars'
                                                  style={{
                                                    marginLeft: '35rem',
                                                  }}
                                                >
                                                  <Bars
                                                    className='text-center  offset-md-5 py-5'
                                                    color='grey'
                                                    height={80}
                                                    width={80}
                                                  />
                                                </div>{' '}
                                              </th>
                                            </tr>
                                          </>
                                        ) : (
                                          <>
                                            <tr className='col-md-12 m-auto py-5'>
                                              <td colSpan='8'>
                                                <h2>No Reports Found</h2>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reportslist: state.reportslist,
    profitreportslist: state.profitreportslist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(ProfitReports));
