import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import Form from "../../commons/form";
import { pushRoute } from "../../commons/pushRoute";
import ReactCountdownClock from "react-countdown-clock";
import OtpInput from "react-otp-input";
import Joi from "joi-browser";
import ReCAPTCHA from "react-google-recaptcha";
import auth, { validateOTP, resendotp } from "../../services/authService";
import { toastfun } from "../../commons/toast";
import PhoneInput from "react-phone-input-2";

var recaptchaRef = React.createRef();
var capt = process.env.REACT_APP_CAPTCHA;

class Login extends Form {
  state = {
    success: "",
    error: "",
    resendbtn: false,
    twofa: 0,
    data: {
      phone: "",
      password: "",
      captcha: "",
    },
    otpvalue: null,
    errors: {},
    captcha: "",
    btnTxt: "Sign In",
    disabled: false,
    btnClas: "btn btn-primary button-long teal-bg mr-4",
    otp: false,
    resend: false,
    otpbutton: false,
    otpnumber: "",
    pwd: false,
    otpobj: {},
  };
  schema = {
    phone: Joi.string().min(12).max(12).required().label("Phone"),
    password: Joi.string()
      .min(8)
      .max(15)
      .required()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
      .options({
        language: {
          string: {
            regex: {
              base: "Must contain at least 1 lowercase,1 uppercase alphabetical,1 numeric ",
              test: "another description",
            },
          },
        },
      })
      .label(" Password"),
    captcha: Joi.string().required().label("Captcha "),
  };

  componentDidMount() {}

  doSubmit = async () => {
    // if (this.state.captcha.length) {

    this.setState({
      btnTxt: "Please Wait....",
    });

    try {
      let values = { ...this.state.data };
      var obj = {
        phone: values.phone,
        password: values.password,
        captcha: "abc",
        // values.captcha,
      };

      var data = await auth.login(obj);

      if (data) {
        toastfun("success", "success");
        this.setState({
          otp: true,
          btnClas: "btn btn-primary button-long teal-bg mr-4",
        });
      }
    } catch (ex) {
      const data = { ...this.state.data };
      data.captcha = "";
      setTimeout(() => {
        recaptchaRef.current.reset();
      }, 2000);

      this.setState({
        data,
        btnTxt: "Sign In",
      });

      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
        this.setState({ error: ex.response.data });
      }
    }
  };

  nospaces = (e) => {
    var k = e ? e.which : window.e.keyCode;
    if (k == 32) {
      let { data } = { ...this.state };
      data["email"] = e.target.value.replace(/\s+/g, "");
      this.setState({ data });
    }
  };
  handleotpChange = async (input) => {
    var otpvalue = input;
    await this.setState({ otpvalue, butdisabled: false });
  };

  onChange = async (value) => {
    var data = { ...this.state.data };
    data.captcha = value;
    this.setState({ data });

    const errors = { ...this.state.errors };
    if (errors) {
      delete errors.captcha;
      this.setState({ errors });
    }
  };

  renderer = async () => {
    await this.setState({ resend: true, btndis: true });
  };

  doResend = async () => {
    this.setState({ resend: true, resendbtn: true });
    try {
      const obj = {
        phone: this.state.data.phone,
      };

      const res = await resendotp(obj);

      if (res) {
        await this.setState({
          resendbtn: false,
          resend: false,
        });

        toastfun(res.success, "success");
      }
    } catch (ex) {
      this.setState({ resend: false, resendbtn: false });
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }
    }
  };

  otpValidate = async () => {
    const { navigate } = this.props;
    await this.setState({ otpbutton: true });

    try {
      if (this.state.otpvalue === null || this.state.otpvalue.length < 6) {
        await this.setState({ otpbutton: false });
        toastfun("Please Enter Valid OTP", "error");
        return;
      }
      const obj = {
        phone: this.state.data.phone,
        otp: this.state.otpvalue,
      };

      const data = await validateOTP(obj);

      if (data) {
        navigate("/dashboard");
      }
    } catch (ex) {
      await this.setState({ otpbutton: false });
      if (ex.response && ex.response.status === 400) {
        await this.setState({ otpbutton: false });
        toastfun(ex.response.data, "error");
      }
    }
  };

  pwdfun = () => {
    this.setState({ pwd: !this.state.pwd });
  };

  render() {
    var errors = this.state.errors;
    if (auth.getCurrentUser())
      return (
        <Navigate to="/dashboard" />
        // <Redirect
        //   to={{
        //     pathname: "/dashboard",
        //     state: { from: this.props.location },
        //   }}
        // />
      );
    return (
      <React.Fragment>
        <div className="page-content bg-2" style={{ display: "flex" }}>
          {!this.state.otp ? (
            <div className="login-c ">
              <div className="login-form">
                <div className="mb-0">
                  <div className="">
                    <div className="login_icon_div">
                      <img
                        src="/demo/brand/logo.png"
                        className="login_icon"
                        alt="logo"
                      />
                      {/* <i className="icon-user-lock mr-3 icon-2x" /> */}
                    </div>
                    <div className="text-center mb-3">
                      <h3 className="mb-0 mt-2 title"> Login </h3>
                    </div>
                    {/* {this.state.success && (
                      <div class='alert alert-success'>
                        {this.state.success}
                      </div>
                    )}
                    {this.state.error && (
                      <div className='alert alert-danger'>
                        {this.state.error}
                      </div>
                    )} */}
                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <PhoneInput
                        country={"ph"}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        onChange={(phone) => this.handleDate(phone, "phone")}
                      />

                      {errors.phone && (
                        <div className="bg-red">
                          <i className="fa fa-times" /> {errors?.phone}
                        </div>
                      )}
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        type={this.state.pwd ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Admin's Password"
                        name="password"
                        onChange={this.handleChange}
                      />
                      <div className="form-control-feedback">
                        <i
                          style={{ fontSize: "1.2rem" }}
                          className="fa fa-lock"
                          aria-hidden="true"
                        ></i>
                      </div>

                      <i
                        className={
                          this.state.pwd
                            ? "fa fa-eye password_eye_icon"
                            : "fa fa-eye-slash password_eye_icon"
                        }
                        onClick={this.pwdfun}
                      ></i>

                      {errors.password && (
                        <div className="bg-red">
                          <i className="fa fa-times" /> {errors.password}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={capt}
                        onChange={this.onChange}
                      />
                      {errors.captcha && (
                        <div className="bg-red">
                          <i className="fa fa-times" /> {errors.captcha}
                        </div>
                      )}
                    </div>

                    <div className="form-group buttons text-center ml-4">
                      <button
                        onClick={this.handleSubmit}
                        style={{ background: "#201865" }}
                        type="submit"
                        disabled={this.validate()}
                        className={
                          this.validate()
                            ? "btn btn-primary button-long teal-bg btn-disable"
                            : " btn btn-primary button-long teal-bg mr-4"
                        }
                      >
                        {this.state.btnTxt}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="login-c login-c_opt_height">
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="mb-0">
                    <div className="">
                      <div className="login_icon_div">
                        <img
                          src="/demo/brand/logo.png"
                          className="login_icon"
                          alt="top"
                        />
                        {/* <i className="icon-user-lock mr-3 icon-2x" /> */}
                      </div>
                      <div className="text-center mb-3">
                        <h3 className="mb-0 mt-2 title"> OTP Verification </h3>
                      </div>

                      <div className="form-group form-group-feedback form-group-feedback-left">
                        <OtpInput
                          containerStyle="opt_input"
                          inputStyle="opt_input_box"
                          value={this.state.otpvalue}
                          onChange={this.handleotpChange}
                          numInputs={6}
                          isInputNum
                          separator={<span> &nbsp;</span>}
                        />

                        {/* <div className="form-control-feedback" style={{left:'-2px'}}>
                        <i className="icon-mobile text-muted" />
                      </div> */}

                        {this.state.resend ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "1rem",
                              }}
                            >
                              <span className="" style={{ fontSize: "0.8rem" }}>
                                <div
                                  class="alert alert-warning custom_alert"
                                  role="alert"
                                >
                                  OTP Expired,Request new one
                                </div>
                                {/* OTP Expired,Request new one{' '} */}
                              </span>
                            </div>{" "}
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "1rem",
                            }}
                          >
                            <span
                              className="mt-1 mr-2"
                              style={{ fontSize: "1rem" }}
                            >
                              This OTP will expire in
                            </span>
                            <ReactCountdownClock
                              seconds={120}
                              color="white"
                              weight={3}
                              alpha={0.9}
                              size={35}
                              fontSize="11px"
                              onComplete={() => this.renderer()}
                            />
                            <span
                              className="mt-1 ml-2"
                              style={{ fontSize: "1rem" }}
                            >
                              Seconds.
                            </span>

                            {/* <OtpTimer/> */}
                          </div>
                        )}
                      </div>

                      {this.state.resend ? (
                        <>
                          <div className="form-group buttons text-center ml-4">
                            <button
                              style={{ background: "#201865", color: "white" }}
                              type="submit"
                              disabled={this.state.resendbtn}
                              onClick={this.doResend}
                              className="btn btn-primary button-long teal-bg mr-4"
                              // className={this.state.btnClas}
                            >
                              Resend
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group buttons text-center ml-4">
                            <button
                              style={{ background: "#201865", color: "white" }}
                              type="submit"
                              disabled={this.state.otpbutton}
                              onClick={this.otpValidate}
                              className="btn btn-primary button-long teal-bg mr-4"
                              // className={this.state.btnClas}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(pushRoute(Login));
