import { GET_USERLIMITS } from "../action/userlimitsAction";

const userlimitsreducer = (state = null, { type, payload }) => {
    switch (type) {
        case GET_USERLIMITS:
            return payload;
        default:
            return state;
    }
};
export default userlimitsreducer;
