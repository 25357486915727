/* eslint-disable */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
const Four04 = () => {
  return (
    <React.Fragment>
      <div className="page-content bg2 pb ">
        <div className="content-wrapper">
          <div className="content justify-content-center align-items-center error">
            <div className="blink">
              <h1>
                <span>
                  <b> 404 </b>
                </span>
              </h1>
            </div>
            <h3>
              <b> THE PAGE WAS NOT FOUND </b>
            </h3>
            <div className="text-center ">
              <NavLink to="/dashboard">
                <button type="button" className="btn btn-orange rounded-round">
                  Back to HOME <i className="fa fa-angle-right ml-1" />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Four04;
