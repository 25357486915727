import store from "../store/index";

import rider from "../../services/marketplace/riderService";
import { toast } from "react-toastify";

export var GET_RIDERS = "GET_RIDERS";

async function get_Riderslists() {
  try {
    const data = await rider.getriders();
    store.dispatch({ type: GET_RIDERS, payload: data });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_Riderslists;
