import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';
import HomeHeader from '../../commons/Header';
import { Table } from 'tabler-react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { BallTriangle } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import get_userlimits from '../../reduxStore/action/userlimitsAction';
import user from '../../services/userService';
import { backEndCallObj } from '../../services/MainService';
import get_userslist from '../../reduxStore/action/userlistAction';
import CommonTable from '../../commons/commonTable';
import { toastfun } from '../../commons/toast';
class UserLimits extends Component {
  state = {
    limits: [],
    dmove: [],
    stoploader: true,
    stoploader: true,
    loadmore: false,
    skip: 0,
    loader: true,

    lim: '',
  };
  componentDidMount = async () => {
    if (!this.props.getuserlist) {
      this.intialload({ intial: true });
    } else {
      if (this.props.location.state?.intialload === false) {
        await this.intialload({ intial: true });
        return;
      }
      this.intialload({ intial: false });
    }
  };

  intialload = async ({ intial }) => {
    try {
      this.setState({ loadmore: true });
      var obj = { skip: 0 };

      if (intial) {
        await this.setState({ limits: [] });
        const dd = await backEndCallObj('/adminget/getusers', obj);

        const data = dd.users;

        await get_userslist(data);
        this.setState({ loadmore: false, stoploader: true });

        if (data && data.length === 0) {
          await this.setState({ loader: false });
        }
      }
      const userlimits = [...this.props.getuserlist];

      const data = userlimits.map((item) => {
        return {
          ...item.user_limits,
          ...item.fee,
          phone: item.phone,
          member_name: item.member_name,
          user_type: item.user_type,
        };
      });

      await this.setState({
        limits: data,
        dmov: data,
        alldata: userlimits,
        loadmore: false,
      });
    } catch (error) {
      console.warn(error);
      await this.setState({ loader: false });
      toast.error('error');
    }
  };

  limEdit = async (k) => {
    const { navigate } = this.props;

    navigate('/limitedit', { state: k });

    // this.props.history.push({
    //   pathname: "/limitedit",
    //   state: k,
    //   funcc: this.onCloseModal(),
    // });
  };

  fetchData = async () => {
    this.setState({ loadmore: true });

    try {
      await this.setState({ skip: this.state.alldata.length });

      const obj = {
        skip: parseInt(this.state.skip),
      };

      const dd = await backEndCallObj('/adminget/getusers', obj);
      const data = dd.users;

      this.setState({ loadmore: false });

      if (data && data.length === 0) {
        await this.setState({ loader: false });
      } else {
        let newr = [...this.state.user, ...data];

        const uniqueValues = new Set(newr.map((v) => v.name));

        if (uniqueValues.size < newr.length) {
        }

        await get_userslist(newr);

        await this.setState({
          user: this.props.getuserlist,
          alldata: this.props.getuserlist,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
    }
  };

  refresh = () => {
    this.intialload({ intial: true });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ limits: this.state.dmov });
    } else {
      if (this.state.limits && this.state.limits.length) {
        const fd = Object.values(this.state.limits).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });
        await this.setState({ limits: fd, currentPage: 1 });
      }
    }
  };

  colums = [
    { path: 'phone', label: 'PHONE' },
    { path: 'member_name', label: 'NAME' },
    { path: 'user_type', label: 'User Type' },

    { path: 'daily_limit', label: 'CASH OUT DAILY LIMIT' },
    { path: 'daily_used', label: 'CASH OUT DAILY UTILIZED' },
    { path: 'monthly_limit', label: 'CASH OUT MONTHLY LIMIT' },
    { path: 'monthly_used', label: 'CASH OUT MONTHLY UTILIZED' },
    { path: 'yearly_limit', label: 'CASH OUT YEARLY LIMIT' },
    { path: 'yearly_used', label: 'CASH OUT YEARLY UTILIZED' },

    { path: 'cashindaily_limit', label: 'CASH IN DAILY LIMIT' },
    { path: 'cashindaily_used', label: 'CASH IN DAILY UTILIZED' },
    { path: 'cashinmonthly_limit', label: 'CASH IN MONTHLY LIMIT' },
    { path: 'cashinmonthly_used', label: 'CASH IN MONTHLY UTILIZED' },
    { path: 'cashinyearly_limit', label: 'CASH IN YEARLY LIMIT' },
    { path: 'cashinyearly_used', label: 'CASH IN YEARLY UTILIZED' },

    { path: 'cashin_fee', label: 'CASH IN FEE' },
    { path: 'cashout_fee', label: 'CASH OUT FEE' },

    {
      label: 'ACTION',
      content: (item) => (
        <button
          className='btn btn-sm bg-primary'
          onClick={() => this.limEdit(item)}
        >
          Edit
        </button>
      ),
    },
  ];

  render() {
    const { limits } = this.state;
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> User Transfer Limits </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                  {this.state.loadmore ? null : (
                                    <i
                                      className='fa fa-refresh ml-2'
                                      style={{ fontSize: 'xx-large' }}
                                      aria-hidden='true'
                                      onClick={this.refresh}
                                    ></i>
                                  )}
                                </div>
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <CommonTable
                                  colums={this.colums}
                                  data={limits}
                                  stoploader={this.state.stoploader}
                                  loader={this.state.loader}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getuserlimits: state.getuserlimits,
    user: state.getcurrentuser,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserLimits));
