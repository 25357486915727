import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import { pushRoute } from "../../commons/pushRoute";
import { connect } from "react-redux";
import get_coinlist from "../../reduxStore/action/coinslistAction";
import { backEndCall } from "../../services/MainService";

class ProfitStats extends Component {
  state = {
    coins: [],
  };
  async componentDidMount() {
    // if (!this.props.coinslist) {
    //   const data = await backEndCall('/adminget/get_coins');
    //   await get_coinlist(data.coins);
    //   const coins = this.props.coinslist;
    //   await this.setState({ coins });
    // }
  }
  render() {
    const { coins } = this.state;
    const st = this.props?.data;
    const { navigate } = this.props;
    return (
      <React.Fragment>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-sm-2">
            <div className="card pro_card_rmbg">
              <div
                className="Dashboard_card"
                style={{
                  backgroundImage: "linear-gradient(#ffd0d0, #ffbdbd)",
                }}
              >
                <img
                  src={"https://img.topwallet.ph/crypto_logos/peso.png"}
                  className="m-1"
                  alt="peso"
                  width="43"
                  style={{ float: "right" }}
                />
                <p
                  className="card-text text-center"
                  style={{ fontSize: "19px", marginLeft: "1rem" }}
                >
                  Total Peso
                </p>
                <div
                  className=" bg-c-yellow"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <p
                    className="text-center"
                    style={{
                      fontSize: "1.2rem",
                      marginLeft: "-1rem",
                      color: "black",
                    }}
                  >
                    {st?.Total_peso ? st.Total_peso.toFixed(6) : "0.00"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-2">
            <div className="card pro_card_rmbg">
              <div
                className="Dashboard_card"
                style={{
                  backgroundImage: "linear-gradient(#d4a7bb, #9584b7)",
                }}
              >
                <img
                  src={"https://img.topwallet.ph/crypto_logos/bitcoin.png"}
                  className="m-1"
                  width="43"
                  alt="btc"
                  style={{ float: "right" }}
                />
                <p
                  className="card-text mb-0 text-center"
                  style={{ fontSize: "19px", marginLeft: "2rem" }}
                >
                  Total BTC
                </p>

                <div
                  className=" bg-c-yellow"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <p
                    className="text-center"
                    style={{
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                  >
                    {st?.Total_bitcoin ? st.Total_bitcoin.toFixed(8) : "0.00"}

                    {/* {st?.Total_bitcoin || "0.0"} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="card pro_card_rmbg">
              <div
                className="Dashboard_card"
                style={{
                  background: "#f48a0a66",
                }}
              >
                <img
                  src={"https://img.topwallet.ph/crypto_logos/usdc.png"}
                  className="m-1"
                  width="43"
                  alt="eth"
                  style={{ float: "right" }}
                />
                <p
                  className="card-text mb-0 text-center"
                  style={{ fontSize: "19px", marginLeft: "2rem" }}
                >
                  Total USDC
                </p>

                <div
                  className=" bg-c-yellow"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <p
                    className="text-center"
                    style={{
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                  >
                    {st?.Total_ethereum ? st.Total_ethereum.toFixed(8) : "0.00"}

                    {/* {st?.Total_ethereum || "0.0"} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="card pro_card_rmbg">
              <div
                className="Dashboard_card"
                style={{
                  backgroundImage: "linear-gradient(#a6f3b9, #92dc9e)",
                }}
              >
                <img
                  src={"https://img.topwallet.ph/crypto_logos/usdt.png"}
                  className="m-1"
                  width="50"
                  alt="usdt"
                  style={{ float: "right" }}
                />
                <p
                  className="card-text text-center"
                  style={{ fontSize: "19px" }}
                >
                  Total USDT
                </p>

                <div
                  className="bg-c-yellow"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <p
                    className="text-center"
                    style={{
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                  >
                    {st?.Total_usdt ? st.Total_usdt.toFixed(8) : "0.00"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-sm-2 cursor_pointer"
            onClick={() => navigate("/userslist")}
          >
            <div className="card pro_card_rmbg">
              <div className="card-body1">
                <p className="card-text mb-0 text-center">Total Users</p>
                <p
                  className="text-center ml-4"
                  style={{ fontSize: "1.2rem", marginRight: "2rem" }}
                >
                  {st?.Total_users_count || "0"}{" "}
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-sm-2 cursor_pointer"
            onClick={() => navigate("/traderkycreq")}
          >
            <div className="card pro_card_rmbg">
              <div className="card-body1">
                <p className="card-text mb-0 text-center">KYC Trader Pending</p>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                  {st?.Trader_kyc_pending_count || 0}
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-sm-2 cursor_pointer"
            onClick={() => navigate("/cropkycreq")}
          >
            <div className="card pro_card_rmbg">
              <div className="card-body1">
                <p className="card-text mb-0 text-center">
                  KYC Corporate Pending
                </p>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                  {st?.Corporate_kyc_pending_count || 0}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-sm-2 cursor_pointer"
            onClick={() => navigate("/cryptowithdraw")}
          >
            <div className="card pro_card_rmbg">
              <div className="card-body1">
                <p
                  className="card-text mb-0 text-center"
                  style={{ fontSize: "14.5px" }}
                >
                  Crypto Withdraw Pending
                </p>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                  {st?.Crypto_withdraw_pending_count || "0"}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-sm-2 cursor_pointer"
            onClick={() => navigate("/openticks")}
          >
            <div className="card pro_card_rmbg">
              <div className="card-body1">
                <p className="card-text mb-0 text-center">
                  Open Support Tickets
                </p>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                  {st?.Open_tickets_count_count || "0"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinslist: state.coinslist,
    getuserlist: state.getuserlist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(ProfitStats));
