import React, { Component } from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";
import Footer from "../../commons/Footer";
import { connect } from "react-redux";

import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import CommonTable from "../../commons/commonTable";
import Form from "../../commons/form";
import Joi from "joi-browser";
import { min } from "lodash";

class UserListByGroup extends Form {
  state = {
    phone: "",
    bknLoader: true,
    udata: [],
    loader: false,
    popupOpen: false,
    popupOpen3: false,
    ustatus: "",
    txn: false,
    txndet: [],
    txndata: false,
  };

  componentDidMount = async () => {};
  schema = {
    userid: Joi.string().min(5).trim().required(),
  };

  doSubmit = async (status) => {
    await this.setState({ btndis: true });
    const { navigate } = this.props;
    const { data } = this.state;

    try {
      const obj = {
        userid: data?.userid.replaceAll(" ", ""),
      };

      const dd = this.props.getuserlist?.Individual || [];
      var phone1 = obj?.userid?.length === 10 ? "63" + obj.userid : obj.userid;

      const gain = dd?.filter(
        (el) => el?.phone === phone1 || el?.userid === phone1
      );

      if (gain.length) {
        navigate("/usertabs", { state: { user: gain[0] } });

        return;
      }

      const { user } = await backEndCallObj("/adminget/getuser", obj);
      if (user) {
        navigate("/usertabs", { state: { user } });
      }
    } catch (ex) {
      toastfun(ex.response.data, "error");

      this.setState({ btndis: false });
    }
  };

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
          // ticker={this.props.data.ticker}
        />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title">
                        <b> Get User Details By Id </b>
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          class="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <div
                            class="tab-pane fade show active"
                            id="solid-justified-tab11"
                          >
                            <div className="card-body datatable-scroll">
                              <div className="row">
                                <div className="d-flex col-md-5 offset-md-4 ">
                                  <input
                                    type="text"
                                    name="userid"
                                    value={data?.userid}
                                    placeholder="Get Details By User ID/Phone"
                                    pattern="[0-9]*"
                                    className="py-1 mb-2 border w-75 "
                                    onChange={this.handleChangeNoValidate}
                                  />

                                  <button
                                    disabled={this.state.btndis}
                                    className="mb-2 btn btn-primary  btn-sm lh-22 rounded-0"
                                    onClick={() =>
                                      this.handleSubmitToast("accept")
                                    }
                                  >
                                    Submit
                                  </button>
                                  {/* <i className="fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0"></i> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    grouplist: state.grouplist,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserListByGroup));
