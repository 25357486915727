import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import { pushRoute } from '../../commons/pushRoute';
import { Table } from 'tabler-react';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import get_admincontrols from '../../reduxStore/action/admincntrlAction';
import { backEndCall, backEndCallObj } from '../../services/MainService';
import _ from 'lodash';
import Joi from 'joi-browser';

import { toastfun } from '../../commons/toast';
import { NavLink } from 'react-router-dom';
import Form from '../../commons/form';

class SiteControls extends Form {
  state = {
    data: {},
    cstatus: [],
    cvalues: [],
    enable: 'Enable',
    popupOpen: false,
    updatestatus: '',
    name: '',
    value: '',
    stoploader: true,
  };
  componentDidMount = async () => {
    if (!this.props.getadmincontrols) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  intialload = async ({ intial }) => {
    try {
      if (intial) {
        const { success } = await backEndCall('/adminget/getcontrols');
        await get_admincontrols(success);
      }

      let cstatuss = await this.props.getadmincontrols;

      const dd = _.pick(
        cstatuss,
        'register',
        'login',
        'crypto_withdraw',
        'peso_withdraw',
        'otc_service',
        'transfer',
        'card_deposit',
        'dragonpay',
        'bills',
        'eload',
        'crypto_address_gen'
      );
      this.setState({ stoploader: false });

      await this.setState({ cstatus: dd, alldata: cstatuss });
    } catch (error) {
      console.warn(error, 'error');
      await this.setState({ stoploader: false });
    }
  };

  onOpenModal = async (e, val) => {
    await this.setState({ name: e, value: val.target.value });
    this.setState({ popupOpen: true });
  };

  onCloseModal = () => {
    this.setState({ deleteOpen: false, popupOpen: false });
  };

  advanControls = async (item) => {
    const { navigate } = this.props;

    navigate('/sitecontrolsadvan', { state: item });
  };

  updateStatus = async (e, val) => {
    await this.setState({ popupOpen: false });
    try {
      let update = await this.state;
      const sts = {
        key: update.name,
        value: update.value,
      };

      const { success } = await backEndCallObj('/admin/editcontrols', sts);

      if (success) {
        this.intialload({ intial: true });
      }
    } catch (ex) {
      console.warn(ex);
    }
  };

  render() {
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card '>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title '>
                        <b>Admin Controls </b>
                      </h3>
                    </div>

                    <div className='card-body datatable-scroll'>
                      <div className='table-responsive dt-table mt-1'>
                        <Table responsive className='card-table table-vcenter '>
                          <thead>
                            <th>Admin Controls</th>
                            <th>Current Status</th>
                            <th>Enable/Disable</th>
                          </thead>
                          {this.state.cstatus &&
                          Object.keys(this.state.cstatus).length ? (
                            <tbody>
                              {Object.keys(this.state.cstatus).map(
                                (key, index) => (
                                  <tr>
                                    <td
                                      className='cap'
                                      style={{ fontSize: '0.9rem' }}
                                    >
                                      {key}
                                    </td>
                                    <td>
                                      <span>{this.state.cstatus[key]}</span>
                                    </td>
                                    <td>
                                      <button
                                        value={
                                          this.state.enable ===
                                          this.state.cstatus[key]
                                            ? 'Disable'
                                            : 'Enable'
                                        }
                                        type='button'
                                        onClick={(val) =>
                                          this.onOpenModal(key, val)
                                        }
                                        className={
                                          this.state.enable ===
                                          this.state.cstatus[key]
                                            ? 'btn btn-sm  btn-light mx-1 my-1 text-center'
                                            : 'btn btn-sm  btn-success mx-1 my-1 text-center'
                                        }
                                      >
                                        {key === 'ticketprice'
                                          ? 'EDIT'
                                          : this.state.enable ===
                                            this.state.cstatus[key]
                                          ? 'Disable'
                                          : 'Enable'}
                                        {/* // this.state.enable === this.state.cstatus[key] 
                                  //   ? "Disable"
                                  //   : "Enable" 
                                  // // {this.state.enable === this.state.cstatus[key] 
                                  //   : "EDIT" */}
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          ) : this.state.stoploader ? (
                            <tr className=' m-auto py-5'>
                              <th colSpan='12'>
                                {' '}
                                <div
                                  className='bars'
                                  style={{
                                    marginLeft: '35rem',
                                  }}
                                >
                                  <Bars
                                    className='text-center  offset-md-5 py-5'
                                    color='grey'
                                    height={80}
                                    width={80}
                                  />
                                </div>{' '}
                              </th>
                            </tr>
                          ) : (
                            <th colSpan='8'>
                              {' '}
                              <div className='text-center  offset-md-1 py-5'>
                                No Data Found
                              </div>
                            </th>
                          )}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal open={this.state.popupOpen} onClose={this.onCloseModal} center>
          <div>
            {this.state.name !== 'ticketprice' ? (
              <div className='register_top in'>
                <div className='signinsignup-widget'>
                  <div className='popup-title py-1'>
                    <h5
                      className='modal-title text-center'
                      id='exampleModalLabel'
                    >
                      Are You Sure? <br></br>You Want to Update{' '}
                      <span className='pop_phone'>
                        {' '}
                        {this.state.name.toUpperCase()}{' '}
                      </span>
                      Status
                    </h5>
                  </div>

                  <div className='login-form'>
                    <div className='signin_username'>
                      <div className='signin-form'>
                        <form className='form-horizontal'>
                          <div className='mck-form-group'></div>
                          <div className='getstarted text-center py-2'>
                            <button
                              // disabled={this.state.buttondisable}

                              onClick={this.updateStatus}
                              type='button'
                              data-dismiss='modal'
                              className='btn btn-success '
                            >
                              Yes
                            </button>
                            <button
                              type='button'
                              className='btn btn-danger ml-1'
                              onClick={this.onCloseModal}
                            >
                              No
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getadmincontrols: state.getadmincontrols,
  };
};

export default connect(mapStateToProps)(pushRoute(SiteControls));
