import { GET_CLOSETICKETS } from "../action/closeticketAction";

const closeticketsReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_CLOSETICKETS:
      return payload;
    default:
      return state;
  }
};
export default closeticketsReducer;
