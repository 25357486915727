import { combineReducers } from 'redux';
import txcontrolReducer from './txcontrolReducer';
import authReducer from './authReducer';
import userbalReducer from './userbalReducer';
import userlisttReducer from './userlistReducer';
import cryptoadrsReducer from './cryptoadrsReducer';
import userbanklistReducer from './userbankslistReducer';
import adminslistReducer from './adminslistReducer';
import openticketsReducer from './openticketsReducer';
import closeticketsReducer from './closeticketReducer';
import admincontrolReducer from './admincntrlReducer';
import sellerlistReducer from './sellerlistReducer';
import catcontrolReducer from './catcontrolReducer';
import marketplacecontrolReducer from './marketplacecntrlReducer';
import instoreordereducer from './instoreordersReducer';
import onlineeordereducer from './onlineordersReducer';
import ridersReducer from './ridersReducer';
import dashboardValuesReducer from './dashboardValuesReducer';
import kycreqreducer from './kycreqReucer';
import traderkycreqreducer from './traderkycReducer';
import cropkycreqreducer from './cropkycReducer';
import userlimitsreducer from './userlimitsRecuder';
import grouplistReducer from './grouplistReducer';
import coinslistReducer from './coinslistReducer';
import pndcrywtrReducer from './pendingCryWithReducer';
import pndpesowtrReducer from './PendingPesoWtrReducer';
import reportsReducer from './reportsReducer';
import profitReportsReducer from './profitReportReducer';

export default function allReducers() {
  return combineReducers({
    getcurrentuser: authReducer,
    getriders: ridersReducer,
    getuserbalance: userbalReducer,
    getuserlist: userlisttReducer,
    getcryptoadrs: cryptoadrsReducer,
    getbankslist: userbanklistReducer,
    getadminslist: adminslistReducer,
    getopentickets: openticketsReducer,
    getclosetickets: closeticketsReducer,
    getadmincontrols: admincontrolReducer,
    getcatcontrols: catcontrolReducer,
    getmarketplacecontrols: marketplacecontrolReducer,
    instoreorders: instoreordereducer,
    onlineorders: onlineeordereducer,
    gettxcontrols: txcontrolReducer,
    getsellerlist: sellerlistReducer,
    getdashboardvalueslist: dashboardValuesReducer,
    kycreqlist: kycreqreducer,
    traderkycreqlist: traderkycreqreducer,
    getuserlimits: userlimitsreducer,
    cropkycreqlist: cropkycreqreducer,
    grouplist: grouplistReducer,
    coinslist: coinslistReducer,
    pndcrywtrlist: pndcrywtrReducer,
    pndpesowtrlist: pndpesowtrReducer,
    reportslist: reportsReducer,
    profitreportslist: profitReportsReducer,
  });
}
