import React, { Component } from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";

import Footer from "../../commons/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { backEndCall } from "../../services/MainService";
import Joi from "joi-browser";
import Form from "../../commons/form";
import moment from "moment";
import { toastfun } from "../../commons/toast";
import CommonTable from "../../commons/commonTable";
import Moment from "react-moment";
import { connect } from "react-redux";

class UserLogDate extends Form {
  state = {
    txnid: "",
    data: {},
    udata: [],
    loader: false,
    hist: false,
    startDate: "",
    btndis: true,
  };
  schema = {
    userid: Joi.string().required().label("User ID"),
    date: Joi.required().label("Date"),
  };
  componentDidMount = () => {};
  handleDateChange = async (date) => {
    var d = date;
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var year = d.getFullYear();
    const dd = year + "/" + month + "/" + day;
    await this.setState({
      startDate: dd,
    });
  };
  doSubmit = async () => {
    await this.setState({ loader: true });
    const { data } = this.state;
    try {
      const { success } = await backEndCall(
        "/adminget/get_user_logs/" + data.userid?.trim() + "/" + data.date
      );
      // console.log(success);

      // var mySubString = success.substring(
      //   success.indexOf('{') + 1,
      //   success.lastIndexOf('}')
      // );

      if (success) {
        await this.setState({
          udata: success,
          textarea: true,
        });
      }
    } catch (ex) {
      toastfun(ex.response.data, "error");
      this.setState({ startDate: "", loader: false });
    }
  };

  colums = [
    {
      label: "DATE",
      content: (date) => (
        <Moment unix format="DD-MM-YYYY" fromNow>
          {date.timestamp / 1000}
        </Moment>
      ),
    },
    { path: "message", label: "MESSAGE" },
  ];

  render() {
    const { data, errors, udata } = this.state;
    const { navigate } = this.props;
    const det = moment();
    var date = "";

    if (data?.date) {
      date = new Date(data?.date);
    }

    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row pb-5 mb-5">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title ">
                        <b> User Log Details </b>
                      </h3>
                      <button
                        onClick={() => {
                          navigate(-1);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>
                    <hr className="mt-0"></hr>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group offset-md-1 col-md-3">
                          <label>Date</label>
                          <DatePicker
                            onChange={(val) => this.handleDate(val, "date")}
                            value={data?.date}
                            name="date"
                            showMonthDropdown
                            showYearDropdown
                            withPortal
                            maxDate={det._d}
                            selected={date}
                            placeholderText="Please Select Date.."
                            className="py-2 mb-1 border w_20"
                          />

                          <small>{errors?.date}</small>
                        </div>

                        <div className="form-group col-md-3">
                          <label> User ID</label>
                          <input
                            type="text"
                            name="userid"
                            value={data?.userid}
                            className="form-control"
                            placeholder="Enter User ID"
                            onChange={this.handleChangeNoValidate}
                          />
                          <small>{errors?.userid}</small>
                        </div>
                        <div className="form-group col-md-2">
                          <button
                            type="button"
                            onClick={this.handleSubmitToast}
                            className="btn btn-primary btn-lg btn-block mt-3 ml-4 loc-bttn"
                            disabled={this.state.btndisabled}
                          >
                            {" "}
                            Submit{" "}
                          </button>
                        </div>
                      </div>
                      {/* <CommonTable
                        colums={this.colums}
                        data={udata}
                        bknLoader={this.state.bknLoader}
                        loadMoreButton={this.state.loadMoreButton}
                        loadmore={this.state.loadmore}
                        fetchData={this.fetchData}
                      /> */}
                      {this.state.textarea && (
                        <textarea
                          value={udata}
                          style={{ width: "97%", marginLeft: "2rem" }}
                          className="text-center"
                          rows="100"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getuserlist: state.getuserlist,
    grouplist: state.grouplist,
  };
};

export default connect(mapStateToProps)(pushRoute(UserLogDate));
