import React from 'react';
import HomeHeader from '../../commons/Header';
import { connect } from 'react-redux';
import { paginate } from '../../commons/paginate';
import moment from 'moment';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import { NavLink } from 'react-router-dom';
import { pushRoute } from '../../commons/pushRoute';
import { fields } from '../../data';

import { BallTriangle } from 'react-loader-spinner';

import { Modal } from 'react-responsive-modal';
import get_adminslist from '../../reduxStore/action/adminslistAction';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';
import Form from '../../commons/form';
import Joi from 'joi-browser';

class AdminsList extends Form {
  state = {
    adm: [],
    data: {},
    dmov: [],
    currentPage: 1,
    pageSize: 15,
    admn: '',
    loader: false,
    popupOpen: false,
    delpopupOpen: false,
    resetpopup: false,
    phone: '',
    pwd: '',
    stype: '',
    stoploader: true,
  };

  componentDidMount = async () => {
    if (!this.props.getadminslist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(15)
      .required()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
      .options({
        language: {
          string: {
            regex: {
              base: 'Must contain at least 1 lowercase,1 uppercase alphabetical,1 numeric ',
              test: 'another description',
            },
          },
        },
      })
      .label(' Password'),
  };

  intialload = async ({ intial }) => {
    if (intial) {
      await get_adminslist();
    }
    const dd = await this.props.getadminslist;

    this.setState({ stoploader: false });
    await this.setState({ adm: dd.admins, dmov: dd.admins });
  };

  onOpenModal = async (adm) => {
    await this.setState({ popupOpen: true, phone: adm.phone });
  };
  resetpopupOpen = (adm) => {
    this.setState({ resetpopup: true, phone: adm.phone });
  };
  delpopup = (adm) => {
    this.setState({ delpopupOpen: true, phone: adm.phone });
  };
  onCloseModal = () => {
    this.setState({ popupOpen: false, delpopupOpen: false, resetpopup: false });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ adm: this.state.dmov });
    } else {
      if (this.state.adm && this.state.adm.length > 0) {
        const fd = Object.values(this.state.adm).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .trim()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          } else {
            let mdate = moment(val.ndate).format('D MMM YYYY hh:mm A');
            if (
              mdate
                .toLowerCase()
                .includes(evt.target.value.trim().toString().toLowerCase())
            ) {
              return val;
            }
          }
        });

        await this.setState({ adm: fd, currentPage: 1 });
      }
    }
  };

  changeAdminType = async (adm) => {
    const { stype, phone } = this.state;
    this.setState({ popupOpen: false });
    if (!stype) {
      toastfun('Select AdminType', 'error');
      return;
    }
    try {
      const obj = {
        phone: phone,
        type: stype,
      };

      const data = await backEndCallObj('/admin/changeType', obj);
      this.setState({ popupOpen: false });

      this.intialload({ intial: true });

      if (data) {
        toastfun(data.success, 'success');
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
    }
  };
  deleteAdmin = async () => {
    const { navigate } = this.props;
    this.setState({ delpopupOpen: false });
    try {
      const obj = {
        phone: this.state.phone,
      };

      const data = await backEndCallObj('/admin/removeadmin', obj);
      this.intialload({ intial: true });

      if (data) {
        toastfun(data.success, 'success');
        setTimeout(() => {
          navigate('/admlist');
        }, 200);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status === 400
      ) {
        toastfun(ex.response.data, 'error');
      }
    }
  };

  doSubmit = async () => {
    this.setState({ resetpopup: false });

    try {
      const obj = {
        phone: this.state.phone,
        password: this.state.data.password,
      };

      const data = await backEndCallObj('/admin/adminPwdChange', obj);
      await get_adminslist();

      if (data) {
        toastfun('Password Updated Successfully', 'success');

        // await this.setState({ popupOpen: true, pwd: data.password });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, 'error');
      }
    }
  };
  getPagedData = () => {
    const { pageSize, currentPage } = this.state;
    const movies = paginate(this.state.adm, currentPage, pageSize);
    return {
      totalCount: this.state.adm ? this.state.adm.length : null,
      data: movies,
    };
  };

  render() {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} user={this.props.location.state} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Admins List </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>

                                <div className='pull-right no-gutters'>
                                  <NavLink
                                    to={{
                                      pathname: '/addadmin',
                                    }}
                                    type='button'
                                    className='btn text-white theme-btn'
                                    style={{
                                      background:
                                        'linear-gradient(to bottom, #4e4376, #2b5876)',
                                      fontWeight: '600',
                                      fontSize: '14px',
                                    }}
                                  >
                                    Add Admin
                                  </NavLink>
                                </div>
                              </div>
                              {/* <span>Showing {totalCount} users</span> */}

                              <div className='mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <th>SNO</th>
                                    <th>PHONE</th>
                                    <th>NAME</th>
                                    <th>ADMIN TYPE</th>
                                    <th>ACTION</th>
                                    <th>ACTION</th>
                                    <th>DELETE</th>
                                  </thead>
                                  {this.state.adm && this.state.adm.length ? (
                                    <tbody>
                                      {this.state.adm.map((k, i) => (
                                        <tr key={i + 1}>
                                          <td>{i + 1}</td>
                                          <td>{'+' + k.phone}</td>

                                          <td className='cap'>{k.name}</td>
                                          <td>{k.admin_type}</td>
                                          <td>
                                            <div className=' no-gutters'>
                                              <div
                                                onClick={() =>
                                                  this.resetpopupOpen(k)
                                                }
                                                type='button'
                                                className='btn text-white bg-primary'
                                              >
                                                Change Password
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className=' no-gutters'>
                                              <div
                                                onClick={() =>
                                                  this.onOpenModal(k)
                                                }
                                                type='button'
                                                className='btn text-white bg-primary'
                                              >
                                                Change Admin Type
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div
                                              className=' no-gutters'
                                              onClick={() => this.delpopup(k)}
                                            >
                                              <i
                                                style={{
                                                  fontSize: '22px',
                                                  color: 'red',
                                                  cursor: 'pointer',
                                                }}
                                                className='fa fa-trash-o'
                                                aria-hidden='true'
                                              ></i>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : this.state.stoploader ? (
                                    <tr className=' m-auto py-5'>
                                      <th colSpan='8'>
                                        {' '}
                                        <div className=''>
                                          <BallTriangle
                                            className='text-center  offset-md-5 py-5'
                                            // type="Bars"
                                            color='grey'
                                            // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                            height={80}
                                            width={80}
                                          />
                                        </div>
                                      </th>
                                    </tr>
                                  ) : (
                                    <th colSpan='8'>
                                      {' '}
                                      <div className='text-center  offset-md-1 py-5'>
                                        No Admins Found
                                      </div>
                                    </th>
                                  )}
                                </Table>

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal open={this.state.popupOpen} onClose={this.onCloseModal} center>
          <div>
            <div className='register_top in'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1 '>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Are You Sure ? Do You Want Change Type For Admin:
                    <br></br>
                    Phone:<span className='mx-1'>{this.state.phone}</span>
                    <br></br>
                    <select
                      style={{ margin: '1rem 1rem 1rem 0rem' }}
                      className=' form-control '
                      onChange={(r) => {
                        this.setState({ stype: r.target.value });
                      }}
                    >
                      {fields.admin_types.map((r) => (
                        <option value={r.value} required>
                          {r.label}
                        </option>
                      ))}
                    </select>
                  </h5>
                </div>
              </div>
              <div className='offset-md-3 my-1'>
                <button
                  type='button'
                  class='btn btn-success mx-1'
                  onClick={this.changeAdminType}
                >
                  Submit
                </button>
                <button type='button' class='btn btn-danger'>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal open={this.state.resetpopup} onClose={this.onCloseModal} center>
          <div>
            <div className='register_top in'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1 '>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Change Password For Admin:<br></br>
                    Phone:<span className='mx-1'>{this.state.phone}</span>
                    <br></br>
                    <input
                      type={this.state.pwd ? 'text' : 'password'}
                      name='password'
                      value={data?.password}
                      style={{ margin: '1rem 1rem 1rem 0rem' }}
                      placeholder='Enter Password'
                      className='form-control  text-center '
                      onChange={this.handlePassword}
                    />
                    <i
                      className={
                        !this.state.pwd
                          ? 'fa fa-eye-slash  text-dark admin_list_pop_eye'
                          : 'fa fa-eye  text-dark admin_list_pop_eye'
                      }
                      onClick={() => this.setState({ pwd: !this.state.pwd })}
                    ></i>
                    <small>{errors?.password}</small>
                  </h5>
                </div>
              </div>

              <div className='login-form'>
                <div className='signin_username'>
                  <div className='signin-form'>
                    <form className='form-horizontal'>
                      <div className='mck-form-group'></div>
                      <div className='getstarted text-center py-2'>
                        <button
                          // disabled={this.state.buttondisable}
                          onClick={this.handleSubmit}
                          type='button'
                          data-dismiss='modal'
                          className='btn btn-success '
                        >
                          Submit
                        </button>
                        <button
                          type='button'
                          className='btn btn-danger ml-1'
                          onClick={this.onCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.delpopupOpen}
          onClose={this.onCloseModal}
          center
        >
          <div>
            <div className='register_top in'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1'>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Are You Sure ? Do You Want to Delete <br></br>
                    <span className='mr-2'>{this.state.phone}</span>Admin?
                  </h5>
                </div>

                <div className='login-form'>
                  <div className='signin_username'>
                    <div className='signin-form'>
                      <form className='form-horizontal'>
                        <div className='mck-form-group'></div>
                        <div className='getstarted text-center py-2'>
                          <button
                            // disabled={this.state.buttondisable}
                            onClick={this.deleteAdmin}
                            // onClick={(val) => this.selectedValue(val)}
                            type='button'
                            data-dismiss='modal'
                            className='btn btn-success '
                          >
                            Yes
                          </button>
                          <button
                            type='button'
                            className='btn btn-danger ml-1'
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/*<Modal open={this.state.resetpopup} onClose={this.onCloseModal} center>
          <div onClick={this.onOpenpopupModal}>
            <div className="register_top in">
              <div className="signinsignup-widget">
                <div className="popup-title py-1">
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Are You Sure ? Do You Want to Reset Password? <br></br>
                    <span className="mr-2">{this.state.phone}</span>Admin?
                  </h5>
                </div>

                <div className="login-form">
                  <div className="signin_username">
                    <div className="signin-form">
                      <form className="form-horizontal">
                        <div className="mck-form-group"></div>
                        <div className="getstarted text-center py-2">
                          <button
                            onClick={this.resetPassword}
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-success "
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger ml-1"
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal> */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getadminslist: state.getadminslist,
  };
};

export default connect(mapStateToProps)(pushRoute(AdminsList));
