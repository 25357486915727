import jwtDecode from 'jwt-decode';
import http from './commonServices/httpService';
import helpers from './commonServices/cryptos';
// import apiUrl from "../config.json";

const { detect } = require('detect-browser');

const apiEndpoint = process.env.REACT_APP_API_URL;

export async function dashboardValues(obj) {
  const drreqpob = helpers.encryptobj(obj);
  const { data } = await http.post(apiEndpoint + '/nimdfs/getcounts', {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export default {
  dashboardValues,
};
