// import { GET_ADMINCONTROLS } from "../action/userlistAction";

import { GET_ADMINCONTROLS } from "../action/admincntrlAction";

const admincontrolReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_ADMINCONTROLS:
      return payload;
    default:
      return state;
  }
};
export default admincontrolReducer;
