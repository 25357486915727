// import { GET_ADMINCONTROLS } from "../action/userlistAction";

import { GET_MRPLACECONTROLS } from "../action/marketplacecntrlAction";

const marketplacecontrolReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_MRPLACECONTROLS:
      return payload;
    default:
      return state;
  }
};
export default marketplacecontrolReducer;
