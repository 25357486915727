import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import { pushRoute } from '../../commons/pushRoute';
import { txcontrols, updatetxncontrols } from '../../services/adminService';
import { BallTriangle } from 'react-loader-spinner';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import get_admincontrols from '../../reduxStore/action/admincntrlAction';
import _ from 'lodash';
import { toastfun } from '../../commons/toast';
import { backEndCallObj } from '../../services/MainService';
class TxControls extends Component {
  state = {
    txc: [],
    editbtn: false,
    stoploader: true,
    popupOpen: false,
    tp: '',
  };
  componentDidMount = async () => {
    if (!this.props.getadmincontrols) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };
  intialload = async ({ intial }) => {
    try {
      if (intial) {
        await get_admincontrols();
      }

      let dd = await this.props.getadmincontrols;
      const txc = _.pick(
        dd,
        'billers_fee',
        'drpayco_fee',
        'eload_fee',
        'netbank_cashin_fee',
        'netbank_cashout_fee',
        'otc',
        'otc_fee',
        'user_trans_max',
        'user_trans_min',
        'withdraw'
      );

      this.setState({ stoploader: false });

      await this.setState({ txc });
    } catch (error) {
      console.warn(error, 'error');
      await this.setState({ loader: false });
    }
  };

  onOpenModal = async (e, val) => {
    await this.setState({ name: e, value: val.target.value });
    this.setState({ popupOpen: true });
  };
  onCloseModal = () => {
    this.setState({ popupOpen: false });
  };

  updateticketPrice1 = async (e) => {
    this.setState({ tp: e.target.value });
  };

  renderObjControls = (main_key, txc) => {
    return (
      <>
        {Object.keys(txc).map((key, index) => (
          <>
            {txc[key] && toString.call(txc[key]).slice(8, -1) === 'Object' ? (
              this.renderObjControls(main_key + '.' + key, txc[key])
            ) : (
              <>
                <div class='col-sm-3'>
                  <div class='card '>
                    <div class='card-body'>
                      <>
                        <h5 class='card-title'>{main_key + '.' + key}</h5>
                        <div className='row'>
                          <input
                            style={{
                              cursor: 'not-allowed',
                            }}
                            className='mx-3 w-50'
                            value={txc[key]}
                            disabled
                          />
                          <button
                            class='btn btn-primary btn-sm'
                            onClick={(val) =>
                              this.onOpenModal(main_key + '.' + key, val)
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  updateticketPrice = async () => {
    if (this.state.tp) {
      await this.setState({ buttondisable: true });
      let update = await this.state;

      if (update.name.includes('fee')) {
        if (this.state.tp > 90) {
          toastfun('Fee must in below 90%', 'error');
          await this.setState({ buttondisable: false });
          return;
        }
      } else {
      }

      try {
        const sts = {
          key: update.name,
          value: this.state.tp,
        };

        const { success } = await backEndCallObj('/admin/editcontrols', sts);

        if (success) {
          await this.setState({ popupOpen: false, buttondisable: false });
          toastfun(success, 'success');
          this.intialload({ intial: true });
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        await this.setState({ popupOpen: false, buttondisable: false });
      }
    }
  };
  render() {
    const { txc } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card '>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title '>
                        <b>Tx Controls </b>
                      </h3>
                    </div>

                    <div className='card-body datatable-scroll'>
                      <div className='table-responsive dt-table mt-1'>
                        {this.state.txc &&
                        Object.keys(this.state.txc).length ? (
                          <div class='row m-1'>
                            {Object.keys(this.state.txc).map((key, index) => (
                              <>
                                {txc[key] &&
                                toString.call(txc[key]).slice(8, -1) ===
                                  'Object' ? (
                                  this.renderObjControls(key, txc[key])
                                ) : (
                                  <>
                                    <div class='col-sm-3'>
                                      <div class='card '>
                                        <div class='card-body'>
                                          <>
                                            <h5 class='card-title'>{key}</h5>
                                            <div className='row'>
                                              <input
                                                style={{
                                                  cursor: 'not-allowed',
                                                }}
                                                className='mx-3 w-50'
                                                value={this.state.txc[key]}
                                                disabled
                                              />
                                              <button
                                                class='btn btn-primary btn-sm'
                                                onClick={(val) =>
                                                  this.onOpenModal(key, val)
                                                }
                                              >
                                                Edit
                                              </button>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        ) : this.state.stoploader ? (
                          <div className='offset-md-5'>
                            <BallTriangle
                              className='text-center py-5'
                              // type="Bars"
                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                              color='grey'
                              height={80}
                              width={80}
                            />
                          </div>
                        ) : (
                          <th colSpan='8'>
                            {' '}
                            <div className='text-center  offset-md-1 py-5'>
                              No Data Found
                            </div>
                          </th>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.popupOpen && (
          <Modal open={this.state.popupOpen} onClose={this.onCloseModal} center>
            <div className='register_top in'>
              <div className='signinsignup-widget'>
                <div className='popup-title py-1'>
                  <h5
                    className='modal-title text-center'
                    id='exampleModalLabel'
                  >
                    Are You Sure? <br></br>You Want to Update{' '}
                    <span>
                      <h3 className='text-dark'> {this.state.name} </h3>
                    </span>
                  </h5>
                </div>
                <input
                  type='number'
                  className='text-center py-1 offset-md-3'
                  onChange={this.updateticketPrice1}
                />
                <div className='login-form'>
                  <div className='signin_username'>
                    <div className='signin-form'>
                      <form className='form-horizontal'>
                        <div className='mck-form-group'></div>
                        <div className='getstarted text-center py-2'>
                          <button
                            disabled={this.state.buttondisable}
                            onClick={this.updateticketPrice}
                            type='button'
                            data-dismiss='modal'
                            className='btn btn-success '
                          >
                            Submit
                          </button>
                          <button
                            disabled={this.state.buttondisable}
                            type='button'
                            className='btn btn-danger ml-1'
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getadmincontrols: state.getadmincontrols,
  };
};

export default connect(mapStateToProps)(pushRoute(TxControls));
