import { GET_CROPKYCREQLIST } from '../action/cropkycreqAction';

const cropkycreqreducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_CROPKYCREQLIST:
      return payload;
    default:
      return state;
  }
};
export default cropkycreqreducer;
