import React, { Component } from "react";
import HomeHeader from "../../commons/Header";
import Footer from "../../commons/Footer";
import { pushRoute } from "../../commons/pushRoute";
import Moment from "react-moment";

import { Table } from "tabler-react";
import { connect } from "react-redux";
import moment from "moment";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import CommonTable from "../../commons/commonTable";

class TxnHistory extends Component {
  state = {
    txnid: "",
    udata: [],
    loader: false,
    hist: false,
    bknLoader: true,
    skip: 0,
    loadMoreButton: false,
    backEndBtn: false,
    ld: [],
    htype: "",
  };
  componentDidMount = async () => {
    const user = this.props.location.state;
    await this.setState({ user });
    this.backendCall();
  };

  colums = [
    {
      label: "DATE",
      content: (date) => (
        <Moment unix format="DD-MM-YYYY hh:mm A" fromNow>
          {date.time / 1000}
        </Moment>
      ),
    },
    { path: "userid", label: "USER ID" },
    { path: "phone", label: "PHONE" },
    { path: "tid", label: "TRANSACTION ID" },
    { path: "amount", label: "AMOUNT" },
    { path: "fee", label: "FEE" },
    { path: "coin", label: "COIN" },
    { path: "type", label: "TYPE" },
    { path: "status", label: "STATUS" },
    { path: "comment", label: "COMMENT" },
  ];

  refresh = async () => {
    await this.backendCall();
  };

  backendCall = async () => {
    try {
      await this.setState({ bknLoader: true, refreshButton: true });
      const { user } = this.state;
      const obj = {
        userid: user.userid.trim(),
      };
      const res = await backEndCallObj("/adminget/getuserhistory", obj);

      if (res) {
        await this.setState({ bknLoader: false });
        this.setState({
          history: res.History,
          bill_history: res.Billers_History,
          tableData: res.History,
          Referal_History: res.Referal_History,
          refreshButton: false,
        });
      }
    } catch (error) {
      await this.setState({ bknLoader: false, refreshButton: false });
      toastfun(error.response.data, "error");
    }
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ udata: this.state.ld });
    } else {
      if (this.state.udata && this.state.udata.length) {
        const fd = Object.values(this.state.udata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          } else {
            let mdate = moment(val.date).format("D MMM YYYY hh:mm A");
            if (
              mdate
                .toLowerCase()
                .includes(evt.target.value.trim().toString().toLowerCase())
            ) {
              return val;
            }
          }
        });

        await this.setState({ udata: fd });
      }
    }
  };

  handleTableData = async (historyName) => {
    var filteredData;
    const { history } = this.state;
    if (historyName === "OTC") {
      filteredData = history.filter(
        (o) => o.type === "BUY" || o.type === "SELL"
      );
    } else if (historyName === "CASHIN/CASHOUT") {
      filteredData = history.filter(
        (o) => o.type === "Withdraw" || o.type === "Deposit"
      );
    } else if (historyName === "Referral") {
      filteredData = this.state.Referal_History;
    }

    //
    // const filteredData = history.filter((o) => o.type === historyName);
    await this.setState({ tableData: filteredData, htype: historyName });
  };
  onclose = () => {
    // this.props.history.push("/txns");
    this.setState({ hist: false });
  };

  render() {
    const { user, history, bill_history, tableData } = this.state;

    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline dflex_center">
                      <h3 className="card-title">
                        <b>
                          {" "}
                          Transaction History Of User - &nbsp;
                          <span className="text-danger">
                            {user?.userid} &nbsp;/&nbsp;{user?.member_name}
                            &nbsp;/&nbsp;{user?.phone}
                          </span>
                        </b>
                      </h3>
                      {this.state.hist ? (
                        <div className="d-flex col-md-1 offset-md-4">
                          <i
                            class="fa fa-close text-danger"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={this.onclose}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                    {/* {this.state.hist ? (
                      <div className="d-flex col-md-4 no-gutters ml-4">
                        <input
                          type="text"
                          placeholder="Search...."
                          pattern="[0-9]*"
                          className="py-1 pl-2 mb-2 border w-50"
                          onChange={this.handleSearch}
                        />
                        <i className="fa fa-search btn btn-primary mb-2 btn-sm lh-22 rounded-0"></i>
                      </div>
                    ) : null} */}
                    <div className="card-body pt-0">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="solid-justified-tab11"
                          >
                            <div className="card-body datatable-scroll pt-0">
                              {this.state.hist || this.state.loader ? null : (
                                <div
                                  className="form-group row"
                                  // style={{ alignItems: 'center' }}
                                >
                                  <div className="col d-flex m-2">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Wallet");
                                      }}
                                      className={
                                        this.state.htype === "Wallet"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      WALLET
                                    </button>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("OTC");
                                      }}
                                      className={
                                        this.state.htype === "OTC"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      OTC
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("CASHIN/CASHOUT");
                                      }}
                                      className={
                                        this.state.htype === "CASHIN/CASHOUT"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      CASHIN/CASHOUT
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        this.handleTableData("Referral");
                                      }}
                                      className={
                                        this.state.htype === "Referral"
                                          ? "btn text-white theme-btn ml-2 active_button"
                                          : "btn text-white theme-btn ml-2"
                                      }
                                      style={{
                                        background: "#be5869",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Referral
                                    </button>
                                  </div>

                                  {this.state.refreshButton ? null : (
                                    <i
                                      className="fa fa-refresh ml-2 offset-md-4 text-danger"
                                      style={{ fontSize: "2rem" }}
                                      aria-hidden="true"
                                      onClick={this.refresh}
                                    ></i>
                                  )}
                                </div>
                              )}
                              <h1 className="text-danger text-center">
                                {this.state.htype
                                  ? `${this.state.htype}`
                                  : "All Transactions"}
                              </h1>
                              <div className="mb-2 table-responsive dt-table">
                                <CommonTable
                                  colums={this.colums}
                                  data={tableData}
                                  bknLoader={this.state.bknLoader}
                                  loadMoreButton={this.state.loadMoreButton}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userbalances: state.userbalances,
  };
};

export default connect(mapStateToProps)(pushRoute(TxnHistory));
