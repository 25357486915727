import store from "../store/index";

import marketplace from "../../services/marketplace/marketplacecontrolsService";
import { toast } from "react-toastify";

export var GET_MRPLACECONTROLS = "GET_MRPLACECONTROLS";

async function get_marketplacecontrols() {
  try {
    const data = await marketplace.getmarketplacecontrols();
    store.dispatch({ type: GET_MRPLACECONTROLS, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_marketplacecontrols;
