// import { GET_ADMINCONTROLS } from "../action/userlistAction";

import { GET_CATCONTROLS } from "../action/catcontrolsAction";

const catcontrolReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_CATCONTROLS:
      return payload;
    default:
      return state;
  }
};
export default catcontrolReducer;
