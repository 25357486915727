import React, { Component } from 'react';
import Joi from 'joi-browser';
import { toastfun } from './toast';

class Form extends Component {
  state = {};

  handleChange = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };

    if (input.name === 'phone') {
      data[input.name] = input.value.replace(/[^0-9]/g, '');

      this.setState({ data, errors });
    } else {
      if (input.value === ' ') {
        input.value = '';
        return;
      }
      data[input.name] = input.value;
      if (input.value === '') delete data[input.name];
      this.setState({ data, errors });
    }
  };

  handleChangeNoValidate = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };

    const data = { ...this.state.data };

    if (input.value === ' ') {
      input.value = '';
      return;
    }
    data[input.name] = input.value;
    if (input.value === '') delete data[input.name];
    this.setState({ data, errors });
  };

  handleNumberNoValidate = (input) => {
    input = input.target;
    const data = { ...this.state.data };
    data[input.name] = input.value
      .replace(/[^\d\.]/g, '')
      .replace(/\.(([^\.]*)\.)*/g, '.$2');

    this.setState({ data });
  };

  handleDate = (val, name) => {
    const input = { name: name, value: val };

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };

    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleChangeWithKey = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };

    if (input.value === ' ') {
      input.value = '';
      return;
    }
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handlePassword = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value.replace(/ /g, '');
    this.setState({ data, errors });
  };

  handleNumber = (input) => {
    input = input.target;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value
      .replace(/[^\d\.]/g, '')
      .replace(/\.(([^\.]*)\.)*/g, '.$2');
    this.setState({ data, errors });
  };

  subcastoptions = async (res) => {
    const input = { name: res.target.name, value: res.target.value };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
    const subcaste1 = this.state.casteList.find(
      (el) => el.value === res.target.value
    ).subcaste;
    if (!subcaste1) this.setState({ selectdis: true });
    else {
      await this.setState({ subcasteList: subcaste1 });
    }
  };

  handleSelect = async (res) => {
    const input = { name: res.target.name, value: res.target.value };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value === '') delete data[input.name];
    this.setState({ data, errors });
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleGender = async (e, value, name) => {
    const input = { name: name, value: value };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;

    await this.setState({ data, errors });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ value: 'Processing ....' });
    const errors = this.validate();

    this.setState({ errors: errors || {} });

    if (errors) return;
    this.doSubmit();
  };

  handleSubmitToast = (status = 'reject') => {
    this.setState({ value: 'Processing ....' });
    const errors = this.validate();

    this.setState({ errors: errors || {} });

    if (errors) {
      const errormsg = Object.values(errors)[0];
      toastfun(errormsg, 'error');
      return;
    }
    this.doSubmit(status);
  };
}

export default Form;
