import React from "react";
import Form from "../../commons/form";
import { Modal } from "react-responsive-modal";
import HomeHeader from "../../commons/Header";
import Footer from "../../commons/Footer";
import { backEndCall, backEndCallObj } from "../../services/MainService";
import get_coinlist from "../../reduxStore/action/coinslistAction";
import { connect } from "react-redux";
import { pushRoute } from "../../commons/pushRoute";
import CommonTable from "../../commons/commonTable";

class CoinsList extends Form {
  state = {};

  componentDidMount = async () => {
    if (!this.props.coinslist) {
      this.intialload({ intial: true });
    } else {
      if (this.props.location.state?.intialload === false) {
        await this.intialload({ intial: true });
        return;
      }
      this.intialload({ intial: false });
    }
  };

  goToCoinEditPage = (item) => {
    const { navigate } = this.props;
    navigate("/coinform", { state: item });
  };

  colums = [
    { path: "coin_name", label: "COIN NAME" },
    { path: "ticker", label: "TICKER" },

    { path: "otc_min", label: "OTC  MINIMUM" },
    { path: "otc_max", label: "OTC  MAXIMUM" },
    { path: "withdraw_min", label: "WITHDRAW  MINIMUM" },
    { path: "withdraw_max", label: "WITHDRAW  MAXIMUM" },
    { path: "withdraw", label: "WITHDRAW  " },
    { path: "address_generation", label: "ADDRESS  GENERATION" },

    {
      label: "ACTION",
      content: (item) => (
        <div onClick={() => this.deletePopup(item)}>
          <i
            class="fa fa-trash fa-2x"
            style={{ color: "red" }}
            aria-hidden="true"
          ></i>
        </div>
      ),
    },

    {
      label: "ACTION",
      content: (item) => (
        <div onClick={() => this.goToCoinEditPage(item)}>
          <i className="fa fa-edit fa-2x"></i>
        </div>
      ),
    },
  ];

  deletePopup = async (item) => {
    await this.setState({ deleteOpen: true, deleteCoin: item });
  };

  renderObjValues = (main_key, txc) => {
    return (
      <>
        {Object.keys(txc).map((key, index) => (
          <>
            {txc[key] && toString.call(txc[key]).slice(8, -1) === "Object" ? (
              this.renderObjValues(main_key + "." + key, txc[key])
            ) : (
              <>
                <table class="table col-md-6">
                  <tr>
                    <th scope="row" className="text-left ">
                      <b>{main_key + "." + key}</b>
                    </th>

                    <td className="text-right">{txc[key]}</td>
                  </tr>
                </table>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  onCloseModal = () => {
    this.setState({ deleteOpen: false });
  };

  deleteCoin = async () => {
    try {
      await this.setState({ buttondisable: true });
      const { coins, deleteCoin } = this.state;

      const objBE = {
        coin_name: deleteCoin.coin_name,
      };
      const res = await backEndCallObj("/admin/delete_coins", objBE);

      if (res) {
        this.intialload({ intial: true });

        await this.setState({
          buttondisable: false,
          deleteOpen: false,
          btndis: false,
        });
      }
    } catch (error) {
      await this.setState({ buttondisable: false });
    }
  };

  intialload = async ({ intial }) => {
    await this.setState({ bknLoader: true });
    try {
      if (intial) {
        const data = await backEndCall("/adminget/get_coins");
        await get_coinlist(data.coins);
      }

      const success = this.props.coinslist;
      await this.setState({ bknLoader: false });
      await this.setState({ coins: success });
    } catch (error) {
      console.warn(error, "error");
      await this.setState({ bknLoader: false });
    }
  };
  render() {
    const { alldata, coins, btndis, data, errors } = this.state;

    const { navigate } = this.props;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title ">
                        <b>Coints List</b>
                      </h3>

                      <div className="pull-right no-gutters">
                        <button
                          onClick={() => {
                            navigate("/coinform");
                          }}
                          type="button"
                          className="btn text-white theme-btn"
                          style={{
                            background:
                              "linear-gradient(to bottom, #4e4376, #2b5876)",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Add Coin
                        </button>
                      </div>
                    </div>

                    <div className="card-body datatable-scroll">
                      <div className="table-responsive dt-table mt-1">
                        <CommonTable
                          colums={this.colums}
                          data={coins}
                          bknLoader={this.state.bknLoader}
                          loadMoreButton={this.state.loadMoreButton}
                          loadmore={this.state.loadmore}
                          fetchData={this.fetchData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.deleteOpen && (
          <Modal
            open={this.state.deleteOpen}
            onClose={this.onCloseModal}
            center
          >
            <div className="popup-title">
              <div className="signinsignup-widget">
                <div className="popup-title py-1">
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  >
                    Are You Sure Delete ?
                  </h5>
                </div>

                <div className="login-form">
                  <div className="signin_username">
                    <div className="signin-form">
                      <form className="form-horizontal">
                        <div className="mck-form-group"></div>
                        <div className="getstarted text-center py-2">
                          <button
                            disabled={this.state.buttondisable}
                            onClick={this.deleteCoin}
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-success "
                          >
                            Submit
                          </button>
                          <button
                            disabled={this.state.buttondisable}
                            type="button"
                            className="btn btn-danger ml-1"
                            onClick={this.onCloseModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coinslist: state.coinslist,
    getadmincontrols: state.getadmincontrols,
  };
};

export default connect(mapStateToProps)(pushRoute(CoinsList));
