export const fields = {
  admin_types: [
    { label: "-- Select Admin Type --", value: "" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
  ],

  partner_names: [
    { label: "--Select Type--", value: "" },
    { label: "NetBank-CashIn", value: "netbank-cashin" },
    { label: "NetBank-CashOut", value: "NetBank-CashOut" },
    { label: "DragonPay-CashIn", value: "DragonPay-CashIn" },
    { label: "DragonPay-CashOut", value: "DragonPay-CashOut" },
    { label: "CRYPTO-CashIn", value: "CRYPTO-CashIn" },
    { label: "CRYPTO-CashOut", value: "CRYPTO-CashOut" },
    { label: "OTC", value: "OTC" },
    { label: "LoadCentral", value: "LoadCentral" },
    { label: "P2PCashin", value: "P2PCashin" },
    { label: "P2PCashout", value: "P2PCashout" },
    { label: "EGHL", value: "EGHL" },
    { label: "peso_transfer", value: "peso_transfer" },
  ],

  channel_names: [
    { label: "--Select Type--", value: "" },
    { label: "INSTAPAY", value: "INSTAPAY" },
    { label: "PESONET", value: "PESONET" },
  ],
  coin_type: [
    { label: "--Select--", value: "" },
    { label: "Enable", value: "Enable" },
    { label: "Disable", value: "Disable" },
  ],
};
