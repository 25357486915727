/* eslint-disable */

import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { pushRoute } from '../../commons/pushRoute';
// import { fullreports } from "../../services/historyService";
import moment from 'moment';
import { connect } from 'react-redux';
import get_Reportslists from '../../reduxStore/action/reportsAction';
// import get_fullstats from "../../reduxStore/action/fullreportsAction";

var QRCode = require('qrcode.react');
class MyForms extends Component {
  state = {
    options: {
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {},
    },
    categories: [],
    series: [
      {
        name: 'profits',
        data: [],
      },
    ],
  };
  componentDidMount = async () => {
    if (!this.props.reportslist) {
      // await get_Reportslists();
    }
    const dd = await this.props.reportslist;

    try {
      let weeks = [];
      let pr = [];
      const weekrep = this.state.categories.map(async (d) => {
        weeks.push(moment(d.date).format('D MMM '));
        pr.push(d.profit);
        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: [...weeks],
            },
          },
          series: [
            {
              data: [...pr],
            },
          ],
        });
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className='col-md-10 float-left padding-left pr-0 offset-md-1'>
          <div className='card'>
            <div className='pt-2 header-elements-inline border-btm'>
              <ul className='nav  nav-tabs-bottom '>
                <li className='nav-item'>
                  <a
                    href='#bottom-justified-tab11'
                    className='nav-link active show'
                    data-toggle='tab'
                  >
                    <h6> TOPWALLET STATISTICS </h6>
                  </a>
                </li>
              </ul>
            </div>

            <div className='card-body'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade active show'
                  id='bottom-justified-tab11'
                >
                  <Chart
                    className='d-chart'
                    options={this.state.options}
                    series={this.state.series}
                    type='bar'
                    height='345px'
                    convertedCatToNumeric={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-6 float-left padding-right ">
        <div className="card">
          <div className="pt-2 header-elements-inline border-btm">
            <ul className="nav  nav-tabs-bottom ">
              <li className="nav-item">
                <a
                  href="#bottom-justified-tab1"
                  className="nav-link active show"
                  data-toggle="tab"
                >
                  <h6> Withdrawal </h6>
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="#bottom-justified-tab12"
                  className="nav-link"
                  data-toggle="tab"
                >
                  <h6> Internal Transfer </h6>
                </a>
              </li>
            </ul>
          </div>

          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane fade active show" id="bottom-justified-tab1">
                <div className="form-group">
                  <label>Select MT5 Account</label>
                  <div className="clearfix" />
                  <select
                    className="form-controll col-md-12 select-new"
                    name="account"
                    value={withdrawstate.account}
                    onChange={withdraw.onChangeWithdraw}
                  >
                    <option value="0">-- Select MT5 Account --</option>
                    {accounts}
                  </select>
                </div>
                <div className="clearfix" />
                <div className="form-group">
                  <label>Select Payment Method</label>
                  <div className="clearfix" />
                  <select
                    className="form-controll col-md-12 select-new"
                    name="method"
                    value={withdrawstate.method}
                    onChange={withdraw.onChangeWithdraw}
                  >
                    <option value="0">-- Select Payment Method --</option>
                    <option>Bitcoin</option>
                    <option>Netellar</option>
                    <option>Skrill</option>
                    <option>Bank Wire</option>
                    <option>Visa/Master Card</option>
                  </select>
                </div>
                {withdrawstate.payment > 0 && (
                  <div className="form-group mt-2">
                    <label>Enter Payment Details</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-controll border"
                        placeholder="Enter Payment Details"
                        name="payment_details"
                        value={withdrawstate.payment_details}
                        onChange={withdraw.onChangeWithdraw}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text bg-primary border-primary text-white">
                          <i className="fa fa-key" />
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <div className="form-group mt-2">
                  <label> USD Amount: </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-controll border"
                      placeholder=" USD Amount"
                      name="amount"
                      value={withdrawstate.amount}
                      onChange={withdraw.onChangeWithdraw}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text bg-primary border-primary text-white">
                        <i className="fa fa-dollar" />
                      </span>
                    </span>
                  </div>
                </div>

                {withdrawstate.counter > 0 && (
                  <div className="form-group mt-2">
                    <label>
                      {withdrawstate.counter == 1 ? "Email" : "Email"} code :
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-controll border"
                        placeholder="Enter Security Code"
                        name="email_code"
                        value={withdrawstate.email_code}
                        onChange={withdraw.onChangeWithdraw}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text bg-primary border-primary text-white">
                          <i className="fa fa-key" />
                        </span>
                      </span>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                  {withdrawstate.counter <= 0 && (
                    <button
                      type="submit"
                      disabled={withdrawstate.disabled1}
                      onClick={withdraw.preValidate}
                      className={withdrawstate.btnclassName}
                    >
                      {withdrawstate.btnTxt} <i className="icon-paperplane ml-2" />
                    </button>
                  )}

                  {withdrawstate.counter > 0 && (
                    <button
                      type="submit"
                      onClick={withdraw.sendRequest}
                      disabled={withdrawstate.disabled1}
                      className={withdrawstate.btnclassName}
                    >
                      {withdrawstate.btnTxt} <i className="icon-paperplane ml-2" />
                    </button>
                  )}

                  <div className="card-body" />
                </div>
                <p style={{ color: "red" }}> {withdrawstate.error}</p>
                <p className="pt-2">
                  Note :<b style={{ color: "red" }}>*</b> Minimum Withdrawal
                  Amount to process is 50 USD
                </p>
              </div>

              <div className="tab-pane fade " id="bottom-justified-tab12">
                <div className="form-group">
                  <label>Select MT5 </label>
                  <div className="clearfix" />
                  <select
                    className="form-controll col-md-12 select-new"
                    name="account"
                    value={internalstate.account}
                    onChange={internal.onChangeInternal}
                  >
                    <option value="0">-- Select MT5 Account --</option>
                    {accounts}
                  </select>
                </div>
                <div className="clearfix" />
                <div className="form-group">
                  <label>Enter Receiver's Account</label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-controll border"
                      placeholder="Enter Receivers MT5 Account"
                      name="receiveraccount"
                      value={internalstate.receiveraccount}
                      onChange={internal.onChangeInternal}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text bg-primary border-primary text-white">
                        <i className="fa fa-user" />
                      </span>
                    </span>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label> USD Amount: </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-controll border"
                      placeholder=" USD Amount"
                      name="amount"
                      value={internalstate.amount}
                      onChange={internal.onChangeInternal}
                    />
                    <span className="input-group-append">
                      <span className="input-group-text bg-primary border-primary text-white">
                        <i className="fa fa-dollar" />
                      </span>
                    </span>
                  </div>
                </div>
                {internalstate.counter >= 1 && (
                  <div className="form-group mt-2">
                    <label>Email code :</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-controll border"
                        placeholder="Enter Security Code"
                        name="email_code"
                        value={internalstate.email_code}
                        onChange={internal.onChangeInternal}
                      />
                      <span className="input-group-append">
                        <span className="input-group-text bg-primary border-primary text-white">
                          <i className="fa fa-key" />
                        </span>
                      </span>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                  {internalstate.counter <= 0 && (
                    <button
                      type="submit"
                      disabled={internalstate.disabled1}
                      onClick={internal.preValidate}
                      className={internalstate.btnclassName}
                    >
                      {internalstate.btnTxt}
                      <i className="icon-paperplane ml-2" />
                    </button>
                  )}

                  {internalstate.counter > 0 && (
                    <button
                      type="submit"
                      disabled={internalstate.disabled1}
                      onClick={internal.sendRequest}
                      className={internalstate.btnclassName}
                    >
                      {internalstate.btnTxt}
                      <i className="icon-paperplane ml-2" />
                    </button>
                  )}

                  <div className="card-body" />
                </div>
                <p style={{ color: "red" }}> {internalstate.error}</p>
                <p className="pt-2">
                  Note :<b style={{ color: "red" }}>*</b> Minimum Internalal
                  Amount to process is 50 USD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fullstats: state.fullstats,
  };
};

export default connect(mapStateToProps)(pushRoute(MyForms));
