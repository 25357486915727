import { GET_TRADERKYCREQLIST } from '../action/traderkycreqAction';

const traderkycreqreducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_TRADERKYCREQLIST:
      return payload;
    default:
      return state;
  }
};
export default traderkycreqreducer;
