import React, { Component } from 'react';
import HomeHeader from '../../commons/Header';
import { connect } from 'react-redux';
import { pushRoute } from '../../commons/pushRoute';
import { paginate } from '../../commons/paginate';
import { Table } from 'tabler-react';
import Footer from '../../commons/Footer';
import { Link, NavLink } from 'react-router-dom';

import Moment from 'react-moment';
import get_userslist from '../../reduxStore/action/userlistAction';
import { getuserbanks } from '../../services/userService';
import get_bankslist from '../../reduxStore/action/userbankslistAction';
import { BallTriangle } from 'react-loader-spinner';
import { backEndCallObj } from '../../services/MainService';
// import { lowerFirst } from "lodash";
import { toast } from 'react-toastify';

class BanksList extends Component {
  state = {
    banks: [],
    loader: true,
    active: 'active',
    dmov: [],
    currentPage: 1,
    stoploader: true,
  };

  componentDidMount = async () => {
    if (!this.props.getuserlist) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }

    // if (!this.props.getbankslist) {
    //   await get_bankslist();
    // }
    // setTimeout(() => {
    //   if (!this.props.getbankslist.length) {
    //     this.setState({ stoploader: false });
    //   }
    // }, 5000);
    // var ul = await this.props.getbankslist;
    // this.setState({ banks: ul, dmov: ul });
  };
  intialload = async ({ intial }) => {
    try {
      this.setState({ loadmore: true });
      var obj = { skip: 0 };

      if (intial) {
        await this.setState({ limits: [] });
        const dd = await backEndCallObj('/adminget/getusers', obj);

        const data = dd.users;

        await get_userslist(data);
        this.setState({ loadmore: false });

        if (data && data.length === 0) {
          await this.setState({ loader: false });
        }
      }

      const userlimits = this.props.getuserlist;

      const data = userlimits.map((item) => {
        return {
          ...item.user_limits,
          phone: item.phone,
          member_name: item.member_name,
        };
      });

      await this.setState({
        limits: data,
        dmov: data,
        alldata: userlimits,
        loadmore: false,
      });
    } catch (error) {
      console.warn(error);
      await this.setState({ loader: false });
      toast.error('error');
    }
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ banks: this.state.dmov });
    } else {
      if (this.state.banks && this.state.banks.length) {
        const fd = Object.values(this.state.banks).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          }
        });

        await this.setState({ user: fd, currentPage: 1 });
      }
    }
  };

  // getPagedData = () => {
  //   const { pageSize, currentPage } = this.state;

  //   const movies = paginate(this.state.movies, currentPage, pageSize);

  //   return { totalCount: this.state.movies.length, movies };
  // };
  render() {
    // const { popupOpen } = this.state;

    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Banks List </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll'>
                              <div className='d-flex no-gutters'>
                                <div className='col d-flex'>
                                  <input
                                    type='text'
                                    placeholder='Search..'
                                    pattern='[0-9]*'
                                    className='py-1 pl-2 mb-2 border w-25 '
                                    onChange={this.handleSearch}
                                  />
                                  <i className='fa fa-search btn btn-primary mb-2  btn-sm lh-22 rounded-0'></i>
                                </div>
                              </div>

                              <div className='mb-2 table-responsive dt-table'>
                                <Table
                                  responsive
                                  className='card-table table-vcenter text-nowrap'
                                >
                                  <thead>
                                    <th>SNO</th>
                                    <th>PHONE</th>
                                    <th>NAME</th>
                                    <th>BANK NAME</th>
                                    <th>BANK CODE</th>
                                    <th>BANK ACCOUNT NUMBER</th>
                                  </thead>
                                  <tbody>
                                    {this.state.banks &&
                                    this.state.banks.length > 0 ? (
                                      this.state.banks.map((k, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{k.phone}</td>
                                            <td>{k.name}</td>
                                            <td>{k.bankname}</td>
                                            <td>{k.bankcode}</td>
                                            <td>{k.bankaccountnumber}</td>
                                          </tr>
                                        );
                                      })
                                    ) : this.state.stoploader ? (
                                      <tr className=' m-auto py-5'>
                                        <th colSpan='8'>
                                          {' '}
                                          <div className=''>
                                            <BallTriangle
                                              className='text-center  offset-md-5 py-5'
                                              // type="Bars"
                                              // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                                              color='grey'
                                              height={80}
                                              width={80}
                                            />
                                          </div>{' '}
                                        </th>
                                      </tr>
                                    ) : (
                                      <th colSpan='8'>
                                        {' '}
                                        <div className='text-center  offset-md-1 py-5'>
                                          No Banks Found
                                        </div>
                                      </th>
                                    )}{' '}
                                  </tbody>
                                </Table>

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getbankslist: state.getbankslist,
    getuserlist: state.getuserlist,
  };
};

export default connect(mapStateToProps)(pushRoute(BanksList));
