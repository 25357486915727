import store from "../store/index";
import auth from "../../services/authService";
import { toast } from "react-toastify";
export var GET_USER = "GET_USER";

async function get_currentuser() {
  try {
    const data = auth.getCurrentUser();
    store.dispatch({ type: GET_USER, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_currentuser;
