import React, { Component } from 'react';
import Footer from '../../commons/Footer';
import HomeHeader from '../../commons/Header';
import kyc from '../../services/kycService';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { pushRoute } from '../../commons/pushRoute';

import { connect } from 'react-redux';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';

class KYCValidate extends Component {
  state = {
    video: null,
    vloader: false,
    videobutton: false,
    image1: null,
    image2: null,
    image3: null,
    sign: null,
    imagebtn: false,
    signbtn: false,
    iloader: false,
    sloader: false,
    useroption: '',
    btndisable: false,
    pageNumber: '',
    numPages: '',
  };

  async componentDidMount() {
    setTimeout(() => {
      this.redirected();
    }, 200);
    var userDeatils = this.props.location?.state;
    // userDeatils.images = {
    //   idcard:
    //     'https://topwallet.s3.amazonaws.com/KYCv3/2M7YSVL54153483%40TOP/idcard.jpg',
    //   selfie:
    //     'https://topwallet.s3.amazonaws.com/KYCv3/2M7YSVL54153483%40TOP/selfie.jpg',
    //   traderimg: 'https://www.africau.edu/images/default/sample.pdf',
    // };

    await this.setState({ userDeatils });
  }

  onDocumentLoadSuccess = async ({ numPages }) => {
    await this.setState({ numPages: numPages });
  };

  redirected = () => {
    const { navigate } = this.props;
    if (!this.props.location.state) {
      navigate('/traderkycreq');
    }
  };

  handleVerification = async (type) => {
    const { navigate } = this.props;
    const { userDeatils } = this.state;
    await this.setState({ loader: !this.state.loader, btndisable: true });

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to Validate this Client.',
      buttons: [
        {
          label: 'Yes',

          onClick: async () => {
            try {
              const obj = {
                userid: userDeatils.phone,
                status: type,
              };
              const data = await backEndCallObj(
                '/admin/accept_reject_traderkyc',
                obj
              );

              if (data) {
                toastfun('Successfully Upgraded User!', 'success');

                this.setState({ loader: !this.state.loader });
                navigate('/traderkycreq', { state: { intialload: false } });
              }
            } catch (ex) {
              if (ex.response && ex.response.status === 400) {
                toastfun(ex.response.data, 'error');
              }
            }

            this.setState({ loader: !this.state.loader, btndisable: false });
          },
        },
        {
          label: 'No',
          onClick: () =>
            this.setState({ loader: !this.state.loader, btndisable: false }),
        },
      ],
    });
    // }
  };
  handleselect = async (input) => {
    if (input.target.value === '--Select--') {
      toast.error('Please Select Account Type', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      await this.setState({ useroption: input.target.value });
    }
  };
  handlereject = async (type) => {
    const { navigate } = this.props;

    this.setState({ btndisable: true });

    try {
      const obj = {
        type: type,
        phone: this.props.location.state.Mobile,
      };
      const data = await kyc.verifireject(obj);
      if (data) {
        toast.success('Status Updated Successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          //   this.setState({ loader: !this.state.loader });
          navigate('/kycreq');
        }, 2000);
        this.setState({ btndisable: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.setState({ btndisable: false });
    }
  };

  handleacceptCorp = async () => {
    this.setState({ loader: !this.state.loader, btndisable: true });
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to Validate this Client.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const obj = {
                phone: this.props.location.state.Mobile,
                acc_type: 'Corporate',
              };

              const data = await kyc.succverifi(obj);
              toast.success('KYC Verified Successfully!!', {
                position: toast.POSITION.TOP_RIGHT,
              });

              const { navigate } = this.props;
              setTimeout(() => {
                navigate('/kycreq');
              }, 2000);
            } catch (ex) {
              if (ex.response && ex.response.status === 400) {
                toast.error(ex.response.data, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setTimeout(() => {
                  this.setState({
                    loader: !this.state.loader,
                    btndisable: false,
                  });
                }, 2000);
              }
            }
          },
        },
        {
          label: 'No',

          onClick: () =>
            this.setState({ loader: !this.state.loader, btndisable: false }),
        },
      ],
    });
  };

  render() {
    const { userDeatils } = this.state;
    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline dflex_center'>
                      <h3 className='card-title dflex_center'>
                        <b>
                          {' '}
                          Validate KYC Request Of :{' '}
                          <span style={{ color: 'blue' }}>
                            {' '}
                            {userDeatils?.userid}
                          </span>{' '}
                        </b>
                      </h3>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div class='card bg-dark row'>
                              <div
                                class='card-body text-center col-lg-12 '
                                style={{
                                  fontSize: '1.2rem',
                                  display: 'flex',
                                }}
                              >
                                <span className='col-lg-7 dflex_center'>
                                  {' '}
                                  Account Status: &nbsp;{' '}
                                  {userDeatils?.user_type}
                                </span>

                                <div
                                  className='col-lg-6 row'
                                  style={{
                                    display: 'inline',
                                  }}
                                >
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn  btn-success mt-1 col-lg-2 m-1 '
                                    onClick={() =>
                                      this.handleVerification('verified')
                                    }
                                  >
                                    Verify User
                                  </button>
                                  <button
                                    disabled={this.state.btndisable}
                                    className='btn btn-danger col-lg-2 m-1'
                                    onClick={() =>
                                      this.handleVerification('rejected')
                                    }
                                  >
                                    Reject
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className='card-body datatable-scroll'>
                              <>
                                <div class='row'>
                                  <div className=' row col-4 dflex_center'>
                                    <h4
                                      className='col-12 '
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      ID CARD
                                    </h4>
                                    <img
                                      className='col-12 p-3 req_height'
                                      src={userDeatils?.images?.idcard}
                                      alt='Id Card'
                                    />
                                  </div>
                                  <div className=' row col-4 dflex_center'>
                                    <h5
                                      className='col-12 '
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      SELFIE IMAGE
                                    </h5>
                                    <img
                                      className='col-12 p-3 req_height'
                                      src={userDeatils?.images?.selfie}
                                      alt='Selfie'
                                    />
                                  </div>
                                  <div className=' row col-4 dflex_center'>
                                    <h5
                                      className='col-12 '
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      TRADER IMAGE
                                    </h5>
                                    <>
                                      {userDeatils?.images?.trader.substring(
                                        userDeatils?.images?.trader.length - 3
                                      ) === 'pdf' ? (
                                        <div className='col-12 p-3 req_height  '>
                                          {' '}
                                          <a
                                            className='btn btn-primary p-1 '
                                            href={userDeatils?.images?.trader}
                                          >
                                            Click Here to view PDF File
                                          </a>
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            className='col-12 p-3 req_height'
                                            src={userDeatils?.images?.trader}
                                            alt='Trader Img'
                                          />

                                          {/* <a className="btn btn-primary p-1" href={this.state.image1} target="_blank"> Click Here to view PDF File</a> */}
                                        </>
                                      )}
                                    </>
                                  </div>
                                  {/* <div className='ml-4'>
                                    <div>
                                      <button
                                        style={{ width: '100%' }}
                                        disabled={this.state.btndisable}
                                        className='btn  btn-success mt-1'
                                        onClick={this.handleaccept}
                                      >
                                        Verify User
                                      </button>
                                    </div>
                                  </div>
                                  <div className='ml-4 mt-1'>
                                    <div>
                                      <button
                                        style={{ width: '100%' }}
                                        disabled={this.state.btndisable}
                                        className='btn btn-danger'
                                        onClick={() =>
                                          this.handlereject('Reject')
                                        }
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div> */}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getadminslist: state.getadminslist,
  };
};

export default connect(mapStateToProps)(pushRoute(KYCValidate));
