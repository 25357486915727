import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { pushRoute } from "../commons/pushRoute";

import { getCurrentUser } from "../services/authService";

class HomeHeader extends Component {
  state = {
    cuser: "",
  };

  componentDidMount = async () => {
    const userr = getCurrentUser();

    // if (!this.props.user) {
    //   await get_user();
    // }
    // let userr = this.props.user;
    this.setState({ cuser: userr });
  };
  render() {
    const { navigate } = this.props;
    return (
      <React.Fragment>
        <div className="page-header page-header-dark">
          <div
            style={{}}
            className="navbar navbar-expand-md navbar-dark border-transparent m-auto row row-cols-2 "
          >
            <div className="col-6 ">
              <NavLink to="/">
                <img
                  src={require("../assets/logo2.png")}
                  alt="top-wallet"
                  style={{ height: "10vh", width: "15rem" }}
                  className="mt-1  d-block"
                />
              </NavLink>
            </div>

            <div className="col admin_dropdown">
              <ul style={{ listStyle: "none", display: "contents" }}>
                <li className="nav-item dropdown dropdown-user">
                  <a
                    disabled="disabled"
                    href="/"
                    style={{ color: "black" }}
                    className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img
                      src="global_assets/images/demo/users/face11.jpg"
                      className="rounded-circle mr-2"
                      height="34"
                      alt="img"
                    />
                    <span>
                      {this.state.cuser && this.state.cuser.name
                        ? this.state.cuser.name
                        : "Hello Admin"}
                    </span>
                  </a>

                  <div className="dropdown-menu ">
                    <a className="dropdown-item" disabled="disabled" href="/">
                      <i className="icon-user-plus" />
                      {this.state.cuser ? this.state.cuser.phone : null}
                    </a>

                    <div className="dropdown-divider" />

                    <NavLink to="/logout" className="dropdown-item">
                      <i className="icon-switch2" /> Logout
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="navbar navbar-expand-md navbar-darkk border-top-0">
            <div className="d-md-none w-100">
              <button
                type="button"
                className="navbar-toggler d-flex align-items-center w-100"
                data-toggle="collapse"
                data-target="#navbar-navigation"
              >
                <i className="icon-menu-open mr-2" />
                Menu
              </button>
            </div>

            <div className="navbar-collapse collapse" id="navbar-navigation">
              <ul className="navbar-nav mr-auto">
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "2" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li>
                    <NavLink
                      to="/dashboard"
                      className="navbar-nav-link"
                      // activeclassName='navbar-nav-link active'
                    >
                      <i className="icon-home4 mr-1" /> Home
                    </NavLink>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="navbar-nav-link dropdown-toggle"
                      to=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-users" /> Users
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/userslist", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-group" /> All Users List
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/usersgroups", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-user" /> Get User By Id
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/usersname", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-search" /> Get User By Name
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/txns");
                        }}
                      >
                        <i className="fa fa-exchange" /> Get Transactions
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      to=""
                      className="navbar-nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-arrow-circle-o-down"></i> Pending
                      Withdraw
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink
                        to={{
                          pathname: "/cryptowithdraw",
                          state: this.state.cuser,
                        }}
                        className="dropdown-item"
                      >
                        <i className="fa fa-download" /> Crypto Withdraw
                      </NavLink>

                      <NavLink
                        to={{
                          pathname: "/pesowithdraw",
                          state: this.state.cuser,
                        }}
                        className="dropdown-item"
                      >
                        <i className="fa fa-download" /> Peso Withdraw
                      </NavLink>
                    </div>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "2" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="navbar-nav-link dropdown-toggle"
                      to=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-list-alt" /> KYC Request
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/traderkycreq", {
                            state: this.state.cuser,
                          });
                        }}
                      >
                        <i className="fa fa-indent" /> Trader Requests
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/cropkycreq", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-indent" /> Corporate Requests
                      </div>
                    </div>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    ></div>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type != "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="navbar-nav-link dropdown-toggle"
                      to=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user-secret" /> Admins
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/sitecontrols", {
                            state: this.state.cuser,
                          });
                        }}
                      >
                        <i className="fa fa-cog" /> Admin controls
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/admlist", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-list" /> Admins List
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/sitecontrolsadvan");
                        }}
                      >
                        <i className="fa fa-th-large" /> Group Controls
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/coinslist");
                        }}
                      >
                        <i className="fa fa-bars" />
                        Coins List/Limits
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/reports");
                        }}
                      >
                        <i className="fa fa-file-text-o" /> MIS Reports
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/profitreports");
                        }}
                      >
                        <i className="fa fa-money" /> Profit Reports
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/binupdate");
                        }}
                      >
                        <i className="fa fa-key" /> Update Binance API Keys
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="navbar-nav-link dropdown-toggle"
                      to=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-book" /> Logs
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/userlogdate", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-calendar" /> User Logs By Date
                      </div>
                    </div>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li>
                    <NavLink to="/msg" className="navbar-nav-link">
                      <i className="fa fa-bell mr-1" /> Notification
                    </NavLink>
                  </li>
                ) : null}
                {this.state.cuser?.admin_type === "1" ||
                this.state.cuser?.admin_type === "2" ||
                this.state.cuser?.admin_type === "3" ? (
                  <li className="nav-item dropdown">
                    <NavLink
                      className="navbar-nav-link dropdown-toggle"
                      to=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-phone" /> Support
                    </NavLink>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/openticks", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-indent" /> Open Tickets
                      </div>

                      <div
                        className="dropdown-item"
                        onClick={(e) => {
                          navigate("/closedticks", { state: this.state.cuser });
                        }}
                      >
                        <i className="fa fa-outdent" /> Closed Tickets
                      </div>
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.getcurrentuser,
  };
};

export default connect(mapStateToProps)(pushRoute(HomeHeader));
