import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_DASHBOARD_VALUES = 'var GET_DASHBOARD_VALUES';

async function get_dashboardvalues() {
  try {
    const { success } = await backEndCall('/adminget/dashboard_stats');
    store.dispatch({ type: GET_DASHBOARD_VALUES, payload: success });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_dashboardvalues;
