import React from "react";
import HomeHeader from "../../commons/Header";
import { pushRoute } from "../../commons/pushRoute";
import Footer from "../../commons/Footer";
import { BallTriangle } from "react-loader-spinner";
import Joi from "joi-browser";
import { fields } from "../../data";
import Form from "../../commons/form";
import { backEndCallObj } from "../../services/MainService";
import { toastfun } from "../../commons/toast";
import get_adminslist from "../../reduxStore/action/adminslistAction";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

class Addadmin extends Form {
  state = {
    data: {},
    btndisabled: false,
  };

  schema = {
    phone: Joi.string().min(12).max(12).required().label("Phone"),
    password: Joi.string()
      .min(8)
      .max(15)
      .required()
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
      .options({
        language: {
          string: {
            regex: {
              base: "Must contain at least 1 lowercase,1 uppercase alphabetical,1 numeric ",
              test: "another description",
            },
          },
        },
      })
      .label(" Password"),
    admin_type: Joi.string().required().label("Type "),
    name: Joi.string().min(5).trim(true).required().label("Name "),
  };

  doSubmit = async () => {
    const { navigate } = this.props;

    this.setState({ btndisabled: true });

    try {
      const { data } = this.state;

      const dd = await backEndCallObj("/admin/createadmin", data);
      if (dd && dd.success) {
        toastfun("Admin Registered Successfully", "success");
        await get_adminslist();
        setTimeout(() => {
          navigate("/admlist");
        }, 2000);
      }
    } catch (ex) {
      this.setState({ btndisabled: false });
      if (ex.response && ex.response.status === 400) {
        toastfun(ex.response.data, "error");
      }
      console.warn(ex);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { navigate } = this.props;
    return (
      <React.Fragment>
        <HomeHeader />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title ">
                        <b> Add Admin </b>
                      </h3>
                      <button
                        onClick={() => {
                          navigate(-1);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </button>
                    </div>
                    <hr className="mt-0"></hr>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label>Admin Type</label>
                          <select
                            name="admin_type"
                            value={data?.admin_type}
                            className="form-control"
                            onChange={this.handleSelect}
                          >
                            {fields?.admin_types.map((r) => (
                              <option value={r.value} required>
                                {r.label}
                              </option>
                            ))}
                          </select>
                          <small>{errors?.admin_type}</small>
                        </div>

                        <div className="form-group col-md-3">
                          <label> Admin Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={data?.name}
                            placeholder="Enter Admin Name"
                            onChange={this.handleChange}
                          />
                          <small>{errors?.name}</small>
                        </div>

                        <div className="form-group col-md-3">
                          <label>Admin Phone </label>

                          <PhoneInput
                            className="w_20"
                            country={"ph"}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            onChange={(phone) =>
                              this.handleDate(phone, "phone")
                            }
                          />

                          <small>{errors?.phone}</small>
                        </div>
                        <div className="form-group col-md-3">
                          <label>Admin Password </label>
                          <input
                            type={this.state.pwd ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Admin Password"
                            value={data?.password}
                            onChange={this.handlePassword}
                          />
                          <i
                            className={
                              !this.state.pwd
                                ? "fa fa-eye-slash  text-dark eye_icon"
                                : "fa fa-eye  text-dark eye_icon"
                            }
                            onClick={() =>
                              this.setState({ pwd: !this.state.pwd })
                            }
                          ></i>
                          <small>{errors?.password}</small>
                        </div>
                      </div>
                      <div className="col-md-2 offset-md-5">
                        {!this.state.loader ? (
                          <button
                            type="button"
                            onClick={this.handleSubmit}
                            className="btn btn-primary btn-lg btn-block mt-3 ml-4 loc-bttn"
                            disabled={this.state.btndisabled}
                          >
                            {" "}
                            Submit{" "}
                          </button>
                        ) : (
                          <BallTriangle
                            className="text-center  offset-5"
                            // type="Bars"
                            color="grey"
                            // color="linear-gradient(to bottom, #4e4376, #2b5876)"
                            height={80}
                            width={80}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default pushRoute(Addadmin);
