import store from "../store/index";
import user from "../../services/userService";
import { toast } from "react-toastify";

export var GET_USERSBALANCE = "GET_USERSBALANCE";

async function get_usersbalance() {
  try {
    const data = await user.getuserbal();
    store.dispatch({ type: GET_USERSBALANCE, payload: data });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_usersbalance;
