import React, { Component } from 'react';
import HomeHeader from '../../commons/Header';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';
import Moment from 'react-moment';

import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { backEndCallObj } from '../../services/MainService';
import CommonTable from '../../commons/commonTable';

class GetTxns extends Component {
  state = {
    txnid: '',
    bknLoader: true,
    skip: 0,
    loadMoreButton: false,
    backEndBtn: false,

    udata: [],
    loader: false,
    hist: false,
    ld: [],
  };
  componentDidMount = () => {};

  colums = [
    {
      label: 'DATE',
      content: (date) => (
        <Moment unix format='DD-MM-YYYY hh:mm A' fromNow>
          {date.time / 1000}
        </Moment>
      ),
    },
    { path: 'tid', label: 'TRANSACTION ID' },

    { path: 'phone', label: 'PHONE' },
    // { path: 'refno', label: 'REF NO' },

    { path: 'amount', label: 'AMOUNT' },
    { path: 'fee', label: 'FEE' },
    { path: 'coin', label: 'COIN' },
    { path: 'status', label: 'STATUS' },
    { path: 'comment', label: 'COMMENT' },
    { path: 'type', label: 'TYPE' },
  ];

  getTxnhist = async () => {
    await this.setState({ hist: true, bknLoader: true });
    try {
      const obj = {
        type: 'all',
      };
      const { success } = await backEndCallObj('/adminget/gethistory', obj);
      if (success) {
        await this.setState({ udata: success, bknLoader: false, ld: success });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.setState({ bknLoader: false });
    }
  };
  getTransfer = async (type) => {
    await this.setState({ hist: true, bknLoader: true });
    try {
      const obj = {
        type: type,
      };
      const { success } = await backEndCallObj('/adminget/gethistory', obj);

      if (success) {
        await this.setState({ udata: success, bknLoader: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.setState({ bknLoader: false });
    }
  };

  handleSearch = async (evt) => {
    if (!evt.target.value) {
      this.setState({ udata: this.state.ld });
    } else {
      if (this.state.udata && this.state.udata.length) {
        const fd = Object.values(this.state.udata).filter((val) => {
          if (
            Object.values(val)
              .toString()
              .toLowerCase()
              .includes(evt.target.value.trim().toString().toLowerCase())
          ) {
            return val;
          } else {
            let mdate = moment(val.date).format('D MMM YYYY hh:mm A');
            if (
              mdate
                .toLowerCase()
                .includes(evt.target.value.trim().toString().toLowerCase())
            ) {
              return val;
            }
          }
        });

        await this.setState({ udata: fd });
      }
    }
  };
  onclose = () => {
    this.setState({ hist: false });
  };

  render() {
    const { udata } = this.state;
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        <b> Transactions Details </b>
                      </h3>
                      {this.state.hist ? (
                        <div className='d-flex col-md-1 offset-md-4'>
                          <i
                            class='fa fa-close text-danger'
                            style={{ fontSize: '20px', cursor: 'pointer' }}
                            onClick={this.onclose}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                    {this.state.hist ? (
                      <div className='d-flex col-md-4 no-gutters ml-4'>
                        <input
                          type='text'
                          placeholder='Search....'
                          pattern='[0-9]*'
                          className='py-1 pl-2 mb-2 border w-50'
                          onChange={this.handleSearch}
                        />
                        <i className='fa fa-search btn btn-primary mb-2 btn-sm lh-22 rounded-0'></i>
                      </div>
                    ) : null}
                    <div className='card-body pt-0'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='card-body datatable-scroll pt-0'>
                              {this.state.hist || this.state.loader ? null : (
                                <div>
                                  <div className='row my-2'>
                                    <div className=' col-md-2'>
                                      <button
                                        className='mb-2 btn-dark btn btn-success btn-sm lh-22 '
                                        onClick={this.getTxnhist}
                                      >
                                        Get Latest Transactions History
                                      </button>
                                    </div>
                                    <div className=' col-md-2'>
                                      <button
                                        className='mb-2 btn btn-dark btn-sm lh-22 mx-3 '
                                        onClick={() => this.getTransfer('SELL')}
                                      >
                                        Get Transactions By Sell
                                      </button>
                                    </div>
                                    <div className=' col-md-2'>
                                      <button
                                        className='mb-2 btn btn-dark btn-sm lh-22 mx-2 '
                                        onClick={() => this.getTransfer('BUY')}
                                      >
                                        Get Transactions By Buy
                                      </button>
                                    </div>
                                    <div className=' col-md-2'>
                                      <button
                                        className='mb-2 btn btn-dark btn-sm lh-22 '
                                        onClick={() => this.getTransfer('OTC')}
                                      >
                                        Get Transactions By OTC
                                      </button>
                                    </div>
                                    <div className=' col-md-2'>
                                      <button
                                        className='mb-2 btn btn-dark btn-sm lh-22 '
                                        onClick={() =>
                                          this.getTransfer('Deposit')
                                        }
                                      >
                                        Get Transactions By Deposit
                                      </button>
                                    </div>
                                    <div className='col-md-2'>
                                      <button
                                        className='mb-2 btn btn-dark btn-sm lh-22 '
                                        onClick={() =>
                                          this.getTransfer('Withdraw')
                                        }
                                      >
                                        Get Transactions By Withdraw
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.hist ? (
                                <div className='mb-2 table-responsive dt-table'>
                                  <CommonTable
                                    colums={this.colums}
                                    data={udata}
                                    bknLoader={this.state.bknLoader}
                                    loadMoreButton={this.state.loadMoreButton}
                                    loadmore={this.state.loadmore}
                                    fetchData={this.fetchData}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userbalances: state.userbalances,
  };
};

export default connect(mapStateToProps)(pushRoute(GetTxns));
