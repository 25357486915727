import http from "./commonServices/httpService";
import helpers from "./commonServices/cryptos";
const tokenKey = "admpr-token";
const apiUrl = process.env.REACT_APP_API_URL;

export async function backEndCall(route) {
  updtk();
  const { data } = await http.post(apiUrl + route);
  return helpers.decryptobj(data);
}

export async function backEndCallObj(route, obj) {
  updtk();
  const drreqpob = await helpers.encryptobj(obj);
  const { data } = await http.post(apiUrl + route, {
    enc: drreqpob,
  });
  return helpers.decryptobj(data);
}

export async function updtk() {
  const token = getJwt();
  if (!token) {
    console.warn("No Token in header");
  }
  http.setJwt(getJwt());
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  backEndCall,
  backEndCallObj,
};
