import store from '../store/index';
import { toast } from 'react-toastify';
import { backEndCall } from '../../services/MainService';

export var GET_ADMINLIST = 'GET_ADMINLIST';

async function get_adminslist() {
  try {
    const data = await backEndCall('/adminget/getadmins');

    store.dispatch({ type: GET_ADMINLIST, payload: data });
  } catch (ex) {
    if (
      (ex.response && ex.response.status === 400) ||
      (ex.response && ex.response.status === 404)
    ) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_adminslist;
