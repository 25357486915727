import React, { Component } from "react";
import { pushRoute } from "../../commons/pushRoute";

import HomeHeader from "../../commons/Header";
import Footer from "../../commons/Footer";
import { connect } from "react-redux";
import get_closetickets from "../../reduxStore/action/closeticketAction";
import CommonTable from "../../commons/commonTable";
import Moment from "react-moment";
import moment from "moment";

class Closedtickets extends Component {
  state = {
    closeticks: [],
    loadMoreButton: false,
    backEndBtn: false,
    stoploader: true,
  };
  componentDidMount = async () => {
    if (!this.props.getclosetickets) {
      this.intialload({ intial: true });
    } else {
      this.intialload({ intial: false });
    }
  };

  GotoViewTicketPage = (t) => {
    const { navigate } = this.props;
    navigate("/viewtickets", {
      state: t,
    });
  };

  colums = [
    {
      label: "DATE",
      content: (item) => (
        <>
          <Moment format="DD-MM-YYYY HH:MM A">{item.createdAt}</Moment>
          {/* <Moment format='DD-MM-YYYY hh:mm A'>{date.createdAt}</Moment> */}
        </>
      ),
    },
    { path: "ticket_id", label: "TICKET ID" },
    { path: "userid", label: "USER ID" },
    { path: "phone", label: "PHONE" },

    { path: "subject", label: "SUBJECT" },
    { path: "status", label: "STATUS" },
    {
      label: "ACTION",
      content: (t) => (
        <div className="no-gutters">
          <div
            className="btn text-white theme-btn bg-warning"
            style={{
              fontWeight: "600",
              fontSize: "14px",
            }}
            onClick={(e) => {
              this.GotoViewTicketPage(t);
            }}
          >
            View
          </div>
        </div>
      ),
    },
  ];

  intialload = async ({ intial }) => {
    try {
      await this.setState({ bknLoader: true });

      if (intial) {
        await get_closetickets();
      }

      const tick = await this.props.getclosetickets;
      this.setState({ closeticks: tick });

      await this.setState({ bknLoader: false });
    } catch (error) {
      await this.setState({ bknLoader: false });
    }
  };

  render() {
    const { closeticks } = this.state;

    return (
      <React.Fragment>
        <HomeHeader
          myprops={this.props}
          // user={this.props.location.state.user}
        />
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header header-elements-inline">
                      <h3 className="card-title">
                        <b> Closed Support Tickets </b>
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="solid-justified-tab1"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="solid-justified-tab11"
                          >
                            <div className="card-body datatable-scroll">
                              <div className="d-flex no-gutters"></div>
                              <div className="mb-2 table-responsive dt-table">
                                <CommonTable
                                  colums={this.colums}
                                  data={closeticks}
                                  bknLoader={this.state.bknLoader}
                                  loadMoreButton={this.state.loadMoreButton}
                                  loadmore={this.state.loadmore}
                                  fetchData={this.fetchData}
                                />

                                {/* <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={this.handlePageChange}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getclosetickets: state.getclosetickets,
  };
};

export default connect(mapStateToProps)(pushRoute(Closedtickets));
