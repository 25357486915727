import http from '../commonServices/httpService';
// import { apiUrl } from "../../config.json";
import helpers from '../commonServices/cryptos';

const apiEndpoint = process.env.REACT_APP_API_URL + '/nimecom';

export async function getuserorders() {
  const { data } = await http.post(apiEndpoint + '/getorders');
  return helpers.decryptobj(data);
}
export async function getinstoreorders() {
  const { data } = await http.post(apiEndpoint + '/getinstrorders');
  return helpers.decryptobj(data);
}
// export async function addsubcat(obj) {
//   const drreqpob = helpers.encryptobj(obj);
//   const { data } = await http.post(apiEndpoint + "/getinstrorders", {
//     enc: drreqpob,
//   });
//   return helpers.decryptobj(data);
// }

export default {
  getuserorders,
  getinstoreorders,
};
