import store from '../store/index';

import { toast } from 'react-toastify';

export var GET_PROFITREPORTS = 'GET_PROFITREPORTS';

async function get_ProfitReportslists(data) {
  try {
    store.dispatch({ type: GET_PROFITREPORTS, payload: data });
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
}

export default get_ProfitReportslists;
