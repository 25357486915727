import React, { Component } from 'react';
import HomeHeader from '../../commons/Header';
import Footer from '../../commons/Footer';
import { pushRoute } from '../../commons/pushRoute';
import { toast } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import Joi from 'joi-browser';
import _ from 'lodash';
import Moment from 'react-moment';
import user from '../../services/userService';
import Form from '../../commons/form';
import { backEndCallObj } from '../../services/MainService';
import { toastfun } from '../../commons/toast';

class LimitEdit extends Form {
  state = {
    edit: false,
    btndis: false,
    data: {},
  };
  componentDidMount = async () => {
    setTimeout(() => {
      this.redirected();
    }, 200);

    // this.props.history.push("/userlimits");
  };

  schema = {
    phone: Joi.string().required(),
    member_name: Joi.string().allow(null, '').optional(),

    daily_limit: Joi.number().required(),
    monthly_limit: Joi.number().required(),
    yearly_limit: Joi.number().required(),

    cashindaily_limit: Joi.number().required(),
    cashinmonthly_limit: Joi.number().required(),
    cashinyearly_limit: Joi.number().required(),
  };

  redirected = async () => {
    const { navigate } = this.props;
    if (!this.props.location.state) {
      navigate('/userlimits');
    }
    const ff = this.props.location.state;

    const data = _.pick(
      ff,
      'daily_limit',
      'monthly_limit',
      'yearly_limit',
      'cashindaily_limit',
      'cashinmonthly_limit',
      'cashinyearly_limit',
      'cashin_fee',
      'cashout_fee',
      'user_limits',
      'phone',
      'member_name'
    );

    await this.setState({ data });
  };

  doSubmit = async () => {
    const { navigate } = this.props;
    await this.setState({ btndis: true });
    if (this.props.location.state === this.state.data) {
      toastfun('No Changes', 'warning');
      await this.setState({ btndis: false });
      return;
    }
    try {
      const { success } = await backEndCallObj(
        '/admin/update_user_limits',
        this.state.data
      );
      if (success) {
        toastfun(success, 'success');
        navigate('/userlimits', { state: { intialload: false } });
      }
    } catch (error) {
      await this.setState({ btndis: false });
    }
  };

  close = () => {
    const { navigate } = this.props;
    navigate(-1);
  };

  edit = () => {
    this.setState({ edit: true });
  };

  render() {
    const { data, errors, btndis } = this.state;
    return (
      <React.Fragment>
        <HomeHeader myprops={this.props} />
        <div className='page-content'>
          <div className='content-wrapper'>
            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-header header-elements-inline'>
                      <h3 className='card-title'>
                        User Limits Information :<b> {data?.phone}</b>
                      </h3>
                      {this.state.edit === false ? (
                        <button
                          onClick={this.edit}
                          type='button'
                          class='btn btn-primary'
                          style={{ cursor: 'pointer' }}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          disabled={this.state.btndis}
                          onClick={this.handleSubmitToast}
                          type='button'
                          class='btn btn-primary'
                          style={{ cursor: 'pointer' }}
                        >
                          Update
                        </button>
                      )}
                      <button
                        onClick={this.close}
                        type='button'
                        class='btn-close'
                        aria-label='Close'
                        style={{ cursor: 'pointer' }}
                      >
                        X
                      </button>
                    </div>
                    <div className='card-body'>
                      <div className='tab-content'>
                        <div
                          className='tab-pane fade show active'
                          id='solid-justified-tab1'
                        >
                          <div
                            className='tab-pane fade show active'
                            id='solid-justified-tab11'
                          >
                            <div className='row'>
                              {data &&
                                Object.keys(data).map((key, index) => {
                                  return this.state.edit === false ? (
                                    <table class='table col-md-6'>
                                      <tr>
                                        <th scope='row' className='text-left '>
                                          <b>{key}</b>
                                        </th>
                                        <td className='text-right'>
                                          <input
                                            type='text'
                                            disabled
                                            name={key}
                                            value={data[key]}
                                          />
                                        </td>
                                      </tr>
                                    </table>
                                  ) : this.state.edit === true ? (
                                    <table class='table col-md-6'>
                                      <tr>
                                        <th scope='row' className='text-left '>
                                          <b>{key}</b>
                                        </th>

                                        <td className='text-right'>
                                          {key === 'phone' ||
                                          key === 'member_name' ? (
                                            <>
                                              <input
                                                type='number'
                                                disabled
                                                value={data[key]}
                                                name={key}
                                                // onChange={this.handlchange}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <input
                                                type='number'
                                                value={data[key]}
                                                name={key}
                                                onChange={
                                                  this.handleChangeWithKey
                                                }
                                              />
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  ) : null;
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default pushRoute(LimitEdit);
