import store from "../store/index";
import { toast } from "react-toastify";

export var GET_USERLIMITS = "GET_USERLIMITS";

async function get_userlimits(data) {
    try {
        store.dispatch({ type: GET_USERLIMITS, payload: data });
    } catch (ex) {
        if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
}

export default get_userlimits;
